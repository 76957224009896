import {BigNumberish, utils} from 'ethers';


export function formatAddress(address: string) {
  return address? `${address.slice(0, 5)}...${address.slice(-4)}` : '';
}

export function formatToken(amount: BigNumberish, decimals: number = 2) {
  const wei = utils.parseUnits(amount.toString(), 'wei');
  if (wei.isZero()) return '0.00';
  const remainder = wei.mod(Number(`1e${18 - decimals}`).toString());
  return utils.formatEther(wei.sub(remainder).toString());
}

export function formatTxLink(txHash: string) {
  return `${process.env.REACT_APP_WEB3_EXPLORER_URL}/tx/${txHash}`
}

export function parseWei(wei: string) {
  return utils.parseUnits(wei, 'wei');
}
