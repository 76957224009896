import {AppStartListening} from "store";
import {actions as authActions} from "./index";
import {slice as salesApi} from "../../api/sales/slice";
import {slice as web3Api} from "../../api/web3/slice";
import {AuthStatus, AuthType, Subject} from "./types";
import {isAnyOf} from "@reduxjs/toolkit";


export default function(startListening: AppStartListening) {
  startListening({
    matcher: isAnyOf(authActions.init.match, authActions.cancel.match, authActions.invalidate.match),
    effect: async (action, api) => {
      if (authActions.cancel.match(action) || authActions.invalidate.match(action)) {
        api.cancelActiveListeners();
        api.dispatch(authActions.clear());
        return;
      }
      const authType = action.payload;
      if (authType === AuthType.WEB3) {
        const [{payload: address}] = await api.take(authActions.setAddress.match);
        api.dispatch(authActions.update({type: AuthType.WEB3, status: AuthStatus.AWAITING_TEMPORARY_TOKEN, address}));
        const [{payload: temporaryToken}] = await api.take(salesApi.endpoints.getTemporaryToken.matchFulfilled);
        api.dispatch(authActions.update({type: AuthType.WEB3, status: AuthStatus.AWAITING_SIGNATURE, temporaryToken}));
        const [{payload: signature}] = await api.take(web3Api.endpoints.getPersonalSign.matchFulfilled);
        api.dispatch(authActions.update({type: AuthType.WEB3, status: AuthStatus.AWAITING_TOKEN, signature, temporaryToken}));
        const [{payload: token}] = await api.take(salesApi.endpoints.getJWTToken.matchFulfilled);
        api.dispatch(authActions.update({status: AuthStatus.FULFILLED, token, subject: extractSubject(token)}));
      } else if (authType === AuthType.PRIMARY) {
        const [{payload: token}] = await api.take(salesApi.endpoints.signIn.matchFulfilled);
        api.dispatch(authActions.update({status: AuthStatus.FULFILLED, token, subject: extractSubject(token)}));
      } else {
        const [{payload: {token}}] = await api.take(salesApi.endpoints.auth.matchFulfilled);
        api.dispatch(authActions.update({status: AuthStatus.FULFILLED, token, subject: extractSubject(token)}));
      }
    }
  });

}

function extractSubject(token: string): Subject {
  const payload = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
  const {id, email, address} = JSON.parse(payload.sub); // TODO: remove JSON.parse() when subject serialization is fixed on backend
  return {id, email, address};
}
