import './MyCoupons.scss'
import {NavLink} from "react-router-dom";
import {PATHS} from "../../../router/paths";
import SidebarLayout from "../../../components/layout/SidebarLayout/SidebarLayout";
import AccountSidebar from "../AccountSidebar/AccountSidebar";
import Button from "../../../components/button/Button";
import Loader from "../../../components/loader/Loader";
import {useFetchCouponsQuery} from "../../api/sales/slice";
import {Coupon} from "../../api/sales/model/Coupon";
import {formatToken} from "features/api/web3/lib/helpers";
import {HomeNavbar} from "../../home/HomeNavbar/HomeNavbar";
import {useAuth} from 'hooks';

export default function MyCoupons() {

  const {subject} = useAuth();
  const {data: coupons, isFetching} = useFetchCouponsQuery({accountId: subject?.id!}, {skip: !subject});

  return (
    <SidebarLayout>
      <SidebarLayout.Navbar>
        <HomeNavbar/>
      </SidebarLayout.Navbar>
      <SidebarLayout.Sidebar>
        <AccountSidebar/>
      </SidebarLayout.Sidebar>
      <SidebarLayout.Body>
        <h1>My Coupons</h1>
        {isFetching && <div className='mycoupons__loader'><Loader size='2rem'/></div>}
        {!isFetching && coupons && !!coupons.length && RenderTable(coupons)}
        {!isFetching && coupons && !coupons.length && RednerEmptyList()}
      </SidebarLayout.Body>
    </SidebarLayout>
  );
}

function RenderTable(coupons: Coupon[]) {
  return (
    <div className="mycoupons__table">
      <div className="tr">
        <div className="th">name</div>
        <div className="th">discount</div>
      </div>
      {coupons.map((item, i) => <RenderCoupon key={i} coupon={item}/>)}
    </div>
  )
}

function RenderCoupon(props: {coupon: Coupon}) {
  return (
    <div className="tr">
      <div className="td"><span className="mycoupons__coupon-name">{props.coupon.name}</span></div>
      <div className="td">{formatToken(props.coupon.discount)}&nbsp;USD</div>
    </div>
  );
}


function RednerEmptyList() {
  return (
    <div className="mycoupons__empty_list">
      <h4>no coupons</h4>
      <NavLink to={PATHS.root}>
        <Button>Learn how to get one</Button>
      </NavLink>
    </div>
  );
}
