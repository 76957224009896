import "./Button.css";
import './IconButton.css';

export default function IconButton(props: IconButtonInterface) {

  const className = ["default-btn default-icon-button"];
  if (props.className)
    className.push(props.className);

  const btnType = props.type || "rounded";
  className.push(`_type-${btnType}`);

  if (props.color)
    className.push(`_color-${props.color}`);

  if (props.disabled)
    className.push('_disabled');

  className.push(`_icon-${props.icon}`);

  return (
      <div
          className={className.join(' ')}
          style={props.style}
          onClick={(e) => {
            (!props.disabled) && props.onClick && props.onClick(e)
          }}
      >{props.children}</div>
  );
}

export interface IconButtonInterface {
  icon: "trashcan",
  className?: string
  type?: "squared" | "rounded",
  color?: "gray" | "yellow",
  disabled?: boolean
  children?: React.ReactNode,
  style?: React.CSSProperties,
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}
