import React, {useState} from 'react';
import BaseLayout from "components/layout/BaseLayout/BaseLayout";
import './BlogPage.css';
import Loader from "../../components/loader/Loader";
import {PATHS} from "../../router/paths";
import {getBlogMainPage} from "./BlogApi";
import FormattedDate from "../../components/date/FormattedDate";
import BlogSubscribeForm from "./BlogSubscribeForm";
import {useSafeNavigate} from "../../hooks";
import {HomeNavbar} from "../home/HomeNavbar/HomeNavbar";

export function BlogPage() {

  const navigate = useSafeNavigate();
  const [content, setContent] = useState<any>(undefined);
  const [activeLang, setActiveLang] = useState<string>("EN");
  const [activeCategory, setActiveCategory] = useState<number>(0);

  if (!content)
    getBlogMainPage().then(resp => {
      if (!resp)
        return navigate(PATHS.error_404);

      setContent(resp);
    });

  const filterArticle = function (item: any) {
    const hasActiveCategory = item.categories.indexOf(activeCategory) !== -1;
    const noCategorySelected = activeCategory === 0;
    const hasActiveLang = item.lang === activeLang;

    return (hasActiveCategory || noCategorySelected) && hasActiveLang;
  }

  return (
      <BaseLayout>
        <BaseLayout.Navbar>
          <HomeNavbar/>
        </BaseLayout.Navbar>
        <BaseLayout.Body>
          {!content ? renderLoader() :
              <div className="blog-wrap">
                <div className="inner">
                  <div className="inline-nav">
                    <div
                        onClick={() => setActiveCategory(0)}
                        className={`inline-nav__item ${!activeCategory && '_active'}`}>
                      All posts
                    </div>
                    {Object.values(content.categories)
                        .filter((item: any) => item.lang === activeLang)
                        .map((item: any) =>
                            <div
                                key={item.id}
                                onClick={() => setActiveCategory(item.id)}
                                className={`inline-nav__item ${item.id === activeCategory && '_active'}`}>
                              {item.name}
                            </div>
                        )}
                  </div>
                  <div className="posts-content">
                    <div className="posts-content__header">
                      <div className="language-nav">
                        {Object.values(content.langs).map((lang: any) =>
                            <div
                                key={lang}
                                onClick={() => {
                                  setActiveLang(lang);
                                  setActiveCategory(0);
                                }}
                                className={`language-nav__item ${lang === activeLang && "_active"}`}>
                              {lang}
                            </div>
                        )}
                      </div>
                      <div className="title">ALL POSTS</div>
                    </div>

                    <div className="posts">
                      {Object.values(content.articles)
                          .filter(item => filterArticle(item))
                          .map((item: any) =>
                              <div key={item.id} className="item-wrap">
                                <a className="item" href={PATHS.blog_item.replace(':path', item.path)}>
                                  <div
                                      className="item__preview"
                                      style={{backgroundImage: `url(${item.image.thumb})`}}></div>
                                  <div className="item__info">
                                    <div className="item__title">{item.name}</div>
                                    <div className="item__intro">{item.description}</div>
                                    <div className="item__date">
                                      <FormattedDate date={item.datetime} lang={activeLang}/>
                                    </div>
                                  </div>
                                </a>
                              </div>
                          )}
                    </div>

                    <BlogSubscribeForm/>

                  </div>
                </div>
              </div>
          }
        </BaseLayout.Body>
      </BaseLayout>
  );
}

function renderLoader() {
  return (
      <div className="blog-item-loader">
        <Loader size={50}/>
      </div>
  );
}
