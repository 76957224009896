import * as t from "io-ts";
import {Utils} from "../../util/util";
import {ResponseStatus} from "./ResponseStatus";


export const RESTProtocolDataResponseCodec = <C extends t.Mixed>(codec: C) => t.type({
  details: t.union([t.string, t.null]),
  message: t.union([t.string, t.null]),
  status: Utils.enum<ResponseStatus>(ResponseStatus),
  data: codec
})

export type RESTProtocolDataResponse<T> = {
  details: string | null,
  message: string | null,
  status: ResponseStatus,
  data: T
}
