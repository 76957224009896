export const OrderStatus = {
  INITIAL: 'INITIAL',
  CHECKING_BALANCE: 'CHECKING_BALANCE',
  CHECKING_ALLOWANCE: 'CHECKING_ALLOWANCE',
  PLACING_ORDER: 'PLACING_ORDER',
  AWAITING_PAYMENT: 'AWAITING_PAYMENT ',
  AWAITING_ORDER_FULFILMENT: 'AWAITING_ORDER_FULFILMENT',
  ORDER_FULFILLED: 'ORDER_FULFILLED',
  ORDER_CANCELLED: 'ORDER_CANCELLED',
  CANCELLED_BY_USER: 'CANCELLED_BY_USER'
} as const;

export type OrderStatus = typeof OrderStatus[keyof typeof OrderStatus];
