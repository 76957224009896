import { getContract } from "../util";


const CONTRACT_ABI = [
  'function buyBulk(string[] memory domainNames, string memory refererDomainName, address assetKey) external',
  'function getPrice(string memory domainName, string memory refererDomainName, address assetKey) view returns(uint)',
  'function getPriceWithoutReferer(string memory domainName, address assetKey) public view returns(uint)',
];

const CONTRACT_ADDRESS = process.env.REACT_APP_WEB3_DOMAIN_MARKET_ADDRESS;
const BUSD_ADDRESS = process.env.REACT_APP_WEB3_BUSD_ADDRESS;

export default {
  address: CONTRACT_ADDRESS,
  async fetchPrices(domainNames, refererDomainName, assetKey) {
    const contract = getContract(CONTRACT_ADDRESS, CONTRACT_ABI);
    const prices = await Promise.all(domainNames.map(domainName => contract.getPrice(domainName, refererDomainName, assetKey)));
    return domainNames.reduce((acc, domainName, i) => ({...acc, [domainName]: prices[i].toString()}), {});
  },
  async getPrice(domainName) {
    const contract = getContract(CONTRACT_ADDRESS, CONTRACT_ABI);
    const price = await contract.getPriceWithoutReferer(domainName, BUSD_ADDRESS);
    return price.toString();
  }
};
