export type Auth = {
  status: typeof AuthStatus.UNINITIALIZED
} | {
  status: typeof AuthStatus.FULFILLED
  subject: Subject
  token: string
} | OAuth | PrimaryAuth | Web3Auth

export type Web3Auth = {
  type: typeof AuthType.WEB3
  status: typeof AuthStatus.AWAITING_ADDRESS
} | {
  type: typeof AuthType.WEB3
  status: typeof AuthStatus.AWAITING_TEMPORARY_TOKEN
  address: string
} | {
  type: typeof AuthType.WEB3
  status: typeof AuthStatus.AWAITING_SIGNATURE
  temporaryToken: string
} | {
  type: typeof AuthType.WEB3
  status: typeof AuthStatus.AWAITING_TOKEN
  signature: string
  temporaryToken: string
}

type OAuth = {
  type: typeof AuthType.FACEBOOK | typeof AuthType.GOOGLE
  status: typeof AuthStatus.AWAITING_TEMPORARY_TOKEN
} | {
  type: typeof AuthType.FACEBOOK
  status: typeof AuthStatus.AWAITING_TOKEN
  accessToken: string
  id: string
} | {
  type: typeof AuthType.GOOGLE
  status: typeof AuthStatus.AWAITING_TOKEN
  tokenId: string
}

type PrimaryAuth = {
  type: typeof AuthType.PRIMARY
  status: typeof AuthStatus.AWAITING_TOKEN
}

export const AuthType = {
  FACEBOOK: 'FACEBOOK',
  GOOGLE: 'GOOGLE',
  // TWITTER: 'TWITTER',
  PRIMARY: 'PRIMARY',
  WEB3: 'WEB3'
} as const

export type AuthType = typeof AuthType[keyof typeof AuthType]

export const AuthStatus = {
  UNINITIALIZED: 'UNINITIALIZED',
  AWAITING_ADDRESS: 'AWAITING_ADDRESS',
  AWAITING_TEMPORARY_TOKEN: 'AWAITING_TEMPORARY_TOKEN',
  AWAITING_SIGNATURE: 'AWAITING_SIGNATURE',
  AWAITING_TOKEN: 'AWAITING_TOKEN',
  FULFILLED: 'FULFILLED'
} as const;

export type AuthStatus = typeof AuthStatus[keyof typeof AuthStatus]

export type OAuthData = {
  authType: typeof AuthType.FACEBOOK
  id: string
  accessToken: string
} | {
  authType: typeof AuthType.GOOGLE
  tokenId: string
}

export type Subject = {
  id: number
  email?: string
  address?: string
}
