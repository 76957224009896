import './MyDomains.scss'
import {NavLink} from "react-router-dom";
import {PATHS} from "../../../router/paths";
import {useEffect} from "react";
import DomainItem from "./DomainItem";
import SidebarLayout from "../../../components/layout/SidebarLayout/SidebarLayout";
import AccountSidebar from "../AccountSidebar/AccountSidebar";
import Button from "../../../components/button/Button";
import Loader from "../../../components/loader/Loader";
import {useGetAccountQuery, useLazyFetchDomainsQuery} from "../../api/web3/slice";
import {HomeNavbar} from "../../home/HomeNavbar/HomeNavbar";

export default function MyDomains() {
  const {data: account, isLoading: isAccountLoading} = useGetAccountQuery();
  const [fetchDomains, {domains, areDomainsLoading}] = useLazyFetchDomainsQuery({
    selectFromResult: ({data, isLoading}) => ({
      domains: data ? [...data].sort(({id: a}, {id: b}) => BigInt(a) > BigInt(b) ? -1 : 1) : undefined,
      areDomainsLoading: isLoading,
    })
  });
  const isLoading = isAccountLoading || areDomainsLoading;
  const hasDomains = !!domains?.length

  useEffect(() => {
    if (account) fetchDomains({account});
  }, [account]);

  return (
    <SidebarLayout>
      <SidebarLayout.Navbar>
        <HomeNavbar/>
      </SidebarLayout.Navbar>
      <SidebarLayout.Sidebar>
        <AccountSidebar/>
      </SidebarLayout.Sidebar>
      <SidebarLayout.Body>
        <h1>Domains</h1>
        {isLoading && <div className='mydomains__loader'><Loader size='2rem'/></div>}
        {!isLoading && hasDomains && domainsTable(domains)}
        {!isLoading && !hasDomains && emptyList()}
      </SidebarLayout.Body>
    </SidebarLayout>
  );
}

function domainsTable(domains: {id: string, name: string}[]) {
  return (
      <div className="mydomains__table">

        <div className="row _header">
          <div className="num">#</div>
          <div className="name">Domain</div>
          <div className="price">
            <span className="title">Price</span>
          </div>
          <div className="action"></div>
        </div>

        {domains.map((item, i) => <DomainItem key={i} domain={item} num={i + 1}/>)}

        <div className="row _total">
          <div className="num"></div>
          <div className="name">Total</div>
          <div className="price">
            <span className="title"></span>
            <span className="secondary">$900</span>
            <span className="primary">$300</span>
          </div>
          <div className="action"></div>
        </div>
      </div>
  )
}

function emptyList() {
  return (
    <div className="mydomains__empty_list">
      <h4>You haven't bought any domains yet</h4>
      <NavLink to={PATHS.root}>
        <Button shape="squared">Search domain</Button>
      </NavLink>
    </div>
  );
}
