import {createAction} from "@reduxjs/toolkit";
import {actions} from "./slice";

export const {
  createOrder,
  deleteOrder,
  updateOrder
} = actions;

export const cancelOrder = createAction<boolean | undefined>('order/cancelOrder');
export const markOrderAsPaid = createAction<{id: number}>('order/markAsPaid')
