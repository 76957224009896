import React from 'react';
import Button from "components/button/Button";
import {useFetchOrderQuery} from "../../api/sales/slice";
import Loader from "components/loader/Loader";

export default function AwaitFulfillment (props: {id: number}) {
  const {data, error, isLoading} = useFetchOrderQuery({id: props.id}, {pollingInterval: 1000});

  if (data && 'cancelCause' in data && data.cancelCause) return (
    <div className="claim__inner">
      <div>Something went wrong and Your order was cancelled</div>
      <div style={{marginBottom: '2rem'}}>{data.cancelCause}</div>
      <Button onClick={() => {}}>Return to Cart</Button>
    </div>
  );
  if (error) return (
    <div className="claim__inner">
      <div>Something went wrong</div>
      <div style={{marginBottom: '2rem'}}>{error.message}</div>
    </div>
  );
  return (
    <div className="claim__inner">
      <div>Your request has been successfully sent.</div>
      <div>Please wait for order fulfillment.</div>
      <div style={{display: 'flex', justifyContent: 'center', marginTop: '2rem'}}><Loader size='2rem'/></div>
    </div>
  )

}
