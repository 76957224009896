import './ConnectionPage.scss';
import React, {useEffect} from 'react';
import {NavLink} from "react-router-dom";
import {PATHS} from "../../router/paths";
import {useSafeNavigate, useWeb3} from "../../hooks";
import {useConnectMutation} from "../api/web3/slice";

export default function ConnectionPage () {

	const navigate = useSafeNavigate();
	const {isConnected} = useWeb3();
	const [connect, {isLoading}] = useConnectMutation();

	useEffect(() => {
		if (isConnected) navigate(-1);
	}, [isConnected])

	const handleClickConnect = async () => {
		await connect();
	}

	return (
		<div className="connection">
			<div className="connection__inner">
				<div className="connection__header">
					<div className="logo">
						<img src="/assets/img/header-logo.svg" alt=""/>
					</div>
					<NavLink to={PATHS.root} className="connection__close">
            <img src="/assets/img/cancel.svg" alt=""/>
					</NavLink>
				</div>
				<div className="connection__content">
					<button type="button" onClick={handleClickConnect} disabled={isLoading} className="connection__connect">Connect to WEB3</button>
				</div>
			</div>
		</div>
	);

}
