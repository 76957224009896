import React from 'react';
import {AuthType} from "../store/types";
import {useSelector} from "store/hooks";
import {selectors as authSelectors} from '../store';
import {Field, Form, FormRenderProps} from "react-final-form";
import {FORM_ERROR, SubmissionErrors} from "final-form";
import {useSignInMutation} from "../../api/sales/slice";

export function PrimaryAuthButton (props: {onClick: (authType: typeof AuthType.PRIMARY) => void}) {
  return <button type="button" onClick={() => props.onClick(AuthType.PRIMARY)} className="connection__connect">Sign in with password</button>;
}

export function PrimaryAuthPage () {
  const state = useSelector(authSelectors.selectAuthState);
  const [signIn] = useSignInMutation();

  const handleFormSubmit = async (values: {[k: string]: any}): Promise<SubmissionErrors> => {
    const {login, password} = values;
    const result = await signIn({login, password});
    if ('error' in result) return {[FORM_ERROR]: result.error.message};
  }

  const validateForm = (values: {[k: string]: string}) => {
    const errors: {[k: string]: string} = {};
    if (!values.login) {
      errors.login = 'This field is equired';
    }
    if (!values.password) {
      errors.password = 'This field is equired';
    }
    return errors;
  }

  if (!('type' in state) || state.type !== AuthType.PRIMARY) return null;
  return <Form onSubmit={handleFormSubmit} validate={validateForm} render={renderForm}/>
}


function renderForm(props: FormRenderProps) {
  const {submitError, handleSubmit, submitting} = props;
  return (
    <form className="wert__form" onSubmit={handleSubmit}>
      <Field name="login">
        {({ input, meta }) => (
          <div style={{marginBottom: '1rem'}}>
            <label htmlFor="login" className="default-input__label" style={{display: 'block', marginBottom: '0.5rem'}}>Login or email</label>
            <input {...input} type="text" placeholder="example@email.com" className={ 'default-input ' + ((meta.error || meta.submitError) && meta.touched ? 'wert__error': '') }/>
            {(meta.error || meta.submitError) && meta.touched && <div style={{marginTop: '1rem'}}>{meta.error || meta.submitError}</div>}
          </div>
        )}
      </Field>
      <Field name="password">
        {({ input, meta }) => (
          <div>
            <label htmlFor="password" className="default-input__label" style={{display: 'block', marginBottom: '0.5rem'}}>Password</label>
            <input {...input} type="password" className={ 'default-input ' + ((meta.error || meta.submitError) && meta.touched ? 'wert__error': '') }/>
            {(meta.error || meta.submitError) && meta.touched && <div style={{marginTop: '1rem'}}>{meta.error || meta.submitError}</div>}
          </div>
        )}
      </Field>
      {submitError && <div style={{marginTop: '1rem'}}>{submitError}</div>}
      <div style={{marginTop: '2rem'}}>
        <button type="submit" disabled={submitting} className="button" style={{border: 'none'}}>Submit</button>
      </div>
    </form>
  );
}
