import './TextInput.scss';
import {Field} from "react-final-form";
import React from "react";

export default function TextInput(props: {name: string, label?: string, placeholder?: string}) {
  const {name, label, placeholder} = props;
  return (
    <Field name={name}>{({ input, meta }) => (
      <div className="textinput">
        { label && <label htmlFor={name} className="textinput__label">{label}</label> }
        <input
          {...input}
          type="text"
          placeholder={placeholder}
          className={ 'textinput__field ' + ((meta.error || meta.submitError) && meta.touched ? 'textinput__field_error': '') }
        />
        { (meta.error || meta.submitError) && meta.touched &&
          <span className="textinput__error">{meta.error || meta.submitError}</span>
        }
      </div>
    )}</Field>
  );
}
