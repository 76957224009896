import * as t from "io-ts";
import {pipe} from "fp-ts/pipeable";
import * as e from "fp-ts/Either";
import {failure} from "io-ts/PathReporter";


export default function decodeWith<ApplicationType = any, EncodeTo = ApplicationType, DecodeFrom = unknown>(codec: t.Type<ApplicationType, EncodeTo, DecodeFrom>) {
  return (input: DecodeFrom): ApplicationType => pipe(
    codec.decode(input),
    e.getOrElseW((errors) => { throw new Error(failure(errors).join("\n")); })
  );
}
