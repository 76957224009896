import {createAction} from "@reduxjs/toolkit";
import {actions} from "./slice";

export const {
  createSaga,
  deleteSaga,
  updateSaga
} = actions;

export const cancelSaga = createAction<{uuid: string}>('claim/cancel');
