import {Fragment, useEffect} from "react";
import Button from "components/button/Button";
import {SerializedError} from "@reduxjs/toolkit";
import {formatTxLink, parseWei} from "features/api/web3/lib/helpers";
import Loader from "components/loader/Loader";
import {useApproveMutation, useLazyFetchAllowanceQuery, useLazyWaitForTransactionQuery} from "features/api/web3/slice";
import {Order} from "../store/model/Order";
import {contracts} from "features/api/web3/lib";
import {getOrThrow} from "shared/util";


export default function Approval(props: {account: string | undefined, order: Order}) {
  const {account: owner, order} = props;
  const [fetchAllowance, {data: allowance, error: allowanceError, isLoading: isAllowanceLoading}, {lastArg: fetchAllowanceLastArg}] = useLazyFetchAllowanceQuery();
  const [approve, {data: hash, error: approvalError, isLoading: isApprovalLoading, originalArgs: approveArg}] = useApproveMutation();
  const [waitForTransaction, {isLoading: isAwaitingTransaction}] = useLazyWaitForTransactionQuery();
  const isInsufficientAllowance = allowance ? parseWei(allowance).lt(order.discountedAmount) : undefined;

  useEffect(() => {
    if (owner) fetchAllowance({currency: order.currency, owner, spender: getOrThrow(contracts.domainMarket.address)});
  }, [owner]);

  useEffect(() => {
    if (isInsufficientAllowance) approve({currency: order.currency, spender: getOrThrow(contracts.domainMarket.address), amount: order.discountedAmount});
  }, [isInsufficientAllowance]);

  useEffect(() => {
    if (hash) waitForTransaction({transactionHash: hash});
  }, [hash]);

  if (owner && allowanceError) return (
    <Fragment>
      <div>Could not fetch the allowance</div>
      <RenderError error={allowanceError} />
      <Button onClick={() => fetchAllowance(fetchAllowanceLastArg)}>Retry</Button>
    </Fragment>
  );
  if (approveArg && approvalError) return (
    <Fragment>
      <div>Could not increase the allowance</div>
      <RenderError error={approvalError} />
      <Button onClick={() => approve(approveArg)}>Retry</Button>
    </Fragment>
  );
  if (!owner || isAllowanceLoading) return (
    <Fragment>
      <div style={{marginBottom: '2rem'}}>Checking allowance</div>
      <div style={{display: 'flex', justifyContent: 'center'}}><Loader size='2rem'/></div>
    </Fragment>
  )
  if (isApprovalLoading) return (
    <Fragment>
      <div>The purchase amount exceeds the allowance</div>
      <div>Please use your wallet to increase the allowance</div>
    </Fragment>
  )
  if (hash && isAwaitingTransaction) return (
    <Fragment>
      <div style={{marginBottom: '2rem'}}>Please wait until your <a href={formatTxLink(hash)} target='_blank' rel='noreferrer'>transaction</a> is mined</div>
      <div style={{display: 'flex', justifyContent: 'center'}}><Loader size='2rem'/></div>
    </Fragment>
  )
  if (isInsufficientAllowance === true) return (
    <Fragment>
      <div>The purchase amount exceeds the allowance</div>
      <div>Please use your wallet to increase the allowance</div>
    </Fragment>
  )
  return null;
}

function RenderError(props: {error: SerializedError}) {
  let message;
  switch (props.error.code) {
    case 'ACTION_REJECTED': message = 'User rejected transaction'; break;
    case 'UNPREDICTABLE_GAS_LIMIT': message = 'Cannot estimate gas. Transaction may fail or may require manual gas limit'; break;
    default: message = props.error.message;
  }
  return (<div style={{marginBottom: '2rem'}}>{message}</div>);
}
