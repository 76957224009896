import {To} from 'history';
import {NavigateFunction, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {NavigateOptions} from 'react-router/lib/hooks';
import {useMemo} from "react";
import {useGetAccountQuery, useIsConnectedQuery} from "../features/api/web3/slice";
import {selectAuthState} from "features/auth/store/selectors";
import {useSelector} from "../store/hooks";
import {AuthStatus} from "../features/auth/store/types";


export const useAuth = () => {
  const state = useSelector(selectAuthState);
  return {
    isAuthorized: useMemo(() => (state.status === AuthStatus.FULFILLED), [state.status]),
    subject: useMemo(() => 'subject' in state ? state.subject : undefined, [state]),
    state: useMemo(() => state, [state])
  };
}

export function useReferrer(): string | undefined {
  const [searchParams] = useSearchParams();
  return searchParams.get('referrer') ?? undefined;
}

export function useSafeNavigate(): NavigateFunction {
  const navigate = useNavigate();
  const location = useLocation();
  return (to: To | number, options?: NavigateOptions) => {
    if (typeof to !== 'number') return navigate(to, options);
    if (to < 0 && location.key === 'default') return navigate('/');
    return navigate(to);
  }
}

export function useWeb3(): { isConnected: boolean, address: string | undefined } {
  const { isConnected } = useIsConnectedQuery(undefined, {selectFromResult: ({data}) => ({isConnected: !!data})});
  const { data: address } = useGetAccountQuery(undefined, {skip: !isConnected});
  return {
    isConnected: useMemo(() => isConnected, [isConnected]),
    address: useMemo(() => address, [address]),
  };
}
