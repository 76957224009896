import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import store from "./store";
import {Provider} from "react-redux";
import Router from "./router";
import { gapi } from "gapi-script";



ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<Router/>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

gapi.load('client:auth2', () => {
	gapi.client.init({
		clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
		plugin_name: 'chat',
	});
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
