import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ClaimSaga, ClaimSagaStatus} from "./types";


export interface ClaimState {
  saga: ClaimSaga
}

const initialState: ClaimState = {
  saga: { status: ClaimSagaStatus.UNINITIALIZED }
}

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    createSaga: (state, {payload: {uuid}}: PayloadAction<{uuid: string}>) => {
      state.saga = {status: ClaimSagaStatus.AWAITING_ACCOUNT, uuid};
    },
    deleteSaga: (state,{payload: {uuid}}: PayloadAction<{uuid: string}>) => {
      if ('uuid' in state.saga && state.saga.uuid === uuid) state.saga = {status: ClaimSagaStatus.UNINITIALIZED};
    },
    updateSaga: (state, action: PayloadAction<ClaimSaga>) => {
      state.saga = action.payload;
    }
  }
});

export const { actions, reducer } = slice;
