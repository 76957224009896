import {createContext, useContext, useRef, useState, useLayoutEffect, PropsWithChildren} from "react"
import {BrowserHistory, createBrowserHistory, To} from "history"
import {Path, Router} from "react-router-dom"

const HistoryContext = createContext({} as BrowserHistory);

export const useHistory = () => useContext(HistoryContext);

export default function BrowserRouter({ children }: PropsWithChildren) {
  const { current: history } = useRef(tuneHistory(createBrowserHistory({ window }), ['referrer']))
  const [{ location }, setHistoryState] = useState({ location: history.location })

  useLayoutEffect(() => history.listen(setHistoryState), [history])

  return (
    <Router location={location} navigator={history}>
      <HistoryContext.Provider value={history}>
        {children}
      </HistoryContext.Provider>
    </Router>
  )
}

function preserveQueryParams(history: BrowserHistory, preserve: string[], to: To, state?: any): [To, any] {
  const _to: Partial<Path> = typeof to === 'string' ? {pathname: to} : to;
  const previous = new URLSearchParams(history.location.search);
  const preserved = preserve.reduce<Record<string, string>>((acc, param) => {
    const value = previous.get(param);
    if (value) acc[param] = value;
    return acc;
  }, {})
  const next = new URLSearchParams(_to.search);
  _to.search = new URLSearchParams({...preserved, ...Object.fromEntries(next)}).toString();
  return [_to, state];
}

function tuneHistory(history: BrowserHistory, preserve: string[]) {
  const push = history.push;
  const replace = history.replace;
  history.push = (to: To, state?: any) => push.apply(history, preserveQueryParams(history, preserve, to, state));
  history.replace = (to: To, state?: any) => replace.apply(history, preserveQueryParams(history, preserve, to, state));
  return history;
}
