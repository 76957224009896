export const isBrowser = typeof window !== 'undefined';
export const isNode = !isBrowser;
export const userAgent = isBrowser ? window.navigator.userAgent : '';
export const hasEthereum = isBrowser && typeof window.ethereum !== 'undefined';
export const isAndroid = /(android)/i.test(userAgent);
export const isIphone = /(iphone|ipod)/i.test(userAgent);
export const isIpad = /(ipad)/i.test(userAgent);
export const isMobile = isIphone || isAndroid;
// A mobile browser with ethereum we assume it's Metamask Browser
export const isMetamask = isMobile && hasEthereum;
