import {Fragment, useEffect, useState} from 'react';
import Button from "components/button/Button";
import {useFetchOrderQuery} from "../../api/sales/slice";
import {BuyTaskStatus} from "../../api/sales/model";
import {PATHS} from "../../../router/paths";
import {useSafeNavigate} from "../../../hooks";

export default function OrderFulfilment (props: {orderId: number}) {
  const {orderId} = props;
  const navigate = useSafeNavigate();
  const [pollingInterval, setPollingInterval] = useState<number | undefined>(1000);
  const {data: orderData} = useFetchOrderQuery({id: orderId}, {pollingInterval});

  useEffect(() => {
    if (orderData && orderData.status === BuyTaskStatus.CANCELLED) setPollingInterval(undefined);
  }, [orderData]);

  if (orderData && orderData.status === BuyTaskStatus.CANCELLED) return (
    <Fragment>
      <div>Sorry. Something went wrong and your order was cancelled</div>
      { 'cancelCause' in orderData ? <div>{orderData.cancelCause}</div> : null }
      <Button style={{marginTop: '2rem'}} onClick={() => navigate(PATHS.cart)}>Return to Cart</Button>
    </Fragment>
  );
  else return (
    <Fragment>
      <div>Your order has been placed</div>
      <div>Awaiting order fulfilment</div>
    </Fragment>
  )

}
