import './ReferralProgram.scss';
import {MouseEvent} from "react";
import SidebarLayout from "../../../components/layout/SidebarLayout/SidebarLayout";
import AccountSidebar from "../AccountSidebar/AccountSidebar";
import {HomeNavbar} from "../../home/HomeNavbar/HomeNavbar";
import {useFetchReferralDataQuery} from "../../api/sales/slice";
import {useAuth} from 'hooks';

export default function ReferralProgram () {
  const {subject} = useAuth();
  const {data, isFetching} = useFetchReferralDataQuery({accountId: subject?.id!}, {skip: !subject});
  const link = window && data ? `${window.location.protocol}//${window.location.host}?referrer=${data.data.referralId}` : '';

  const handleClickLink = (link: string) => (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    navigator.clipboard.writeText(link);
  }

  return (
      <SidebarLayout>
          <SidebarLayout.Navbar>
              <HomeNavbar/>
          </SidebarLayout.Navbar>
          <SidebarLayout.Sidebar>
              <AccountSidebar/>
          </SidebarLayout.Sidebar>
          <SidebarLayout.Body>
              <div className="referral-content">
                  <h1>Referral Program</h1>
                  <form className="referral-form">
                      <div className="referral-form__label">
                          Your wallet for referral rewards:
                      </div>
                      <div className="referral-form__field-row">
                          <input type="text" className="referral-form__field"
                                 placeholder="Indicate your EVM compatible wallet"/>
                          {/*_success на кнопку для галочки*/}
                          <button className="referral-form__btn"></button>
                      </div>
                  </form>

                  <div className="referral-content__attention">
                      Attention: all referral rewards will be transferred
                      in USDT(BEP20)
                  </div>

                  <div className="referral-link">
                      <div className="referral-link__label">Your referral link:</div>
                      <div className="referral-link__field-wrapper">
                          <input type="text" className="referral-link__field" disabled value={link}/>
                          <button type='button' className="referral-link__copy-btn" onClick={handleClickLink(link)}></button>
                      </div>
                  </div>
                  <div className="referral-link-message">
                      Upon purchase with your referral link you receive
                      25% from all the purchases made with you
                      ferral link.
                  </div>

                  {/*<div className="referral-stat-block">
                      <div className="referral-stat-block__header">
                          <div className="referral-stat-block__total-referrals">Total Referrals: <span>8</span></div>
                          <div className="referral-stat-block__paid-commissions">Paid Commissions: <span>$1,612</span>
                          </div>
                      </div>
                      <div className="referral-stat-block__table-wrapper">
                          <table className="referral-stat-block__table">
                              <tr>
                                  <th className="referral-stat-block__table-head-cell _num">#</th>
                                  <th className="referral-stat-block__table-head-cell">Registration <br/> time</th>
                                  <th className="referral-stat-block__table-head-cell">Wallet/Email/Username</th>
                                  <th className="referral-stat-block__table-head-cell _align-right">Purchased <br/> amount
                                  </th>
                                  <th className="referral-stat-block__table-head-cell _align-right">Your <br/> commission
                                  </th>
                              </tr>
                              <tr>
                                  <td className="referral-stat-block__table-cell _num">1</td>
                                  <td className="referral-stat-block__table-cell _nowrap">11/26/2022 9:08 AM</td>
                                  <td className="referral-stat-block__table-cell">referral@gmail.com</td>
                                  <td className="referral-stat-block__table-cell _align-right">$30</td>
                                  <td className="referral-stat-block__table-cell _align-right">$12</td>
                              </tr>
                              <tr>
                                  <td className="referral-stat-block__table-cell _num">2</td>
                                  <td className="referral-stat-block__table-cell _nowrap">11/26/2022 11:21 PM</td>
                                  <td className="referral-stat-block__table-cell">0x50D0eaE285C2fA208fab0E4d9a8f42cbf5FeA7Ae</td>
                                  <td className="referral-stat-block__table-cell _align-right">$4,000</td>
                                  <td className="referral-stat-block__table-cell _align-right">$1,600</td>
                              </tr>
                              <tr>
                                  <td className="referral-stat-block__table-cell _num _total">Total</td>
                                  <td className="referral-stat-block__table-cell _nowrap"></td>
                                  <td className="referral-stat-block__table-cell"></td>
                                  <td className="referral-stat-block__table-cell _align-right _bold">$4,030</td>
                                  <td className="referral-stat-block__table-cell _align-right _bold">$1,612</td>
                              </tr>
                          </table>
                      </div>

                      <div className="referral-stat-block__mobile-message">
                          Detailed report on your activity is available in the
                          desktop version
                      </div>
                  </div>*/}
              </div>
              {/*<div className="myprofile__container">
          { (isAccountLoading || isDataLoading) ? (
            <div style={{display: 'flex', justifyContent: 'center'}}><Loader size='2rem'/></div>
          ) : link ? (
            <Fragment>
              <p>Share your referral link with your friends</p>
              <p style={{marginBottom: '3rem'}}>When they purchase a domain using your link, we'll transfer 5% of it's value to your ETH account</p>
              <a onClick={handleClickLink(link)} href={link}>{link}</a>
            </Fragment>
          ) : null }
        </div>*/}
      </SidebarLayout.Body>
    </SidebarLayout>
  );
}
