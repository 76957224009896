import { Contract } from "ethers";
import { getProvider } from "./core";


export function normalizeChainId(chainId: number | string) {
  return (typeof chainId === 'number') ? `0x${chainId.toString(16)}` : chainId;
}

export function getContract(address: string, abi: string[], allowDefaultProvider: boolean = true) {
  const provider = getProvider(allowDefaultProvider);
  return new Contract(address, abi, provider);
}

export function getContractWithSigner(address: string, abi: string[]) {
  const provider = getProvider(false);
  const contract = new Contract(address, abi, provider);
  const signer = provider.getSigner();
  return contract.connect(signer);
}
