import {Form, FormRenderProps} from "react-final-form";
import FileInput from "../../components/FileInput";
import React from "react";

export default function UploadForm (props: {onSubmit: (values: any) => Promise<void>}) {
  return <Form onSubmit={props.onSubmit} validate={validateForm} render={renderForm}/>
}

function validateForm(values: {[key: string]: any}) {
  const errors: {[key: string]: any} = {};
  if (!values.page) {
    errors.page = "This field is required";
  }
  return errors;
}

function renderForm(props: FormRenderProps) {
  const {submitError, handleSubmit, submitting} = props;
  return (
    <form onSubmit={handleSubmit}>
      <div className="uploaddomain__row">
        <label htmlFor="page" className='uploaddomain__label'>Index page archive</label>
        <FileInput name='page' label='page'/>
      </div>
      <div className="uploaddomain__row">
        <label htmlFor="assets" className='uploaddomain__label'>Assets archive (optional)</label>
        <FileInput name='assets' label='assets'/>
      </div>
      {submitError && <div className="uploaddomain__row">{submitError}</div>}
      <div className='uploaddomain__submit'>
        <button type="submit" disabled={submitting} className="button" style={{border: 'none'}}>Submit</button>
      </div>
    </form>
  );
}

