import "./DomainPageEditor.css";
import BaseLayout from "../../../components/layout/BaseLayout/BaseLayout";
import React, {useRef, useState} from "react";
import Input from "../../../components/input/Input";
import ImageInput from "../../../components/imageInput/ImageInput";
import DomainPagePreview from "./DomainPagePreview";
import DomainPageEditorColors from "./DomainPageEditorColors/DomainPageEditorColors";
import DomainPageEditorPosts from "./DomainPageEditorPosts/DomainPageEditorPosts";
import {uploadImage} from "./DomainPageEditorApi";
import DomainPageEditorBackground from "./DomainPageEditorBackgound/DomainPageEditorBackground";
import Textarea from "../../../components/textarea/Textarea";
import Button from "../../../components/button/Button";
import {HomeNavbar} from "../../home/HomeNavbar/HomeNavbar";
import ColorsList from "./DomainPageEditorColors/ColorsList";


const pageTypes: { [key: string]: string } = {
  'sell': 'sell',
  'links': 'links',
  'blog-left': 'social network page',
  'blog-right': 'blog'
}

export default function DomainPageEditor() {
  const domain = {id: '111111', name: 'some_domain'}; // FIXME

  const [isFullPreview, setFullPreview] = useState(false);

  // all uploaded images stored only for two weeks since upload
  // images must be downloaded before being sent to the blockchain
  const [pageInfo, setPageInfo] = useState({
    domain: domain.name,
    type: 'sell',
    name: "Felis Catus",
    bio: "Sometimes I catch mice",
    ava: 'https://static30142.w3dna.net/photos/1472999/pexels-photo-1472999.jpeg?fm=jpg&w=1024&h=768',
    bg: '',
    cover: '',

    price: 0,
    email: null,

    telegram: 'FelisCatus',
    twitter: 'FelisCatus',
    discord: 'https://discord.com/invite/FelisCatus',

    posts: [
      {
        text: 'Wow! Such Cool Photo!',
        img: 'https://static30142.w3dna.net/photos/94420/pexels-photo-94420.jpeg?fm=jpg&w=1024&h=768'
      }
    ],

    colors: ColorsList.default,

    desc: 'This is very convenient if you are going to make a website that will contain a lot of useful information about children.'
  });

  const handleSave = () => {
    alert('save not implemented');
    console.log(pageInfo);
  }

  const assignPageInfo = (value: object) => {
    setPageInfo(prevState => Object.assign({...prevState}, value));
  }

  const setImage = async (file: File | null, key: string) => {
    if (!file)
      return assignPageInfo({[key]: ''});

    let links: any = await uploadImage(file);
    assignPageInfo({[key]: links.full});
  }

  const isFirstBgChange = useRef(true);
  const backgroundChange = (bg: object | null) => {

    const values = {
      bg: bg,
      colors: pageInfo.colors
    }

    if (isFirstBgChange.current && bg) {
      values.colors = ColorsList.dark_emerald;
      isFirstBgChange.current = false;
    }

    assignPageInfo(values);
  }

  return (
      <BaseLayout>
        <BaseLayout.Navbar>
          <HomeNavbar/>
        </BaseLayout.Navbar>
        <BaseLayout.Body>
          <div className={"domain-page-editor_tab-buttons_container"}>
            <div className={"inner domain-page-editor_tab-buttons"}>
              <Button
                  className={`domain-page-editor_tab-button ${!isFullPreview && '_active'}`}
                  onClick={() => setFullPreview(false)}
                  shape="squared">Editor</Button>
              <Button
                  className={`domain-page-editor_tab-button ${isFullPreview && '_active'}`}
                  onClick={() => setFullPreview(true)}
                  shape="squared">Preview</Button>
            </div>
          </div>
          <div className={"inner domain-page-editor_inner"}>
            <div className={"domain-page-editor_side _left"} style={{display: isFullPreview ? 'none' : 'block'}}>
              <h1>{domain.name}</h1>

              <h1 className={"domain-page-editor_title"}>Type</h1>
              <ul className={"domain-page-editor_type-list"}>
                {Object.keys(pageTypes).map((type, index) =>
                    <li className={"domain-page-editor_type-list_item form_radio"} key={index}>
                      <input
                          id={`page-type-radio-${type}`}
                          type="radio"
                          name="page-type"
                          value={type}
                          onChange={(e: any) => {
                            assignPageInfo({type: e.target.value})
                          }}
                          defaultChecked={type === pageInfo.type}/>
                      <label htmlFor={`page-type-radio-${type}`}> {pageTypes[type]}</label>
                    </li>)}
              </ul>

              {['sell'].indexOf(pageInfo.type) !== -1 && <>

                <Input
                  label="price"
                  placeholder="100000$"
                  name="price"
                  defaultValue={pageInfo.price}
                  onBlur={(e: any) => {
                    assignPageInfo({price: e.target.value})
                  }}
                />

                <Input
                  label="email"
                  placeholder="email@example.com"
                  name="email"
                  defaultValue={pageInfo.email}
                  onBlur={(e: any) => {
                    assignPageInfo({email: e.target.value})
                  }}
                />

                <Textarea
                  label="description"
                  onChange={(e: any) => {
                    assignPageInfo({email: e.target.value})
                  }}
                  maxLength="2000">{pageInfo.desc}</Textarea>
              </>}

              {['links', 'blog-left', 'blog-right'].indexOf(pageInfo.type) !== -1 && <>

                <Input
                  label="name"
                  placeholder="Add your name"
                  name="name"
                  defaultValue={pageInfo.name}
                  onBlur={(e: any) => {
                    assignPageInfo({name: e.target.value})
                  }}
                />

                <Input
                  label="bio"
                  placeholder="Bio"
                  name="bio"
                  maxLenght={200}
                  defaultValue={pageInfo.bio}
                  onBlur={(e: any) => {
                    assignPageInfo({bio: e.target.value})
                  }}/>

                <ImageInput
                  label="avatar"
                  onChange={file => setImage(file, 'ava')}
                  defaultValue={pageInfo.ava}/>

                <h1>Socials</h1>
                <Input
                  label="telegram"
                  placeholder="TgNickName"
                  name="telegram"
                  onBlur={(e: any) => {
                    assignPageInfo({telegram: e.target.value})
                  }}
                  defaultValue={pageInfo.telegram}
                />

                <Input
                  label="twitter"
                  placeholder="TwitterNickName"
                  name="twitter"
                  onBlur={(e: any) => {
                    assignPageInfo({twitter: e.target.value})
                  }}
                  defaultValue={pageInfo.twitter}
                />

                <Input
                  label="Discord"
                  placeholder="DiscordInviteLink"
                  name="Discord"
                  onBlur={(e: any) => {
                    assignPageInfo({discord: e.target.value})
                  }}
                  defaultValue={pageInfo.discord}
                />
              </>}

              {['links'].indexOf(pageInfo.type) !== -1 && <>
                <ImageInput
                  label="cover"
                  type="squared"
                  onChange={file => setImage(file, 'cover')}
                  defaultValue={pageInfo.cover}/>
              </>}

              <h1>Background</h1>
              <DomainPageEditorBackground
                  onChange={backgroundChange}/>

              <h1>Color</h1>
              <DomainPageEditorColors
                  onChange={colors => assignPageInfo({colors})}/>

              {['blog-left', 'blog-right'].indexOf(pageInfo.type) !== -1 && <>
                <h1>Posts</h1>
                <DomainPageEditorPosts
                  posts={pageInfo.posts}
                  onChange={posts => assignPageInfo({posts})}/>
              </>}

              <Button onClick={handleSave} shape="squared">Save</Button>

            </div>
            <div className={`domain-page-editor_side _right ${isFullPreview && '_active'}`}
                 style={{display: isFullPreview ? 'block' : ''}}>
              <DomainPagePreview state={pageInfo}/>
            </div>
          </div>
        </BaseLayout.Body>
      </BaseLayout>
  );
}
