import {Fragment, useEffect} from 'react';
import Button from "components/button/Button";
import {SerializedError} from "@reduxjs/toolkit";
import {formatToken, parseWei} from "features/api/web3/lib/helpers";
import Loader from "components/loader/Loader";
import {useLazyFetchBalanceQuery} from "features/api/web3/slice";
import {Order} from "../store/model/Order";


export default function Balance(props: {account: string | undefined, order: Order}) {
  const {account, order} = props;
  const [fetchBalance, {data: balance, error: balanceError, isLoading: isBalanceLoading}] = useLazyFetchBalanceQuery();
  const isInsufficientBalance = balance && parseWei(balance).lt(order.discountedAmount);

  useEffect(() => {
    if (account) fetchBalance({account, currency: order.currency});
  }, [account])

  if (!account || isBalanceLoading) return (
    <Fragment>
      <div style={{marginBottom: '2rem'}}>Checking balance</div>
      <div style={{display: 'flex', justifyContent: 'center'}}><Loader size='2rem'/></div>
    </Fragment>
  )
  if (balanceError) return (
    <Fragment>
      <div>Could not fetch your balance</div>
      <RenderError error={balanceError} />
      <Button onClick={() => fetchBalance({account, currency: order.currency})}>Retry</Button>
    </Fragment>
  );
  if (isInsufficientBalance) return (
    <Fragment>
      <div>The purchase amount exceeds Your balance</div>
      <div style={{marginBottom: '2rem'}}>Your balance is: {formatToken(balance)}&nbsp;${order.currency}</div>
      <Button onClick={() => fetchBalance({account, currency: order.currency})}>Check again</Button>
    </Fragment>
  )
  return null;
}

function RenderError(props: {error: SerializedError}) {
  let message;
  switch (props.error.code) {
    case 'ACTION_REJECTED': message = 'User rejected transaction'; break;
    case 'UNPREDICTABLE_GAS_LIMIT': message = 'Cannot estimate gas. Transaction may fail or may require manual gas limit'; break;
    default: message = props.error.message;
  }
  return (<div style={{marginBottom: '2rem'}}>{message}</div>);
}
