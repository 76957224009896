import {NavLink} from "react-router-dom";
import {PATHS} from "../../router/paths";
import {PropsWithChildren} from "react";
import './modal.scss';

export default function Modal (props: PropsWithChildren) {
  return (
    <div className="modal">
      <div className="modal__inner">
        <div className="modal__header">
          <div className="logo">
            <NavLink to={PATHS.root} className="logo">
              <img src="/assets/img/logo-retina.png" alt=""/>
            </NavLink>
          </div>
          <NavLink to={PATHS.root} className="modal__close">
            <img src="/assets/img/Desktop-retina-close-cart.svg" alt=""/>
          </NavLink>
        </div>
        <div className="modal__content">
          {props.children}
        </div>
      </div>
    </div>
  );
}
