import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';


export interface ProtectedRouteInterfacte {
  predicate: (() => boolean) | boolean
  redirect: string | React.ReactElement
}

export default function ProtectedRoute(props: ProtectedRouteInterfacte): React.ReactElement {
  const { predicate, redirect } = props;
  if ((typeof predicate === 'function' && predicate()) || predicate) {
    return <Outlet/>;
  } else if (typeof redirect === 'string') {
    return <Navigate to={redirect}/>;
  } else {
    return (redirect);
  }
};
