import "./ErrorPage.css";
import React from "react";
import BaseLayout from "../../components/layout/BaseLayout/BaseLayout";
import {HomeNavbar} from "../home/HomeNavbar/HomeNavbar";

export function Error404Page() {
  return (
      <BaseLayout>
        <BaseLayout.Navbar>
          <HomeNavbar/>
        </BaseLayout.Navbar>
        <BaseLayout.Body>

          <div className="error-page__container">
            <h1 className="error-page__title">404</h1>
            <div className="error-page__text">Page Not Found</div>
            <div className="error-page__img"></div>
          </div>

        </BaseLayout.Body>
      </BaseLayout>
  );
}