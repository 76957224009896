const ColorsList = {
  "default": {
    "body_bg": "#f8f8f8ff",
    "opacity_img_bg": 0.4,
    "box_bg": "#ffffffcc",
    "box_shadow_opacity": 0.07,
    "box_border_radius": 0.2,
    "box_divider_color": "#4d4c52cc",
    "line_color": "#4d4c52f2",
    "header_name_color": "#0f0030ff",
    "header_color": "#4f4f4fcc",
    "text_color": "#000000ff",
    "bio_color": "#848484ff",
    "domain_color": "#4c37caff",
    "laber_color": "#4d4c52f2",
    "svg_soc_color": "#4c37ca",
    "svg_logo_color": "#dadada",
    "_theme_icon_1": "#f8f8f8",
    "_theme_icon_2": "#4c37ca"
  },
  "dark_emerald": {
    "body_bg": "#0f0e14ff",
    "opacity_img_bg": 0.4,
    "box_bg": "#232527cc",
    "box_shadow_opacity": 0.07,
    "box_border_radius": 0.2,
    "box_divider_color": "#0f0e14cc",
    "line_color": "#4d4c52cc",
    "header_name_color": "#ffffffff",
    "header_color": "#bdbdbdff",
    "text_color": "#ffffffff",
    "bio_color": "#cfcfcfff",
    "domain_color": "#70ffd1ff",
    "laber_color": "#4d4c52f2",
    "svg_soc_color": "#ffffff",
    "svg_logo_color": "#ffffff",
    "_theme_icon_1": "#0f0e14",
    "_theme_icon_2": "#70ffd1"
  },
  "light_green": {
    "body_bg": "#ffffffff",
    "opacity_img_bg": 0.4,
    "box_bg": "#bdffd0cc",
    "box_shadow_opacity": 0.07,
    "box_border_radius": 0.2,
    "box_divider_color": "#0f0e14cc",
    "line_color": "#4d4c52cc",
    "header_name_color": "#22633cff",
    "header_color": "#000000ff",
    "text_color": "#000000ff",
    "bio_color": "#000000ff",
    "domain_color": "#22633cff",
    "laber_color": "#4d4c52f2",
    "svg_soc_color": "#000000",
    "svg_logo_color": "#dadada",
    "_theme_icon_1": "#bdffd0",
    "_theme_icon_2": "#22633c"
  },
  "white_violet": {
    "body_bg": "#f8f8f8ff",
    "opacity_img_bg": 0.4,
    "box_bg": "#ffffffcc",
    "box_shadow_opacity": 0.07,
    "box_border_radius": 0.2,
    "box_divider_color": "#4d4c52cc",
    "line_color": "#4d4c52f2",
    "header_name_color": "#0f0030ff",
    "header_color": "#4f4f4fcc",
    "text_color": "#000000ff",
    "bio_color": "#848484ff",
    "domain_color": "#4c37caff",
    "laber_color": "#4d4c52f2",
    "svg_soc_color": "#4c37ca",
    "svg_logo_color": "#dadada",
    "_theme_icon_1": "#f8f8f8",
    "_theme_icon_2": "#4c37ca"
  },
  "white_emerald": {
    "body_bg": "#f8f8f8ff",
    "opacity_img_bg": 0.4,
    "box_bg": "#ffffffcc",
    "box_shadow_opacity": 0.07,
    "box_border_radius": 0.2,
    "box_divider_color": "#4f4f4fcc",
    "line_color": "#4f4f4f66",
    "header_name_color": "#000000ff",
    "header_color": "#4f4f4fcc",
    "text_color": "#000000ff",
    "bio_color": "#848484ff",
    "domain_color": "#00bb97ff",
    "laber_color": "#4d4c52f2",
    "svg_soc_color": "#000000",
    "svg_logo_color": "#dadada",
    "_theme_icon_1": "#f8f8f8",
    "_theme_icon_2": "#00bb97"
  },
  "white_pink": {
    "body_bg": "#f8f8f8ff",
    "opacity_img_bg": 0.4,
    "box_bg": "#feccffcc",
    "box_shadow_opacity": 0.07,
    "box_border_radius": 0.2,
    "box_divider_color": "#ffffffff",
    "line_color": "#3f004466",
    "header_name_color": "#3f0044ff",
    "header_color": "#4f4f4fcc",
    "text_color": "#000000ff",
    "bio_color": "#848484ff",
    "domain_color": "#ff2b77ff",
    "laber_color": "#3f004466",
    "svg_soc_color": "#ff2b77",
    "svg_logo_color": "#dadada",
    "_theme_icon_1": "#f8f8f8",
    "_theme_icon_2": "#ff2b77"
  },
  "white_red": {
    "body_bg": "#f8f8f8ff",
    "opacity_img_bg": 0.4,
    "box_bg": "#ffffffcc",
    "box_shadow_opacity": 0.07,
    "box_border_radius": 0.2,
    "box_divider_color": "#4f4f4fcc",
    "line_color": "#4f4f4f66",
    "header_name_color": "#000000ff",
    "header_color": "#4f4f4fcc",
    "text_color": "#000000ff",
    "bio_color": "#848484ff",
    "domain_color": "#d70000ff",
    "laber_color": "#4d4c52f2",
    "svg_soc_color": "#000000",
    "svg_logo_color": "#dadada",
    "_theme_icon_1": "#f8f8f8",
    "_theme_icon_2": "#d70000"
  },
  "light_blue": {
    "body_bg": "#f8f8f8ff",
    "opacity_img_bg": 0.4,
    "box_bg": "#cce7ffcc",
    "box_shadow_opacity": 0.07,
    "box_border_radius": 0.2,
    "box_divider_color": "#ffffffff",
    "line_color": "#4d4c52f2",
    "header_name_color": "#000744ff",
    "header_color": "#4f4f4fcc",
    "text_color": "#000000ff",
    "bio_color": "#000000cc",
    "domain_color": "#0047ffff",
    "laber_color": "#4d4c52f2",
    "svg_soc_color": "#0047ff",
    "svg_logo_color": "#dadada",
    "_theme_icon_1": "#f8f8f8",
    "_theme_icon_2": "#0047ff"
  },
  "dark_yellow": {
    "body_bg": "#14120eff",
    "opacity_img_bg": 0.4,
    "box_bg": "#232527cc",
    "box_shadow_opacity": 0.07,
    "box_border_radius": 0.2,
    "box_divider_color": "#0f0e14cc",
    "line_color": "#4d4c52cc",
    "header_name_color": "#ffffffff",
    "header_color": "#bdbdbdff",
    "text_color": "#ffffffff",
    "bio_color": "#cfcfcfff",
    "domain_color": "#ffde31ff",
    "laber_color": "#4d4c52f2",
    "svg_soc_color": "#ffffff",
    "svg_logo_color": "#ffffff",
    "_theme_icon_1": "#14120e",
    "_theme_icon_2": "#ffde31"
  },
  "dark_blue": {
    "body_bg": "#11121cff",
    "opacity_img_bg": 0.4,
    "box_bg": "#232527cc",
    "box_shadow_opacity": 0.07,
    "box_border_radius": 0.2,
    "box_divider_color": "#0f0e14cc",
    "line_color": "#4d4c52cc",
    "header_name_color": "#4d4c52f2",
    "header_color": "#bdbdbdff",
    "text_color": "#ffffffff",
    "bio_color": "#cfcfcfff",
    "domain_color": "#70b2ffff",
    "laber_color": "#4d4c52f2",
    "svg_soc_color": "#ffffff",
    "svg_logo_color": "#ffffff",
    "_theme_icon_1": "#11121c",
    "_theme_icon_2": "#70b2ff"
  },
  "dark_pink": {
    "body_bg": "#11121cff",
    "opacity_img_bg": 0.4,
    "box_bg": "#232527cc",
    "box_shadow_opacity": 0.07,
    "box_border_radius": 0.2,
    "box_divider_color": "#0f0e14cc",
    "line_color": "#4d4c52cc",
    "header_name_color": "#ff2b77ff",
    "header_color": "#bdbdbdff",
    "text_color": "#ffffffff",
    "bio_color": "#cfcfcfff",
    "domain_color": "#ff2b77ff",
    "laber_color": "#4d4c52f2",
    "svg_soc_color": "#ffffff",
    "svg_logo_color": "#ffffff",
    "_theme_icon_1": "#11121c",
    "_theme_icon_2": "#ff2b77"
  },
  "white_green": {
    "body_bg": "#ffffffff",
    "opacity_img_bg": 0.4,
    "box_bg": "#ccffd0cc",
    "box_shadow_opacity": 0.07,
    "box_border_radius": 0.2,
    "box_divider_color": "#d0dbd4ff",
    "line_color": "#3f004466",
    "header_name_color": "#3cbb00cc",
    "header_color": "#4f4f4fcc",
    "text_color": "#000000ff",
    "bio_color": "#848484ff",
    "domain_color": "#3cbb00ff",
    "laber_color": "#3f004466",
    "svg_soc_color": "#3cbb00",
    "svg_logo_color": "#dadada",
    "_theme_icon_1": "#ccffd0",
    "_theme_icon_2": "#3cbb00"
  }
};


export default ColorsList;
