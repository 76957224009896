import React from "react";
import ReactFacebookLogin, {ReactFacebookFailureResponse, ReactFacebookLoginInfo} from 'react-facebook-login';
import {AuthType, OAuthData} from "../store/types";

export function FacebookAuthButton (props: {
  onClick: (authType: typeof AuthType.FACEBOOK) => void
  onSuccess: (data: OAuthData) => void
  onError?: (error: any) => void
}) {

  const handleCallback = (response: ReactFacebookLoginInfo | ReactFacebookFailureResponse) => {
    if ('accessToken' in response) {
      props.onSuccess({authType: AuthType.FACEBOOK, id: response.id, accessToken: response.accessToken})
    } else {
      props.onError?.(response);
    }
  }

  return (
    <ReactFacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP_ID!}
      autoLoad={false}
      fields="name,email,picture"
      onClick={() => props.onClick(AuthType.FACEBOOK)}
      callback={handleCallback}
    />
  );
}
