import React, {Fragment, useEffect} from 'react';
import {useConnectMutation, useGetPersonalSignQuery} from "features/api/web3/slice";
import {useGetJWTTokenQuery, useGetTemporaryTokenQuery} from "features/api/sales/slice";
import {AuthStatus, AuthType} from "../store/types";
import {useWeb3} from "hooks";
import {useDispatch, useSelector} from "store/hooks";
import {actions as authActions, selectors as authSelectors} from '../store';
import Loader from "components/loader/Loader";

export function Web3AuthButton (props: {onClick: (authType: typeof AuthType.WEB3) => void}) {
  return <button type="button" onClick={() => props.onClick(AuthType.WEB3)} className="connection__connect">Login using WEB3</button>;
}

export function Web3AuthPage () {
  const state = useSelector(authSelectors.selectAuthState);
  if ('type' in state && state.type !== AuthType.WEB3) return null;
  switch(state.status) {
    case AuthStatus.AWAITING_ADDRESS: return <GetAddress/>
    case AuthStatus.AWAITING_TEMPORARY_TOKEN: return <GetTemporaryToken address={state.address}/>
    case AuthStatus.AWAITING_SIGNATURE: return <SignMessage temporaryToken={state.temporaryToken}/>
    case AuthStatus.AWAITING_TOKEN: return <GetJWTToken signature={state.signature} temporaryToken={state.temporaryToken}/>
    default: return <div><Loader size='2rem'/></div>
  }
}

function GetAddress () {
  const dispatch = useDispatch();
  const {address} = useWeb3();
  const [connect, {isLoading, error}] = useConnectMutation();
  useEffect(() => { if (address) dispatch(authActions.setAddress(address)) }, [address]);
  return (
    <Fragment>
      {error ? (
        <Fragment>
          <div>Something went wrong</div>
          <div >{error.message}</div>
        </Fragment>
      ) : (
        <div style={{marginBottom: '2rem'}}>Please connect your Web3 wallet</div>
      )}
    <div><button type="button" onClick={() => connect()} disabled={isLoading} className="connection__connect">Connect</button></div>
    </Fragment>
  );
}

function GetTemporaryToken (props: {address: string}) {
  const {isFetching, error, refetch} = useGetTemporaryTokenQuery(props.address);
  if (error) return (
    <Fragment>
      <div>Something went wrong</div>
      <div style={{marginBottom: '2rem'}}>{error.message}</div>
      <div><button type="button" onClick={refetch} disabled={isFetching} className="auth__connect">Try again</button></div>
    </Fragment>
  );
  return null;
}

function SignMessage (props: {temporaryToken: string}) {
  const {isFetching, error, refetch} = useGetPersonalSignQuery(props.temporaryToken);
  return (
    <Fragment>
      {error ? (
        <Fragment>
          <div>Something went wrong</div>
          <div style={{marginBottom: '2rem'}}>{error.message}</div>
        </Fragment>
      ) : (
        <div style={{marginBottom: '2rem'}}>Please, sign message to continue</div>
      )}
      <div><button type="button" onClick={refetch} disabled={isFetching} className="auth__connect">Sign message</button></div>
    </Fragment>
  );
}

function GetJWTToken (props: {temporaryToken: string, signature: string}) {
  const {isFetching, error, refetch} = useGetJWTTokenQuery(props);
  if (error) return (
    <Fragment>
      <div>Something went wrong</div>
      <div style={{marginBottom: '2rem'}}>{error.message}</div>
      <div><button type="button" onClick={refetch} disabled={isFetching} className="auth__connect">Try again</button></div>
    </Fragment>
  );
  return null;
}
