import {AppStartListening} from "store";
import {slice as salesApi} from "features/api/sales/slice";
import {slice as web3Api} from "features/api/web3/slice";
import {actions as claimActions} from "./index";
import {isAnyOf} from "@reduxjs/toolkit";
import {BuyTaskStatus} from "../../api/sales/model";
import {ClaimSagaStatus} from "./types";


export default function(startListening: AppStartListening) {
  startListening({
    matcher: isAnyOf(claimActions.createSaga.match, claimActions.cancelSaga.match),
    effect: async ({type, payload: {uuid}}, api) => {
      if (type === claimActions.cancelSaga.type) {
        api.cancelActiveListeners();
        api.dispatch(claimActions.deleteSaga({uuid}));
        return;
      }
      api.dispatch(claimActions.updateSaga({status: ClaimSagaStatus.AWAITING_ACCOUNT, uuid}));
      const [{payload: account}] = await api.take(isAnyOf(web3Api.endpoints.getAccount.matchFulfilled, web3Api.endpoints.connect.matchFulfilled));
      api.dispatch(claimActions.updateSaga({status: ClaimSagaStatus.SENDING_REQUEST, account, uuid}));
      const [{payload: {data: {taskId: id}}}] = await api.take( action =>
        salesApi.endpoints.claimDomains.matchFulfilled(action) && action.payload.data.status === BuyTaskStatus.IN_PROGRESS
      );
      api.dispatch(claimActions.updateSaga({status: ClaimSagaStatus.AWAITING_FULFILLMENT, account, id, uuid}));
      await api.take( action =>
        salesApi.endpoints.fetchOrder.matchFulfilled(action) && action.payload.status === BuyTaskStatus.FINISHED
      );
      api.dispatch(claimActions.updateSaga({status: ClaimSagaStatus.FULFILLED, account, id, uuid}));
    }
  });
}
