import {NavLink} from "react-router-dom";
import {PATHS} from "../../router/paths";
import './Navbar.css';
import {HashLink} from "react-router-hash-link";
import {useState} from "react";


function Navbar(props) {
	const [isOpened, setOpened] = useState();

	return (
		<div className={'header ' + (props.className || '')}>
			<div className="inner">
				<div className="header__left-side">
					<NavLink to={PATHS.root} className="logo">
						<img src="/assets/img/header-logo.svg" alt=""/>
					</NavLink>
					<div className={`navigation ${isOpened && '_open'}`}>
						<div className="cancel-button" onClick={() => setOpened(false)}></div>
						<ul className="navigation__items">

							<li className="navigation__item" onClick={() => setOpened(false)}>
								<HashLink to={PATHS.find_domain} className="navigation__item-link">
									Find domain
								</HashLink>
							</li>
							<li className="navigation__item" onClick={() => setOpened(false)}>
								<HashLink to={PATHS.about} className="navigation__item-link">
									About
								</HashLink>
							</li>
							<li className="navigation__item" onClick={() => setOpened(false)}>
								<HashLink to={PATHS.partners} className="navigation__item-link">
									Partners
								</HashLink>
							</li>
							{ (process.env.REACT_APP_IS_SALE_STARTED === 'true') &&
								<li className="navigation__item" onClick={() => setOpened(false)}>
									<NavLink to={PATHS.account.domains.index} className="navigation__item-link">Account</NavLink>
								</li>
							}
							<li className="navigation__item" onClick={() => setOpened(false)}>
								<a className="navigation__item-link" href={process.env.REACT_APP_CHROMIUM_WIN_URL} >Download Chromium For Windows</a>
							</li>
							<li className="navigation__item" onClick={() => setOpened(false)}>
								<a className="navigation__item-link" href={process.env.REACT_APP_CHROMIUM_LINUX_URL} >Download Chromium For Linux</a>
							</li>
							{ /* <li className="navigation__item _mob _parent _open" onClick={() => setOpened(false)}>
								<HashLink to={PATHS.account.domains.index} className="navigation__item-link" href="#">
									Account
								</HashLink>
								<ul className="navigation__sublist">
									<li className="navigation__item">
										<HashLink to={PATHS.account.domains.index} className="navigation__item-link">
											My Domains
										</HashLink>
									</li>
									<li className="navigation__item">
										<HashLink to={PATHS.account.profile.index} className="navigation__item-link">
											My Profile
										</HashLink>
									</li>
									<li className="navigation__item">
										<HashLink to={PATHS.help} className="navigation__item-link">
											Help
										</HashLink>
									</li>
								</ul>
							</li> */}

						</ul>
					</div>
					<div className="navigation-button" onClick={() => setOpened(true)}></div>
				</div>

				<div className="header__content">
					{props.children}
				</div>
			</div>
		</div>
	);

}

export default Navbar;
