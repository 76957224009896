import {Fragment, ReactElement} from "react";
import {Currency} from "shared/types";

export interface TickerInterface {
  currency: Currency
}

export default function Ticker(props: TickerInterface): ReactElement | null {
  switch (props.currency) {
    case Currency.BUSD: return <Fragment>BUSD</Fragment>;
    case Currency.USDT: return <Fragment>USDT</Fragment>;
    default: return null;
  }
}

