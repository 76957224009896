import React from 'react';
import {NavLink} from "react-router-dom";
import {PATHS} from "router/paths";
import Button from "components/button/Button";

export default function RequestAccount () {

  return (
    <div className="claim__inner">
      <p>Thank you for using your credit card to purchase the web3 domain.</p>
      <p style={{marginBottom: '2rem'}}>Please connect your wallet to proceed.</p>
      <NavLink to={PATHS.connect}><Button>connect</Button></NavLink>
    </div>
  );

}
