import {AlphaPicker} from "react-color";
import {useEffect, useState} from "react";

export default function DomainPageEditorColorPickerAlpha(props: DomainPageEditorColorPickerColorInterface) {

  let [value, setValue] = useState(props.value);
  let [isActive, setActive] = useState(false);

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const handleChange = (value: number) => {
    setValue(value);

    if (props.onChange)
      props.onChange(value);
  }

  return (
      <>
        <div
            style={{backgroundColor: `rgba(0 0 0 / ${value * 100}%)`}}
            className={"color-picker-row_value_alpha"}
            onClick={() => setActive(!isActive)}>
        </div>
        {isActive && <div className={"color-picker-row_popover"}>
          <div className={"color-picker-row_popover_close"} onClick={() => setActive(false)}></div>
          <div className={"color-picker-row_value_alpha_wrapper"}>
            <AlphaPicker
              color={{a: value, r: 0, g: 0, b: 0}}
              onChange={color => setValue(color.rgb.a || 0)}
              onChangeComplete={color => handleChange(color.rgb.a || 0)}/>
          </div>

        </div>}
      </>
  );

}


export interface DomainPageEditorColorPickerColorInterface {
  value: number,
  onChange?: (value: number) => void
}