import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Currency} from "shared/types";
import {Coupon} from "./model/Coupon";
import {PaymentOption} from "./model/PaymentOption";

export interface CartState {
  coupon: Coupon | undefined
  currency: Currency
  domainNames: string[]
  paymentOption: PaymentOption
}

const initialState: CartState = {
  currency: Currency.USD,
  coupon: undefined,
  domainNames: [],
  paymentOption: PaymentOption.BUSD,
}

const slice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addDomain: (state, {payload}: PayloadAction<string>) => {
      if (state.domainNames.indexOf(payload) < 0) {
        state.domainNames.push(payload);
      }
    },
    addDomains: (state, {payload}: PayloadAction<string[]>) => {
      for (const item of payload) {
        if (state.domainNames.indexOf(item) < 0) {
          state.domainNames.push(item);
        }
      }
    },
    removeDomain: (state, {payload}: PayloadAction<string>) => {
      state.domainNames = state.domainNames.filter(name => name !== payload)
    },
    removeDomains: (state) => {
      state.domainNames = [];
    },
    restore: (state, {payload: {currency, domainNames}}: PayloadAction<{currency: Currency, domainNames: string[]}>) => {
      state.currency = currency;
      state.domainNames = domainNames;
    },
    setCurrency: (state, {payload}: PayloadAction<Currency>) => {
      state.currency = payload;
    },
    setPaymentOption: (state, {payload}: PayloadAction<PaymentOption>) => {
      state.paymentOption = payload;
    },
    updateCoupon: (state, {payload: coupon}: PayloadAction<Coupon>) => {
      state.coupon = coupon;
    }
  }
});

export const { actions, reducer } = slice;
