import './ImageInput.css';
import Button from "../button/Button";
import IconButton from "../iconButton/IconButton";
import {useEffect, useRef, useState} from "react";


export default function ImageInput(props: ImageInputInterface) {
  const type = props.type || 'rounded';

  const inputId = `id${Math.random()}${Math.random()}${Math.random()}`.replace(/\./, '');

  const [file, setFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | undefined>(props.defaultValue);

  const isFirstCall = useRef(true);
  useEffect(() => {
    if (isFirstCall.current) {
      isFirstCall.current = false;
      return;
    }

    setPreview(file ? window.URL.createObjectURL(file) : undefined);
    props.onChange(file);
  }, [file]);

  return (
      <div className={`default-image-input-input__wrapper _${type}`}>
        {
            props.label &&
          <label
            className="default-image-input-input__label"
            htmlFor={inputId}>{props.label}</label>
        }

        <div className="default-image-input-input__container">
          <div
              className="default-image-input-input__preview"
              style={{background: (preview ? getBackgroundStyle(preview) : '')}}
          ></div>

          <label htmlFor={inputId}>
            <Button
                className="default-image-input-input__browse-btn"
                color="gray"
                style={{paddingLeft: "20px", paddingRight: "20px"}}
            >Browse file</Button>
          </label>

          <IconButton
              color="gray"
              icon="trashcan"
              onClick={() => setFile(null)}
          />
        </div>

        <input
            id={inputId}
            type="file"
            accept="image/jpeg, image/png"
            style={{display: 'none'}}
            onChange={(e) => e.target.files !== null && setFile(e.target.files[0])}
        />

      </div>
  );
}

function getBackgroundStyle(file: string) {
  return `no-repeat center/cover url(${file})`;
}


export interface ImageInputInterface {
  label?: string,
  name?: string,
  type?: ImageInputType
  defaultValue?: string
  onChange: (file: File | null) => void
}

export type ImageInputType = 'squared' | 'rounded';