import {useEffect} from 'react';
import {useDispatch, useSelector} from 'store/hooks';
import {actions as orderActions, selectors as orderSelectors} from "features/order/store";
import Approval from "./components/Approval";
import Balance from "./components/Balance";
import OrderPlacement from "./components/OrderPlacement";
import Layout from "./components/Layout";
import './OrderPage.scss'
import {useSafeNavigate} from "../../hooks";
import {OrderStatus} from './store/model/OrderStatus';
import Mercuryo from "./components/Mercuryo";
import {useHistory} from "../../router/BrowserRouter";
import {PATHS} from "../../router/paths";
import {PaymentSystem} from "../../shared/model/PaymentSystem";
import {useLazyGetAccountQuery} from "../api/web3/slice";
import Loader from "../../components/loader/Loader";
import OrderFulfilment from "./components/OrderFulfilment";
import Congratulations from "./components/Congratulations";
import Heropayments from "./components/Heropayments";
import Wert from "./components/Wert/Wert";

export default function OrderPage () {

  const history = useHistory()
  const dispatch = useDispatch();
  const navigate = useSafeNavigate();
  const order = useSelector(orderSelectors.selectOrder);
  const [getAccount, {data: account, error: accountError, isLoading: isAccountLoading}] = useLazyGetAccountQuery();

  useEffect(() => history.listen(({location: {pathname}}) => {
    if (pathname === PATHS.connect || pathname === PATHS.auth.index) return;
    dispatch(orderActions.cancelOrder(true));
  }), []);

  useEffect(() => {
    if (typeof order === 'undefined') {
      navigate(-1);
    } else if (order.paymentSystem === PaymentSystem.Chain) {
      if (accountError) navigate(PATHS.connect);
      else if (!account) getAccount();
    }
  }, [order, account, accountError])

  if (order && isAccountLoading) return (
    <Layout order={order} >
      <div style={{display: 'flex', justifyContent: 'center'}}><Loader size='2rem'/></div>
    </Layout>
  )

  switch (order?.status) {
    case OrderStatus.CHECKING_BALANCE: return (
      <Layout order={order} >
        <Balance account={account} order={order}/>
      </Layout>
    )
    case OrderStatus.CHECKING_ALLOWANCE: return (
      <Layout order={order}>
        <Approval account={account} order={order}/>
      </Layout>
    )
    case OrderStatus.AWAITING_PAYMENT: switch (order.paymentSystem) {
      case PaymentSystem.Heropayments: return <Heropayments order={order}/>
      case PaymentSystem.Mercuryo: return <Mercuryo order={order}/>
      case PaymentSystem.Wert: return <Wert order={order}/>
    }
    case OrderStatus.PLACING_ORDER: return (
      <Layout order={order} >
        <OrderPlacement account={account} order={order}/>
      </Layout>
    )
    case OrderStatus.AWAITING_ORDER_FULFILMENT: return (
      <Layout order={order} >
        <OrderFulfilment orderId={order.id}/>
      </Layout>
    )
    case OrderStatus.ORDER_FULFILLED: return (
      <Layout order={order}>
        <Congratulations order={order}/>
      </Layout>
    )
    case OrderStatus.ORDER_CANCELLED: return (
      <Layout order={order} >
        <OrderFulfilment orderId={order.id}/>
      </Layout>
    )
  }

  return null;

}
