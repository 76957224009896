import './Textarea.css';
import {Component} from "react";


export default class Textarea extends Component {
  constructor(props) {
    super();

    this.id = props.id || ('id_' + Math.random().toString().replace('.', ''));
    this.id_wrapper = this.id + "_wrapper";
  }

  componentDidMount() {
    document.getElementById(this.id)
      .dispatchEvent(new Event('input', {'bubbles': true}));
  }

  handleInput(e) {
    document
      .getElementById(this.id_wrapper)
      .querySelector('.default-textarea__maxlength-counter')
      .innerText = e.target.value.length;
  }

  handleChange(e) {
    if (typeof this.props.onChange === "function")
      this.props.onChange(e);
  }

  render() {
    return (
      <div className="default-textarea__wrapper" id={this.id_wrapper}>
        {
          this.props.label &&
          <label
            className="default-textarea__label"
            htmlFor={this.id}>{this.props.label}</label>
        }

        <div className="default-textarea__container">
          <textarea
            id={this.id}
            className={'default-textarea ' + (this.props.className || '')}
            name={this.props.name || ''}
            placeholder={this.props.placeholder || ''}
            maxLength={this.props.maxLength || ''}
            defaultValue={this.props.children || this.props.defaultValue || ''}
            onInput={(e) => this.handleInput(e)}
            onChange={(e) => this.handleChange(e)}
          />
          {
            this.props.maxLength &&
            <div className="default-textarea__maxlength-counter__container">
              <span className="default-textarea__maxlength-counter">0</span>/
              <span className="default-textarea__maxlength-counter__maxvalue">{this.props.maxLength}</span>
            </div>
          }
        </div>

      </div>
    );
  }

}