export enum DomainPriceStatus {
  FREE = 'FREE',
  RESERVED = 'RESERVED',
  OWNER = 'OWNER',
  OWNED_ANOTHER_USER = 'OWNED_ANOTHER_USER',
  OWNER_UNKNOWN = 'OWNER_UNKNOWN',
  ERROR_GENERAL = 'ERROR_GENERAL',
  ERROR_FORBIDDEN_SYMBOL = 'ERROR_FORBIDDEN_SYMBOL',
  ERROR_IN_REFERER_DOMAIN_NAME = 'ERROR_IN_REFERER_DOMAIN_NAME',
  ERROR_FORBIDDEN_DOMAIN_NAME = 'ERROR_FORBIDDEN_DOMAIN_NAME'
}
