import {Fragment} from "react";
import Loader from 'components/loader/Loader';
import {formatToken} from "features/api/web3/lib/helpers";

export interface SearchResultProps {
  discountedPrice: string | undefined
  domain: string
  isAvailable: boolean
  isInCart: boolean
  isInvalid: boolean
  isLoading: boolean
  onClickSubmit: (name: string) => any
  onClickMakeOffer?: (name: string) => any
  price: string | undefined
}

export default function SearchResult(props: SearchResultProps) {
  const { domain, isAvailable, isInvalid, isLoading } = props;

  if (isInvalid) return (
    <div className="valid-message">[.] [\\] [,] whitespace and Cyrillic characters are prohibited</div>
  );

  return (
    <div className={`search-result-item ${isAvailable ? "available" : 'unavailable'}`}>
      <div className="search-result-item__name">{domain}</div>
      {isLoading ? (
        <Loader size='2em'/>
      ) : (
        <Fragment>
          <RenderStatus {...props}/>
          <RenderPrice {...props}/>
          <RenderButton {...props}/>
        </Fragment>
      )}
    </div>
  );

}

function RenderStatus(props: {isAvailable: boolean}) {
  if (props.isAvailable) return <div className='search-result-item__status _available'>available</div>
  return <div className='search-result-item__status _unavailable'>taken</div>
}

function RenderPrice(props: { price?: string, discountedPrice?: string, isAvailable: boolean }) {
  const {price, discountedPrice} = props;
  if (!props.isAvailable) return <div className="search-result-item__price"></div>
  if (price && discountedPrice && price !== discountedPrice) return (
      <div className="search-result-item__price">
        <span className="search-result-item__price__secondary">({formatToken(price)}&nbsp;USD)</span>
        &nbsp;
        <span className="search-result-item__price__primary">{formatToken(discountedPrice)}&nbsp;USD</span>
      </div>
  );
  if (price) return (
      <div className="search-result-item__price _no-discount">
        <span className="search-result-item__price__primary">{formatToken(price)}&nbsp;USD</span>
      </div>
  );
  return null;
}

function RenderButton(props: {domain: string, isAvailable: boolean, isInCart: boolean, onClickSubmit: (name: string) => any, onClickMakeOffer?: (name: string) => any}) {
  const {domain, isAvailable, isInCart, onClickMakeOffer, onClickSubmit} = props;
  const handleClickSubmit = () => onClickSubmit(domain);
  if (!isAvailable) return <div className='search-result-item__buy-btn'>Add to cart</div>
  if (isInCart) return <div onClick={handleClickSubmit} className='search-result-item__buy-btn action-added'>Added</div>
  return <div onClick={handleClickSubmit} className='search-result-item__buy-btn'>Add to cart</div>
}
