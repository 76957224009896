import {createSlice} from "@reduxjs/toolkit";
import {Auth, AuthStatus, AuthType} from "./types";
import {init, update, clear} from "./actions";


const initialState: Auth = {
  status: AuthStatus.UNINITIALIZED
}

const slice = createSlice({
  name: 'auth',
  initialState: initialState as Auth,
  reducers: {},
  extraReducers: builder =>
    builder
    .addCase(init, (state, {payload: type}) => {
      switch (type) {
        case AuthType.WEB3: return {status: AuthStatus.AWAITING_ADDRESS, type};
        case AuthType.PRIMARY: return {status: AuthStatus.AWAITING_TOKEN, type};
        default: return {status: AuthStatus.AWAITING_TEMPORARY_TOKEN, type};
      }
    })
    .addCase(update, (state, {payload}) => {
      return payload;
    })
    .addCase(clear, (state) => {
      return {status: AuthStatus.UNINITIALIZED};
    })
});

export const { actions, reducer } = slice;
