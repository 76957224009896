import {actions} from "./slice";
import {createAction} from "@reduxjs/toolkit";

export const {
  addDomain,
  addDomains,
  removeDomain,
  removeDomains,
  restore,
  setCurrency,
  setPaymentOption,
  updateCoupon
} = actions;

export const checkCoupon = createAction<string>('cart/checkCoupon');
