import {createApi} from '@reduxjs/toolkit/query/react'
import {PaymentSystem} from "shared/model/PaymentSystem";
import {Currency} from "shared/types";
import {stripUndefined} from "shared/util";
import {axiosBaseQuery} from "../util/axios";
import decodeWith from "../util/decodeWith";
import {CreateBuyTaskData, RESTProtocolResponseMessage, UserDomainsBuyTask} from './model';
import {Coupon} from "./model/Coupon";
import {DomainPricesData, DomainPricesDataCodec} from "./model/DomainPricesData";
import {RESTProtocolDataResponse, RESTProtocolDataResponseCodec} from "./model/RESTProtocolDataResponse";
import {CouponValidation, CouponValidationCodec} from "./model/CouponValidation";
import {HeropaymentsInvoice, HeropaymentsInvoiceCodec} from "./model/HeropaymentsInvoice";
import {WertPaymentSignedTransactionResponseData, WertPaymentSignedTransactionResponseDataCodec} from "./model/WertPaymentSignedTransactionResponseData";
import {ReferralData, ReferralDataCodec} from "./model/ReferralData";
import {OpenIdProviderType} from "./model/OpenIdProviderType";
import {AuthenticationResponse, AuthenticationResponseCodec} from "./model/AuthenticationResponse";
import {RootState} from "store";
import {AuthStatus} from "features/auth/store/types";

export const slice = createApi({
  reducerPath: 'api/sales',
  baseQuery: axiosBaseQuery({
    baseUrl: '/api/sales',
    prepareHeaders: (headers, { getState }) => {
      const auth = (getState() as RootState).auth;
      if (auth.status === AuthStatus.FULFILLED) headers.set('authorization', `Bearer ${auth.token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    auth: builder.mutation<AuthenticationResponse, {token: string, providerType: OpenIdProviderType}>({
      query: ({token, providerType}) => ({url: `openid`, method: 'post', data: {token, providerType} }),
      transformResponse: decodeWith(AuthenticationResponseCodec)
    }),
    checkCoupon: builder.query<RESTProtocolDataResponse<CouponValidation>, {account: string, coupon: string}>({
      query: ({account, coupon}) => ({url: 'coupons/validate', method: 'post', data: stripUndefined({address: account, couponName: coupon})}),
      transformResponse: decodeWith(RESTProtocolDataResponseCodec(CouponValidationCodec))
    }),
    claimDomains: builder.mutation<RESTProtocolDataResponse<UserDomainsBuyTask>, {account: string, uuid: string}>({
      query: ({account, uuid}) => ({url: 'domains/task/mint', method: 'post', data: {taskUUID: uuid, ownerAddress: account}}),
      transformResponse: decodeWith(RESTProtocolDataResponseCodec(UserDomainsBuyTask))
    }),
    fetchCoupons: builder.query<Coupon[], {accountId: number}>({
      query: ({accountId}) => ({url: `coupons/account/${accountId}`, method: 'get'}),
      transformResponse: ({data: {coupons}}) => coupons.map(decodeWith(Coupon))
    }),
    fetchOrder: builder.query<UserDomainsBuyTask, {id: number}>({
      query: ({id}) => ({url: `domains/task/${id}`, method: 'get'}),
      transformResponse: ({data}) => decodeWith(UserDomainsBuyTask)(data)
    }),
    fetchPrices: builder.query<RESTProtocolDataResponse<DomainPricesData>, {domainNames: string[], account?: string, coupon?: string, referrer?: string}>({
      query: ({domainNames, account, coupon, referrer}) => ({url: 'domains/price', method: 'post', data: stripUndefined({domainNames, buyerAddress: account, couponId: coupon, referrerId: referrer})}),
      transformResponse: decodeWith(RESTProtocolDataResponseCodec(DomainPricesDataCodec))
    }),
    fetchReferralData: builder.query<RESTProtocolDataResponse<ReferralData>, {accountId: number}>({
      query: ({accountId}) => ({url: `refprogram/reflink/${accountId}`, method: 'get'}),
      transformResponse: decodeWith(RESTProtocolDataResponseCodec(ReferralDataCodec))
    }),
    getTemporaryToken: builder.query<string, string>({
      query: address => ({url: `chain/auth/${address}`, method: 'get' })
    }),
    getJWTToken: builder.query<string, {temporaryToken: string, signature: string}>({
      query: ({temporaryToken, signature}) => ({url: `chain/auth`, method: 'post', data: {tempToken: temporaryToken, signature} }),
      transformResponse: (response: { token: string }) => response.token,
    }),
    placeOrder: builder.mutation<CreateBuyTaskData, {domainNames: string[], paymentSystem: PaymentSystem, currency: Currency, buyer?: string, coupon?: string, referrer?: string}>({
      query: ({domainNames, paymentSystem, currency, buyer, coupon, referrer}) => ({
        url: 'domains/task',
        method: 'post',
        data: stripUndefined({
          domainNames,
          paymentSystem: paymentSystem === PaymentSystem.Heropayments ? PaymentSystem.Mercuryo : paymentSystem,
          currency,
          buyerAddress: buyer,
          couponId: coupon,
          referrerId: referrer
        })
      }),
      transformResponse: ({data}) => decodeWith(CreateBuyTaskData)(data)
    }),
    requestHeropaymentsInvoice: builder.mutation<RESTProtocolDataResponse<HeropaymentsInvoice>, {orderId: number, successUrl: string, failUrl: string}>({
      query: ({orderId, successUrl, failUrl}) => ({url: 'payment/heropayments/request-invoice', method: 'post', data: {orderId, successUrl, failUrl}}),
      transformResponse: decodeWith(RESTProtocolDataResponseCodec(HeropaymentsInvoiceCodec))
    }),
    requestWertInvoice: builder.mutation<RESTProtocolDataResponse<WertPaymentSignedTransactionResponseData>, {orderId: number, email: string}>({
      query: ({orderId, email}) => ({url: 'payment/wert/signature', method: 'post', data: {taskId: orderId, email}}),
      transformResponse: decodeWith(RESTProtocolDataResponseCodec(WertPaymentSignedTransactionResponseDataCodec))
    }),
    signIn: builder.mutation<string, {login: string, password: string}>({
      query: ({login, password}) => ({url: 'auth', method: 'post', data: {login, password}}),
      transformResponse: (response: { token: string }) => response.token
    }),
    submitPartnersForm: builder.mutation<RESTProtocolResponseMessage, {name: string, url: string, email: string, company: string, comment: string}>({
      query: (form) => ({url: 'partners/submitForm', method: 'post', data: stripUndefined(form)}),
      transformResponse: decodeWith(RESTProtocolResponseMessage)
    })
  }),
});

export const {
  useAuthMutation,
  useCheckCouponQuery,
  useLazyCheckCouponQuery,
  useClaimDomainsMutation,
  useFetchCouponsQuery,
  useLazyFetchCouponsQuery,
  useFetchOrderQuery,
  useLazyFetchOrderQuery,
  useFetchPricesQuery,
  useFetchReferralDataQuery,
  useGetTemporaryTokenQuery,
  useGetJWTTokenQuery,
  useRequestHeropaymentsInvoiceMutation,
  useRequestWertInvoiceMutation,
  useLazyFetchPricesQuery,
  usePlaceOrderMutation,
  useSignInMutation,
  useSubmitPartnersFormMutation
} = slice
