export type ClaimSaga = {
  status: typeof ClaimSagaStatus.UNINITIALIZED
} | {
  status: typeof ClaimSagaStatus.AWAITING_ACCOUNT
  uuid: string
} | {
  status: typeof ClaimSagaStatus.SENDING_REQUEST
  account: string
  uuid: string
} | {
  status: typeof ClaimSagaStatus.AWAITING_FULFILLMENT
  account: string
  id: number
  uuid: string
} | {
  status: typeof ClaimSagaStatus.FULFILLED
  account: string
  id: number
  uuid: string
}

export const ClaimSagaStatus = {
  UNINITIALIZED: 'UNINITIALIZED',
  AWAITING_ACCOUNT: 'AWAITING_ACCOUNT',
  SENDING_REQUEST: 'SENDING_REQUEST',
  AWAITING_FULFILLMENT: 'AWAITING_FULFILLMENT',
  FULFILLED: 'FULFILLED'
} as const;

export type ClaimSagaStatus = typeof ClaimSagaStatus[keyof typeof ClaimSagaStatus]
