import './UploadDomain.scss';
import {Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from "store/hooks";
import SidebarLayout from "../../../components/layout/SidebarLayout/SidebarLayout";
import AccountSidebar from "../AccountSidebar/AccountSidebar";
import {useParams} from "react-router-dom";
import {useUploadMutation} from "features/api/upload/slice";
import {useFetchDomainNameQuery, useSetContentMutation, useSetContentRouteMutation} from "features/api/web3/slice";
import {actions as accountActions, selectors as accountSelectors} from "../store";
import UploadForm from "./components/UploadForm";
import TransactionSnippet from "./components/TransactionSnippet";
import {useSafeNavigate} from "../../../hooks";
import {HomeNavbar} from "../../home/HomeNavbar/HomeNavbar";

export default function UploadDomain () {

	const dispatch = useDispatch();
	const navigate = useSafeNavigate();
	const {id} = useParams();
	const state = useSelector(accountSelectors.selectUploadState)(id);
	const {data: domainName} = useFetchDomainNameQuery(id ?? '', {skip: !id})
	const [uploadDataToServer] = useUploadMutation();
	const [setContent, {data: contentTxHash, error: contentTxError, isLoading: isConentTxLoading}] = useSetContentMutation();
	const [setContentRoute, {data: routeTxHash, error: routeTxError, isLoading: isRouteTxLoading}] = useSetContentRouteMutation();
	const isWaitingForContentTx = state?.status === 'awaiting content tx' && typeof contentTxHash !== 'undefined';
	const isWaitingForRouteTx = state?.status === 'awaiting route tx' && typeof routeTxHash !== 'undefined';

	useEffect(() => {
		if (!id) return navigate(-1);
		else if (!state) dispatch(accountActions.upload.init(id));
		return () => { dispatch(accountActions.upload.cancel(id)) };
	}, []);

	const handleFormSubmit = async (values: any) => {
		if (state?.status !== 'awaiting file upload') return;
		const data = new FormData();
		data.append('tokenId', state.tokenId);
		data.append('index', values.page[0]);
		if (values.assets) data.append('zip', values.assets[0]);
		uploadDataToServer(data);
	}

	const handleSetContent = () => {
		if (state?.status !== 'awaiting content tx') return;
		const {tokenId, content, contentProvider} = state;
		setContent({tokenId, content, contentProvider});
	}

	const handleSetContentRoute = () => {
		if (state?.status !== 'awaiting route tx') return;
		const {tokenId, route} = state;
		setContentRoute({tokenId, route});
	}

	return (
		<SidebarLayout>
			<SidebarLayout.Navbar><HomeNavbar/></SidebarLayout.Navbar>
			<SidebarLayout.Sidebar><AccountSidebar/></SidebarLayout.Sidebar>
			<SidebarLayout.Body>
				<div className="uploaddomain__container">
					<h1 className="uploaddomain__domain-name">UPLOAD CONTENT</h1>
					{(() => {
						switch (state?.status) {
							case 'awaiting file upload': return (
								<Fragment>
									<h4>Validating your content</h4>
									<UploadForm onSubmit={handleFormSubmit}/>
								</Fragment>
							);
							case 'awaiting content tx': return (
								<Fragment>
									<h4>Uploading your content to blockchain</h4>
									<TransactionSnippet
										isLoading={isConentTxLoading}
										isWaiting={isWaitingForContentTx}
										hash={contentTxHash}
										error={contentTxError}
										onInit={handleSetContent}
										onClickRetry={handleSetContent}
										key='content'
									/>
								</Fragment>
							);
							case 'awaiting route tx': return (
								<Fragment>
									<h4>Updating content route</h4>
									<TransactionSnippet
										isLoading={isRouteTxLoading}
										isWaiting={isWaitingForRouteTx}
										hash={routeTxHash}
										error={routeTxError}
										onInit={handleSetContentRoute}
										onClickRetry={handleSetContentRoute}
										key='route'
									/>
								</Fragment>
							);
							case 'fulfilled': return (
								<Fragment>
									<div>Congratulations!</div>
									<div>You've successfully uploaded your content to blockchain.</div>
									{domainName &&
										<div>Please follow <a href={`${process.env.REACT_APP_GATEWAY_URL}/content?domainName=${encodeURIComponent(domainName)}&path=/`} target='_blank' rel='noreferrer'>this link</a> to see the result.</div>
									}
								</Fragment>
							)
							default: return null;
						}
					})()}
				</div>
			</SidebarLayout.Body>
		</SidebarLayout>
	);

}
