import './Sidebar.css';

export default function Sidebar({children, title, footerEl}) {
  return (
    <div className="sidebar__wrapper">
      <div className="sidebar__title">{title}</div>

      <div className="sidebar__list">{children}</div>

      {footerEl ? <div className="sidebar__footer">{footerEl}</div> : ''}

    </div>
  );
}