import './FileInput.scss';
import {Field} from "react-final-form";
import React from "react";

export default function FileInput(props: {name: string, label?: string}) {
  const {name, label} = props;
  return (
    <Field<FileList> name={name} type="file">{({ input: {value, onChange, ...input}, meta}) => (
      <div className="fileinput">
        <input {...input} className="fileinput__field" type="file" data-label={label} onChange={({ target }) => onChange(target.files)}/>
        { (meta.error || meta.submitError) && meta.touched &&
          <div className="fileinput__error">{meta.error || meta.submitError}</div>
        }
      </div>
    )}</Field>
  );
}
