import {createAction, createAsyncThunk} from "@reduxjs/toolkit";
import {web3} from "features/api/web3/lib";
import {AppError} from "shared/model/AppError";
import {UploadState} from "./types";


export const sign = createAsyncThunk<string, string, {rejectValue: AppError}>(
  'account/sign',
  async (message, { rejectWithValue }) => {
    try {
      return await web3.getPersonalSign(message);
    } catch (error: any) {
      return rejectWithValue(AppError(error));
    }
  }
)

export const upload = {
  init: createAction<string>('account/upload/create'),
  update: createAction<UploadState>('account/upload/update'),
  cancel: createAction<string>('account/upload/cancel'),
  delete: createAction<string>('account/upload/delete')
};
