import './AuthPage.scss';
import React, {Fragment, useEffect} from 'react';
import {useDispatch} from "react-redux";
import {NavLink} from "react-router-dom";
import {PATHS} from "router/paths";
import {useAuth, useSafeNavigate} from "hooks";
import {GoogleAuthButton} from "./components/google";
import {Web3AuthButton, Web3AuthPage} from "./components/web3";
import {AuthStatus, AuthType, OAuthData} from "./store/types";
import {FacebookAuthButton} from "./components/facebook";
import {actions as authActions} from './store';
import {useAuthMutation} from "../api/sales/slice";
import {OpenIdProviderType} from "../api/sales/model/OpenIdProviderType";
import {PrimaryAuthButton, PrimaryAuthPage} from "./components/primary";

export default function AuthPage () {

	const {isAuthorized, state} = useAuth();
	const dispatch = useDispatch();
	const navigate = useSafeNavigate();
	const [sendAuthRequest, {data, error}] = useAuthMutation();

	useEffect(() => {
		if (isAuthorized) navigate(-1);
	}, [isAuthorized]);

	const handleClickAuthButton = (authType: AuthType) => {
		dispatch(authActions.init(authType));
	}

	const handleSuccess = (data: OAuthData) => {
		if (data.authType === AuthType.FACEBOOK) {
			sendAuthRequest({token: data.accessToken, providerType: OpenIdProviderType.FACEBOOK});
		} else if (data.authType === AuthType.GOOGLE) {
			sendAuthRequest({token: data.tokenId, providerType: OpenIdProviderType.GOOGLE});
		}
	}

	return (
		<div className="auth">
			<div className="auth__inner">
				<div className="auth__header">
					<div className="logo">
						<img src="/assets/img/logo-retina.png" alt=""/>
					</div>
					<NavLink to={PATHS.root} className="auth__close">
						<img src="/assets/img/Desktop-retina-close-cart.svg" alt=""/>
					</NavLink>
				</div>
				<div className="auth__content">
					{(() => {
						if (state.status === AuthStatus.FULFILLED) return <div>Authorization successful</div>
						else if (state.status !== AuthStatus.UNINITIALIZED && state.type === AuthType.WEB3) return <Web3AuthPage/>
						else if (state.status !== AuthStatus.UNINITIALIZED && state.type === AuthType.PRIMARY) return <PrimaryAuthPage/>
						else return (
							<Fragment>
								<div style={{marginBottom: '2rem'}}>Please select your preferred authorization method</div>
								<div style={{marginBottom: '1rem'}}><PrimaryAuthButton onClick={handleClickAuthButton}/></div>
								<div style={{marginBottom: '1rem'}}><GoogleAuthButton onClick={handleClickAuthButton} onSuccess={handleSuccess}/></div>
								<div style={{marginBottom: '1rem'}}><FacebookAuthButton onClick={handleClickAuthButton} onSuccess={handleSuccess}/></div>
								<div style={{marginBottom: '1rem'}}><Web3AuthButton onClick={handleClickAuthButton}/></div>
							</Fragment>
						)
					})()}
				</div>
			</div>
		</div>
	);

}
