import * as t from "io-ts";


export const WertPaymentSignedTransactionResponseDataCodec = t.exact(t.type({
  address: t.string,
  commodity: t.string,
  commodity_amount: t.number,
  pk_id: t.string,
  sc_address: t.string,
  sc_id: t.string,
  sc_input_data: t.string,
  partner_id: t.string,
  signature: t.string,
  click_id: t.string,
  origin: t.string
}));

export type WertPaymentSignedTransactionResponseData = t.TypeOf<typeof WertPaymentSignedTransactionResponseDataCodec>;
