import {AppStartListening} from "store";
import * as cartActions from "./actions";
import {slice as salesApi} from 'features/api/sales/slice';
import {slice as web3Api} from 'features/api/web3/slice';
import {Coupon} from "./model/Coupon";

export default function(startListening: AppStartListening) {
  startListening({
    matcher: cartActions.checkCoupon.match,
    effect: async ({payload: value}, api) => {
      const coupon: Coupon = {value, status: 'loading'};
      api.dispatch(cartActions.updateCoupon(coupon))
      let {data: account} = await api.dispatch(web3Api.endpoints.getAccount.initiate());
      if (!account) {
        api.dispatch(cartActions.updateCoupon({...coupon, status: 'account required'}));
        [{payload: account}] = await api.take(web3Api.endpoints.connect.matchFulfilled);
      }
      api.dispatch(cartActions.updateCoupon({...coupon, status: 'loading'}));
      const {data, error} = await api.dispatch(salesApi.endpoints.checkCoupon.initiate({account, coupon: coupon.value}));
      if (data) {
        api.dispatch(cartActions.updateCoupon({...coupon, status: data.data.isValid ? 'valid' : 'invalid'}));
      } else if (error) {
        api.dispatch(cartActions.updateCoupon({...coupon, error, status: 'error'}));
      }
    }
  });
}
