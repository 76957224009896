import {AppStartListening, RootState} from "store";
import * as orderActions from "./actions";
import * as orderSelectors from './selectors';
import {actions as cartActions} from 'features/cart/store';
import {isAnyOf, ListenerEffectAPI} from "@reduxjs/toolkit";
import {OrderStatus} from "./model/OrderStatus";
import {getOrThrow} from "shared/util";
import {slice as salesApi} from 'features/api/sales';
import {slice as web3Api, matchers as web3Matchers} from 'features/api/web3';
import {parseWei} from "features/api/web3/lib/helpers";
import {BuyTaskStatus} from "../../api/sales/model";
import {PaymentSystem} from "shared/model";
import {OrderAwaitingFulfillment, OrderPaidInCrypto, OrderPaidInFiat} from "./model/Order";


export default function(startListening: AppStartListening) {
  startListening({
    matcher: isAnyOf(orderActions.createOrder.match, orderActions.cancelOrder.match),
    effect: async (action, api) => {
      if (orderActions.cancelOrder.match(action)) return handleOrderCancellation(api, action.payload);
      api.dispatch(cartActions.removeDomains());
      let order = getOrThrow(orderSelectors.selectOrder(api.getState()));
      if (order.paymentSystem === PaymentSystem.Chain) {
        order = await handleOrderPaidInCrypto(api, order);
      } else {
        order = await handleOrderPaidInFiat(api, order);
      }
      await handleOrderFulfillment(api, order);
    }
  });
}

async function handleOrderPaidInFiat(api: ListenerEffectAPI<RootState, any>, order: OrderPaidInFiat): Promise<OrderAwaitingFulfillment> {
  api.dispatch(orderActions.updateOrder({...order, status: OrderStatus.PLACING_ORDER}))
  const [{payload: {task: {taskId: id}}}] = await api.take(salesApi.endpoints.placeOrder.matchFulfilled);
  api.dispatch(orderActions.updateOrder({...order, id, status: OrderStatus.AWAITING_PAYMENT}));
  await api.condition(action => orderActions.markOrderAsPaid.match(action) && action.payload.id === id);
  api.dispatch(orderActions.updateOrder({...order, id, status: OrderStatus.AWAITING_ORDER_FULFILMENT}));
  return {...order, id, status: OrderStatus.AWAITING_ORDER_FULFILMENT};
}

async function handleOrderPaidInCrypto(api: ListenerEffectAPI<RootState, any>, order: OrderPaidInCrypto): Promise<OrderAwaitingFulfillment> {
  api.dispatch(orderActions.updateOrder({...order, status: OrderStatus.CHECKING_BALANCE}))
  const total = parseWei(order.discountedAmount);
  await api.condition(action => web3Api.endpoints.fetchBalance.matchFulfilled(action) && total.lte(action.payload));
  api.dispatch(orderActions.updateOrder({...order, status: OrderStatus.CHECKING_ALLOWANCE}))
  await api.pause(Promise.any([
    api.condition(action => web3Api.endpoints.fetchAllowance.matchFulfilled(action) && total.lte(action.payload)),
    api
      .take(action => web3Api.endpoints.approve.matchFulfilled(action) && total.lte(action.meta.arg.originalArgs.amount))
      .then(([{payload}]) => api.condition(web3Matchers.isSuccessfulTx(payload)))
  ]));
  api.dispatch(orderActions.updateOrder({...order, status: OrderStatus.PLACING_ORDER}))
  const [{payload: {task: {taskId: id}}}] = await api.take(action =>
    salesApi.endpoints.placeOrder.matchFulfilled(action) && 'status' in action.payload.task && action.payload.task.status === BuyTaskStatus.IN_PROGRESS
  );
  api.dispatch(orderActions.updateOrder({...order, id, status: OrderStatus.AWAITING_ORDER_FULFILMENT}));
  return {...order, id, status: OrderStatus.AWAITING_ORDER_FULFILMENT};
}

async function handleOrderFulfillment(api: ListenerEffectAPI<RootState, any>, order: OrderAwaitingFulfillment) {
  await api.take(action => {
    if (!salesApi.endpoints.fetchOrder.matchFulfilled(action)) return false;
    const {payload: {status}} = action;
    if (status === BuyTaskStatus.FINISHED || status === BuyTaskStatus.RESERVATION_FINISHED) {
      api.dispatch(orderActions.updateOrder({...order, status: OrderStatus.ORDER_FULFILLED}));
      return true;
    } else if (status === BuyTaskStatus.CANCELLED) {
      api.dispatch(orderActions.updateOrder({...order, status: OrderStatus.ORDER_CANCELLED}));
      return true;
    } else {
      return false;
    }
  })
}

function handleOrderCancellation(api: ListenerEffectAPI<RootState, any>, shouldRestoreCart: boolean | undefined) {
  api.cancelActiveListeners();
  const order = getOrThrow(orderSelectors.selectOrder(api.getState()), 'Could not retrieve Order during order cancellation');
  if (shouldRestoreCart) {
    const isForbiddenToRestore =
      order.status === OrderStatus.AWAITING_ORDER_FULFILMENT ||
      order.status === OrderStatus.ORDER_FULFILLED
    if (!isForbiddenToRestore) api.dispatch(cartActions.restore({currency: order.currency, domainNames: order.items.map(item => item.name)}));
  }
  api.dispatch(orderActions.deleteOrder());
}
