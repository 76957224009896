import './Footer.css';
import {PATHS} from "../../router/paths";
import {HashLink} from "react-router-hash-link";
import {NavBarLinks} from "../navbar/NavBarLinks";


export default function Footer() {

  return (
      <div className="footer__container">
        <div className="inner">

          <div className="footer__nav__container">
            <div className="logo">
              <img src="/assets/img/footer-logo.svg" alt=""/>
            </div>
            <div className="footer__nav__content">
              {NavBarLinks.map((item, i) => <HashLink to={item.link} className='link' key={i}>{item.anchor}</HashLink>)}
            </div>
          </div>

          <div className="footer">
            <div className="copyright">Copyright © 2022 *W3DNA</div>
            <div className="soc-icons">
              <a href={PATHS.external_soc_discord} className="icon _dc" target="_blank" rel="noreferrer"></a>
              <a href={PATHS.external_soc_medium} className="icon _md" target="_blank" rel="noreferrer"></a>
              <a href={PATHS.external_soc_telegram} className="icon _tg" target="_blank" rel="noreferrer"></a>
              <a href={PATHS.external_soc_twitter} className="icon _tw" target="_blank" rel="noreferrer"></a>
              <a href={PATHS.external_soc_youtube} className="icon _yt" target="_blank" rel="noreferrer"></a>
              <a href={PATHS.external_soc_linkedin} className="icon _li" target="_blank" rel="noreferrer"></a>
            </div>
          </div>

        </div>
      </div>
  );

}
