import React, {useEffect} from 'react';
import Button from "../../../components/button/Button";
import {useClaimDomainsMutation} from "../../api/sales/slice";
import Loader from "../../../components/loader/Loader";

export default function SendRequest (props: {account: string, uuid: string}) {
  const {account, uuid} = props;
  const [claimDomains, {error, isLoading}] = useClaimDomainsMutation();

  useEffect(() => {
    claimDomains({account, uuid});
  },[])

  const onClickSumbit = () => {
    claimDomains({account, uuid});
  }

  if (error) return (
    <div className="claim__inner">
      <div>Sorry. We could not complete your purchase.</div>
      <div style={{marginBottom: '2rem'}}>{error?.message}</div>
      <Button onClick={onClickSumbit}>Retry</Button>
    </div>
  );

  if (isLoading) return (
    <div className="claim__inner">
      <div style={{marginBottom: '2rem'}}>Sending request to complete your purchase.</div>
      <div style={{display: 'flex', justifyContent: 'center'}}><Loader size='2rem'/></div>
    </div>
  );

  return (
    <div className="claim__inner"/>
  );

}
