import {createApi} from '@reduxjs/toolkit/query/react'
import {axiosBaseQuery} from "../util/axios";
import {RootState} from "store";
import {AuthStatus} from "features/auth/store/types";


export const slice = createApi({
  reducerPath: 'api/upload',
  baseQuery: axiosBaseQuery({
    baseUrl: '/api/upload',
    prepareHeaders: (headers, { getState }) => {
      const auth = (getState() as RootState).auth;
      if (auth.status === AuthStatus.FULFILLED) headers.set('authorization', `Bearer ${auth.token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    auth: builder.mutation<string, {temporaryToken: string, signature: string}>({
      query: ({temporaryToken, signature}) => ({url: `chain/auth`, method: 'post', data: {tempToken: temporaryToken, signature} }),
      transformResponse: (response: { token: string }) => response.token,
    }),
    getToken: builder.mutation<string, string>({
      query: address => ({url: `chain/auth/${address}`, method: 'get' })
    }),
    upload: builder.mutation<{content: string, contentProvider: string, nft: string, route: string}, FormData>({
      query: data => ({url: `chain/upload`, method: 'post', data }),
      transformResponse: (response) => {
        const { addressForContent, bnsNftContractAddress, contentProviderContractAddress, zippedEncryptedHtml } = response as any;
        return {
          content: zippedEncryptedHtml,
          contentProvider: contentProviderContractAddress,
          nft: bnsNftContractAddress,
          route: addressForContent
        };
      }
    })
  }),
});

export const { useUploadMutation } = slice
