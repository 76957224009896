import {contracts} from "features/api/web3/lib";
import {Currency} from "./types";
import {isPlainObject} from "@reduxjs/toolkit";

export function assertNonNullish<T>(value: T, message?: string): asserts value is NonNullable<T> {
  if (value === null || value === undefined) throw Error(message || 'Assertion failed: tested value is null or undefined');
}

export function getOrThrow<T>(value: T, message?: string): NonNullable<T> {
  assertNonNullish(value, message);
  return value;
}

export function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

export function getAssetContract(currency: Currency) {
  switch (currency) {
    case Currency.BUSD: return contracts.busd;
    case Currency.USDT: return contracts.usdt;
    default: throw Error('Unsupported currency');
  }
}

export function getAssetKey(currency: Currency): string {
  switch (currency) {
    case Currency.BUSD: return contracts.busd.address as string;
    case Currency.USDT: return contracts.usdt.address as string;
    default: throw Error('Unsupported currency');
  }
}

export function stripUndefined(obj: any) {
  if (!isPlainObject(obj)) {
    return obj;
  }
  const copy: Record<string, any> = { ...obj };
  for (const [k, v] of Object.entries(copy)) {
    if (v === undefined) delete copy[k];
  }
  return copy;
}

