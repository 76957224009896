import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PaymentSystem} from "shared/model/PaymentSystem";
import {Currency} from "shared/types";
import {Order} from "./model/Order";
import {OrderStatus} from "./model/OrderStatus";
import {PaymentOption} from "../../cart/store/model/PaymentOption";

export interface CartState {
  order: Order | undefined
}

const initialState: CartState = {
  order: undefined,
}

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    createOrder: (state, action: PayloadAction<Omit<Order, 'status' | 'paymentSystem' | 'currency'> & {paymentOption: PaymentOption}>) => {
      const { paymentOption, ...rest } = action.payload;
      const [paymentSystem, currency] = mapPaymentOption(paymentOption)
      state.order = {
        ...rest,
        paymentSystem,
        currency,
        status: OrderStatus.INITIAL,
      } as Order;
    },
    deleteOrder: (state) => {
      state.order = undefined;
    },
    updateOrder: (state, action: PayloadAction<Order>) => {
      state.order = action.payload;
    }
  }
});

export const { actions, reducer } = slice;

function mapPaymentOption(paymentOption: PaymentOption) {
  switch (paymentOption) {
    case PaymentOption.Heropayments: return [PaymentSystem.Heropayments, Currency.BUSD]
    case PaymentOption.Mercuryo: return [PaymentSystem.Mercuryo, Currency.BUSD]
    case PaymentOption.BUSD: return [PaymentSystem.Chain, Currency.BUSD]
    case PaymentOption.USDT: return [PaymentSystem.Chain, Currency.USDT]
    case PaymentOption.Wert: return [PaymentSystem.Wert, Currency.BUSD]
  }
}
