import Navbar from "components/navbar/Navbar";
import Footer from "components/footer/Footer";

import '../BaseLayout/BaseLayout.css';
import './SidebarLayout.css';

import {Component, PropsWithChildren} from "react";

export default class SidebarLayout extends Component {
  static Navbar = NavbarSlot;
  static Sidebar = SidebarSlot;
  static Body = BodySlot;

  componentDidMount() {
    if (window.innerWidth <= 500) {
      // @ts-ignore
      document.body.style.zoom = (window.innerWidth / 414)
    }
  }

  render() {
    const {children}: PropsWithChildren = this.props;
    // @ts-ignore
    const navbar = children.find(child => child.type === NavbarSlot)
    // @ts-ignore
    const sidebar = children.find(child => child.type === SidebarSlot)
    // @ts-ignore
    const body = children.find(child => child.type === BodySlot)

    return (
      <div className="wrapper">
        {navbar ? navbar.props.children : <Navbar/>}

        <div className="sidebar-layout inner">
          <div className="sidebar-layout__sidebar">
            {sidebar ? sidebar.props.children : null}
          </div>

          <div className="sidebar-layout__content">
            {body ? body.props.children : null}
          </div>
        </div>

        <Footer/>
      </div>
    )
  }
}

function NavbarSlot(props: PropsWithChildren) {
  return null;
}

function SidebarSlot(props: PropsWithChildren) {
  return null;
}

function BodySlot(props: PropsWithChildren) {
  return null;
}
