import React, {ChangeEvent, FormEvent, useRef, useState} from 'react';
import store from "store";
import {useSelector} from "store/hooks";
import {PATHS} from "router/paths";
import {actions as cartActions, selectors as cartSelectors} from "features/cart/store";
import {useLazyFetchPricesQuery} from "features/api/sales/slice";
import {DomainPrice} from "features/api/sales/model";
import SearchResult from "./SearchResult";
import './MainSearchForm.scss';
import {useReferrer, useSafeNavigate} from "../../../hooks";
import {useGetAccountQuery} from "../../api/web3/slice";
import Button from "../../../components/button/Button";

export default function MainSearchForm(props: { placeholder: string }) {
  const navigate = useSafeNavigate();
  const isInCart = useSelector(cartSelectors.isInCart);
  const coupon = useSelector(cartSelectors.selectCoupon);
  const referrer = useReferrer();
  const [isBackgroundActive, setBackgroundActive] = useState(false);
  const [isDropdownActive, setDropdownActive] = useState(false);
  const [name, setName] = useState('');
  const {data: account} = useGetAccountQuery();
  const [fetchPrices, {domain, isLoading}] = useLazyFetchPricesQuery({
    selectFromResult: ({data, error, isLoading}) => ({
      domain: data?.data.domainPrices.map(toDomain)[0],
      error,
      isLoading
    })
  });
  const searchInput = useRef<HTMLInputElement>(null);

  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!name.length) return;
    setBackgroundActive(true);
    setDropdownActive(true);
    await fetchPrices({
      domainNames: [name],
      account,
      coupon: coupon?.status === 'valid' ? coupon.value : undefined,
      referrer
    });
  }

  const handleAddDomain = () => {
    if (!domain) return;
    if (isInCart(domain.name)) navigate(PATHS.cart);
    else store.dispatch(cartActions.addDomain(domain.name));
  }

  const handleFormFocus = () => {
    setBackgroundActive(true);
    if (searchInput.current !== null)
      searchInput.current.focus();
  }

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => setName(e.target.value);

  const handleCloseDropdown = () => {
    setBackgroundActive(false);
    setDropdownActive(false);
  }

  return (
      <div>
        <div className={`search-field-wrapper`} onClick={handleFormFocus}>
          <input
              type="text"
              name="domain"
              placeholder={props.placeholder}
              className="search-field"
          />
          <button className="search-btn" type="submit"></button>
        </div>
        <div className={`search-frame ${isBackgroundActive ? 'active' : ''}`}>
          <div className={`search-field-popup`}>
            <div className={"close-btn"} onClick={handleCloseDropdown}></div>
            <form className={`search-field-wrapper ${isBackgroundActive ? 'active' : ''}`} onSubmit={handleFormSubmit}>
              <input
                  type="text"
                  name="domain"
                  ref={searchInput}
                  placeholder={props.placeholder}
                  className="search-field"
                  onChange={handleFormChange}
              />
              <button className="search-btn" type="submit">Search</button>
              <div className="search-result">
                {isDropdownActive && domain &&
                  <>
                    <SearchResult
                      domain={domain.name}
                      isAvailable={domain.isAvailable || false}
                      isInCart={isInCart(domain.name)}
                      isInvalid={!domain.isValid}
                      isLoading={isLoading}
                      price={domain.price}
                      discountedPrice={domain.discountedPrice}
                      onClickSubmit={handleAddDomain}
                    />

                    <div className="search-result__footer">
                      {isInCart(domain.name) &&
                        <Button className={"btn"} onClick={handleAddDomain} shape="squared">Go to Cart</Button>
                      }
                    </div>
                  </>
                }
              </div>
            </form>
          </div>
        </div>
      </div>
  );
}

type Domain = {
  discountedPrice: string | undefined
  name: string
  isAvailable: boolean
  isValid: boolean
  price: string | undefined
}

function toDomain(domainPrice: DomainPrice): Domain {
  const { domainName, normalizedDomainName, price, priceWithDiscount, status} = domainPrice;
  return {
    discountedPrice: priceWithDiscount,
    name: normalizedDomainName || domainName,
    isAvailable: status === "FREE",
    isValid: status !== "ERROR_FORBIDDEN_SYMBOL",
    price
  }
}
