import './HomeNavbar.css';
import Navbar from "../../../components/navbar/Navbar";
import {Component} from "react";
import store from "../../../store";
import {NavLink} from "react-router-dom";
import {PATHS} from "../../../router/paths";


export class HomeNavbar extends Component {
  state = {count: 0}

  updateCartCounter = () => {
    this.setState({
      count: store.getState().cart.domainNames.length
    })
  }

  componentDidMount() {
    this.updateCartCounter();
  }

  render() {
    return (
      <Navbar>

        <NavLink
          to={PATHS.presentation}
          className={`presentation ${this.state.count === 0 ? '' : 'd-ms-none'}`}
          target="_blank"
        >
          Presentation
        </NavLink>

        <NavLink
          to={PATHS.cart}
          className="cart-btn pos-ms-absolute">
        </NavLink>
      </Navbar>
    );
  }
}

