import {Fragment, PropsWithChildren} from "react";
import {NavLink} from "react-router-dom";
import {PATHS} from "router/paths";
import Ticker from "components/ticker/Ticker";
import {Order} from "../store/model/Order";
import {formatToken} from "features/api/web3/lib/helpers";


export default function Layout(props: PropsWithChildren & {order: Order}) {
  const {order} = props;
  return (
    <div className="order">
      <div className="order__inner">
        <div className="order__header">
          <div className="logo"><NavLink to={PATHS.root} className="logo"><img src="/assets/img/logo-retina.png" alt=""/></NavLink></div>
          <NavLink to={PATHS.root} className="order__close"><img src="/assets/img/Desktop-retina-close-cart.svg" alt=""/></NavLink>
        </div>
        <div className="order__content">
          <Fragment>
            <div className="order__title">BUY {order.items.length > 1 ? 'DOMAINS' : 'DOMAIN'}</div>
            <div>You are buying domains:</div>
            <ul className='order__domains'>
              {order.items.map((item, i) => (<li key={i} className='order__domain'>{item.name}</li>))}
            </ul>
            <div className="order__price">
              Order total: <span className="order__discounted-price">{formatToken(order.discountedAmount)}&nbsp;<Ticker currency={order.currency}/></span>
              &ensp;
              <span className="order__full-price" style={{display: (order.discountedAmount === order.totalAmount ? 'none' : 'inline')}}>({formatToken(order.totalAmount)}&nbsp;<Ticker currency={order.currency}/>)</span>
            </div>
            <div className="order__submit-wrapper">
              <div className="order__info">
                {props.children}
              </div>
            </div>
          </Fragment>
        </div>
      </div>
    </div>
  );
}



