import * as t from "io-ts";
import * as e from "fp-ts/Either";


/**
 * Produce a Map populated with an enumeration's inverted key-value pair(s).
 */
export type ReverseMap<T> = Map<{ [K in keyof T]: T[K] }[keyof T], keyof T>;

/**
 * The Utils service class provides a collection of convenience methods.
 */
export class Utils {

  /**
   * Given an enumeration created in Typescript, produce an io-ts validator schema.
   *
   * @see https://github.com/gcanti/io-ts/issues/216
   *
   * @example
   * ```typescript
   * enum Foo {
   *   bar = 'baz',
   * }
   *
   * const TFizz = t.type({
   *   buzz: Utils.enum<Foo>(Foo),
   * });
   * ```
   *
   * @param {E} enumeration
   * The enumeration object.
   *
   * @param {string} name
   * An optional name of the enumeration type.
   *
   * @returns {t.Type<T>}
   * An io-ts validation schema for the enumeration.
   */
  public static enum<T, E extends object = object>(
    enumeration: E,
    name: string = 'enum',
  ): t.Type<T> {
    return new (class extends t.Type<T> {
      public readonly reverseMap: ReverseMap<E>;

      constructor() {
        super(
          name,
          (value: unknown): value is T => this.reverseMap.has(value as E[keyof E]),
          (value: unknown, context: t.Context): e.Either<t.Errors, T> =>
            this.is(value) ? t.success(value) : t.failure(value, context),
          t.identity,
        );

        this.reverseMap = Utils.enumReverseMap(enumeration);
      }
    })();
  }

  /**
   * Given an arbitrary enumeration, generate the reverse lookup map.
   *
   * @param {object} enumeration
   * The enumeration.
   *
   * @returns {Map}
   * A Mapping where the key is the value and the value is the key.
   */
  public static enumReverseMap<E extends object>(enumeration: E): ReverseMap<E> {
    return new Map(Object.entries(enumeration).map(([key, value]) => [value, key as keyof E]));
  }
}
