import './Input.css';
import {Component} from "react";


export default class Input extends Component {
  constructor(props) {
    super();

    this.id = props.id || ('id_' + Math.random().toString().replace('.', ''));
    this.id_wrapper = this.id + '_wrapper';
  }

  handleChange(e) {
    if (typeof this.props.onChange === "function")
      this.props.onChange(e)
  }

  handleBlur(e) {
    if (typeof this.props.onBlur === "function")
      this.props.onBlur(e)
  }

  render() {
    return (
      <div className="default-input__wrapper" id={this.id_wrapper}>
        {
          this.props.label &&
          <label
            className="default-input__label"
            htmlFor={this.id}>{this.props.label}</label>
        }
        <div className="default-input__container">
          <input
            id={this.id || ''}
            type={this.props.type || 'text'}
            min={this.props.min !== undefined ? this.props.min : ''}
            max={this.props.max !== undefined ? this.props.max : ''}
            maxLength={this.props.maxLength !== undefined ? this.props.maxLength : ''}
            minLength={this.props.minLength !== undefined ? this.props.minLength : ''}
            className={'default-input ' + (this.props.className || '')}
            name={this.props.name || ''}
            placeholder={this.props.placeholder || ''}
            defaultValue={this.props.defaultValue || ''}
            onChange={e => this.handleChange(e)}
            onBlur={e => this.handleBlur(e)}
          />

          {
            this.props.subText &&
            <div
              className="default-input__sub-label"
            >{this.props.subText}</div>
          }
        </div>

      </div>
    );
  }
}