import * as t from "io-ts";
import {Utils} from "../../util/util";
import {DomainPriceStatus} from "./DomainPriceStatus";

export const DomainPrice = t.type({
  discount: t.number,
  domainName: t.string,
  isPremium: t.boolean,
  normalizedDomainName: t.union([t.string, t.undefined]),
  price: t.union([t.string, t.undefined]),
  priceWithDiscount: t.union([t.string, t.undefined]),
  status: Utils.enum<DomainPriceStatus>(DomainPriceStatus)
});

export type DomainPrice = t.TypeOf<typeof DomainPrice>;
