import './AuthPage.scss';
import React from 'react';
import BaseLayout from "../../../components/layout/BaseLayout/BaseLayout";
import {HomeNavbar} from "../../home/HomeNavbar/HomeNavbar";

export default function AuthPage() {
	return (
			<BaseLayout>
				<BaseLayout.Navbar>
					<HomeNavbar/>
				</BaseLayout.Navbar>
				<BaseLayout.Body>
					<div className="inner">
						<div className="registration">
							<div className="registration__title">Sign Up</div>

							<div className="registration__continue-list">
								<div className="registration__continue-item">
									<img src="/assets/img/cont-email-icon.svg" alt=""/>
									Continue with Email
								</div>
								<div className="registration__continue-item">
									<img src="/assets/img/cont-gmail-icon.svg" alt=""/>
									Continue with Google
								</div>
								<div className="registration__continue-item">
									<img src="/assets/img/cont-tw-icon.svg" alt=""/>
									Continue with Twitter
								</div>
								<div className="registration__continue-item">
									<img src="/assets/img/cont-fb-icon.svg" alt=""/>
									Continue with Facebook
								</div>
								<div className="registration__continue-item">
									<img src="/assets/img/cont-wallet-icon.svg" alt=""/>
									Continue with Facebook
								</div>
							</div>

							<div className="registration__policy">
								By proceeding I agree to w3dna’s <a href="features/auth/RegTemplates#">Privacy Policy</a> and <br/>
								<a href="features/auth/RegTemplates#"> Terms and Conditions</a>
							</div>

							<div className="registration__footer">
								<div className="registration__footer-text">Already have an account?</div>
								<div className="registration__btn">
									Sign Up
								</div>
							</div>
						</div>
						<br/>
						<br/>
						<hr/>
						<div className="registration">
							<div className="registration__title">Sign Up</div>

							<form className="login-form">
								<div className="login-form__fields">
									<div className="login-form__field-wrap">
										<div className="login-form__field-error _top">Invalid password or email</div>
										<input type="text" placeholder="Email" className="login-form__field"/>
									</div>
									<div className="login-form__field-wrap">
										<input type="text" placeholder="Password" className="login-form__field _pass"/>
										<div className="login-form__field-icon-eye"></div>
										<div className="login-form__field-error _bottom">Week password</div>
									</div>
								</div>
								<button className="registration__btn">
									Sign Up
								</button>
							</form>

							<div className="registration__footer">
								<div className="registration__footer-text">Don’t have an account?</div>
								<div className="registration__btn _white">
									Sign Up
								</div>
							</div>
						</div>
						<br/>
						<br/>
						<hr/>
						<div className="registration">
							<div className="registration__title">Connect your Wallet</div>

							<div className="choice-wallet">
								<div className="choice-wallet__item">
									<div className="choice-wallet__item-preview">
										<img src="/assets/img/metamask-img.svg" alt=""/>
									</div>
									<div className="choice-wallet__item-name">MetaMask</div>
								</div>
								<div className="choice-wallet__item">
									<div className="choice-wallet__item-preview">
										<img src="/assets/img/wallet-img.svg" alt=""/>
									</div>
									<div className="choice-wallet__item-name">WalletConnect</div>
								</div>
							</div>
						</div>
					</div>
				</BaseLayout.Body>
			</BaseLayout>
	);

}
