const PATHS = {
  all: "*",
  auth: {
    index: '/auth'
  },
  root: "/",
  login: "/login",
  logout: "/logout",
  search: "/search",
  market: "/market",
  about: '/market#about',
  find_domain: '/market#search',
  find_domain_footer: '/market#search-footer',
  partners: "/market#partner",
  investors: "/investors",
  developers: "/developers",
  presentation: "/presentation",
  integrations: "/integrations",
  cart: '/cart',
  claim: {index: '/claim'},
  congratulations: {index: '/congratulations'},
  order: {index: '/order',},
  connect: '/connect',
  required_wallet_connect: "/required_wallet_connection",
  defaultAfterLogin: "/",
  sales_forecast: '/sales_forecast',
  welcome: "/welcome",
  wert: '/wert',

  blog: "/blog",
  blog_item: "/blog/:path",

  account: {
    index: '/account',
    auth: {index: '/account/auth'},
    coupons: {
      index: '/account/coupons'
    },
    domains: {
      index: '/account/domains',
      upload: (id: string) => `/account/domains/${id}/upload`
    },
    profile: {index: '/account/profile'},
    referralprogram: {
      index: '/account/referralprogram'
    },
    register: {
      email: {index: '/account/register/email'}
    }
  },

  help: '/#faq',

  error_404: '/not_found',

  external_presentation: 'https://drive.google.com/file/d/1kNVNvKEu0sDpj3z-1cArV7PXcaC13oXy/view',
  external_sales_forecast: 'https://docs.google.com/spreadsheets/d/1MNvDpjQtbDogp0mYgkvF9Sh14R6vvBMlTZCe-vbFoik/edit',
  external_soc_medium: 'https://medium.com/@web3dna',
  external_soc_telegram: 'https://t.me/w3dna_official',
  external_soc_twitter: 'https://twitter.com/web3dna',
  external_soc_youtube: 'https://www.youtube.com/channel/UCRYkT0xsxgjxNlqss-cp8Qg',
  external_soc_linkedin: 'https://www.linkedin.com/company/w3dna/',
  external_soc_discord: 'https://discord.com/invite/nfZ9jKy7qK',
};

export {PATHS};
