import {PATHS} from "../../router/paths";

const NavBarLinks = [
  {anchor: 'About', link: PATHS.about},
  {anchor: 'Find domain', link: PATHS.find_domain},
  {anchor: 'Partners', link: PATHS.partners},
  {anchor: 'Blog', link: PATHS.blog},
];


export {NavBarLinks};
