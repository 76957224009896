import * as client from "./client";

export function getPageHtml(info: object) {
  return client.post('get_domain_page_html', info);
}

export function uploadImage(file: File) {
  return client.upload('upload_temp_image', file);
}

export function getBgImages() {
  return client.get('get_bg_list');
}