import {createApi} from '@reduxjs/toolkit/query/react'
import {web3BaseQuery} from "../util/web3";
import {contracts, web3} from "features/api/web3/lib";
import {Currency} from "shared/types";
import {getAssetContract} from "shared/util";
import {TxReceipt, TxReceiptCodec} from "./model/TxReceipt";
import decodeWith from "../util/decodeWith";


export const tags = {
  account: 'account',
  connection: 'connection'
} as const;

export const slice = createApi({
  reducerPath: 'api/web3',
  baseQuery: web3BaseQuery(),
  tagTypes: Object.values(tags),
  endpoints: (builder) => ({
    approve: builder.mutation<string, {currency: Currency, spender: string, amount: string}>({
      query: ({currency, spender, amount}) => async () => {
        const contract = getAssetContract(currency);
        const { hash } = await contract.approve(spender, amount);
        return hash;
      }
    }),
    connect: builder.mutation<string, void>({
      query: () => async () => {
        await web3.connect();
        const [account] = await web3.getAccounts();
        return account;
      },
      async onQueryStarted(_, {queryFulfilled, dispatch}) {
        try {
          await queryFulfilled;
          web3.onAccountsChange((addresses) => dispatch(slice.util.invalidateTags([tags.account])));
          web3.onDisconnect(() => dispatch(slice.util.invalidateTags([tags.connection])));
          web3.onChainChange(() => dispatch(slice.util.invalidateTags([tags.account])));
        } catch {}
      },
      invalidatesTags: [tags.connection]
    }),
    fetchAllowance: builder.query<string, {currency: Currency, owner: string, spender: string}>({
      query: ({currency, owner, spender}) => (() => {
        const contract = getAssetContract(currency);
        return contract.fetchAllowance(owner, spender);
      })
    }),
    fetchBalance: builder.query<string, {account: string, currency: Currency}>({
      query: ({account, currency}) => (() => {
        const contract = getAssetContract(currency);
        return contract.fetchBalance(account);
      })
    }),
    fetchDomains: builder.query<{id: string, name: string}[], {account: string}>({
      query: ({account}) => () => contracts.domainNFT.fetchDomains(account),
    }),
    fetchDomainName: builder.query<string, string>({
      query: tokenId => () => contracts.domainNFT.fetchDomainName(tokenId),
    }),
    getAccount: builder.query<string, void>({
      query: () => async () => {
        const [account] = await web3.getAccounts();
        return account;
      },
      providesTags: [tags.account]
    }),
    getPersonalSign: builder.query<string, string>({
      query: (message: string) => async () => await web3.getPersonalSign(message)
    }),
    isConnected: builder.query<boolean, void>({
      query: () => web3.isConnected,
      providesTags: [tags.connection]
    }),
    setContent: builder.mutation<string, {tokenId: string, content: string, contentProvider: string}>({
      query: ({tokenId, content, contentProvider}) => async () => {
        const { hash } = await contracts.contentProvider.setContent(tokenId, content, contentProvider);
        return hash;
      },
    }),
    setContentRoute: builder.mutation<string, {tokenId: string, route: string}>({
      query: ({tokenId, route}) => async () => {
        const { hash } = await contracts.domainNFT.setContentRoute(tokenId, route)
        return hash;
      },
    }),
    waitForTransaction: builder.query<TxReceipt, {transactionHash: string}>({
      query: ({transactionHash}) => async () => await web3.waitForTransaction(transactionHash),
      transformResponse: decodeWith(TxReceiptCodec)
    })
  }),
});

export const {
  useApproveMutation,
  useConnectMutation,
  useFetchAllowanceQuery,
  useLazyFetchAllowanceQuery,
  useFetchBalanceQuery,
  useLazyFetchBalanceQuery,
  useFetchDomainNameQuery,
  useLazyFetchDomainsQuery,
  useFetchDomainsQuery,
  useGetAccountQuery,
  useLazyGetAccountQuery,
  useGetPersonalSignQuery,
  useIsConnectedQuery,
  useSetContentMutation,
  useSetContentRouteMutation,
  useLazyWaitForTransactionQuery
} = slice
