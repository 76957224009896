import {SketchPicker} from "react-color";
import {useEffect, useState} from "react";

export default function DomainPageEditorColorPickerColor(props: DomainPageEditorColorPickerColorInterface) {

  let [color, setColor] = useState(props.color);
  let [isActive, setActive] = useState(false);

  useEffect(() => {
    setColor(props.color)
  }, [props.color])

  const handleChange = (color: string) => {
    setColor(color);

    if (props.onChange)
      props.onChange(color);
  }

  return (
      <>
        <div
            style={{backgroundColor: color}}
            className={"color-picker-row_value_hex"}
            onClick={() => setActive(!isActive)}>
        </div>
        {isActive && <div className={"color-picker-row_popover"}>
          <div className={"color-picker-row_popover_close"} onClick={() => setActive(false)}></div>
          <SketchPicker
            color={color}
            onChange={color => setColor(color.hex)}
            onChangeComplete={color => handleChange(color.hex)}/>

        </div>}
      </>
  );

}


export interface DomainPageEditorColorPickerColorInterface {
  color: string,
  onChange?: (color: string) => void
}