import React from "react";

export interface FormattedDateInterface {
  date?: string | Date,
  lang?: string
}

export default function FormattedDate(props: FormattedDateInterface) {
  let {date, lang} = props;

  date = date ? new Date(date) : new Date();
  lang = lang || "en";

  return (
      <>
        {date.toLocaleString(lang, {month: 'short'})}&nbsp;
        {date.getDate()},&nbsp;
        {date.getFullYear()}
      </>
  );
}