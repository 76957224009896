import {Fragment, useEffect} from 'react';
import Button from "components/button/Button";
import {Order} from "../store/model/Order";
import {OrderStatus} from "../store/model/OrderStatus";
import {usePlaceOrderMutation} from "../../api/sales/slice";
import Loader from "../../../components/loader/Loader";
import {PATHS} from "../../../router/paths";
import {useSafeNavigate} from "../../../hooks";
import {TaskCancelCause} from "../../api/sales/model";

export default function (props: {account: string | undefined, order: Order}) {
  const {account, order} = props;
  const navigate = useSafeNavigate();
  const [placeOrder, {data: orderData, error: orderError, isLoading: isOrderLoading, originalArgs: placeOrderOriginalArgs}] = usePlaceOrderMutation();

  useEffect(() => {
    if (order.status === OrderStatus.PLACING_ORDER)
      placeOrder({
        domainNames: order.items.map(({name}) => name),
        paymentSystem: order.paymentSystem,
        currency: order.currency,
        buyer: account,
        coupon: order.coupon,
        referrer: order.referrer
      })
  }, []);

  if (orderError && placeOrderOriginalArgs) return (
    <Fragment>
      <div>Could not place order</div>
      <div style={{marginBottom: '2rem'}}>{orderError?.message}</div>
      <Button onClick={() => placeOrder(placeOrderOriginalArgs)}>Retry</Button>
    </Fragment>
  );
  if (orderData && 'cancelCause' in orderData.task) return (
    <Fragment>
      <div>Your order was rejected</div>
      <div style={{marginBottom: '2rem'}}>{RenderRejectionReason(orderData.task.cancelCause)}</div>
      <Button onClick={() => navigate(PATHS.cart)}>Return to Cart</Button>
    </Fragment>
  );
  if (isOrderLoading) return (
    <Fragment>
      <div style={{marginBottom: '2rem'}}>Placing order</div>
      <div style={{display: 'flex', justifyContent: 'center'}}><Loader size='2rem'/></div>
    </Fragment>
  )

  return null;

}

function RenderRejectionReason(cancelCause: TaskCancelCause | undefined) {
  switch (cancelCause) {
    case TaskCancelCause.INSUFFICIENT_ALLOWANCE: return "Insufficient allowance";
    case TaskCancelCause.INSUFFICIENT_FUNDS: return "Insufficient funds";
    case TaskCancelCause.WRONG_DOMAIN_NAME: return "Invalid domain name";
    default: return "Something went wrong";
  }
}
