import './HomeContent.css';
import React from "react";
import HomeContentFAQ from "./HomeContentFAQ";
import MainSearchForm from "../MainSearchForm/MainSearchForm";
import {HashLink} from "react-router-hash-link";
import {PATHS} from "../../../router/paths";

export default function HomeContent() {

  return (
    <div>

      <div className="domain-block" id="about">
        <div className="domain-block__title">Get *DNA for Web 3.0 & Enjoy</div>
        <div className="domain-block__items">
          <div className="domain-block__item _domain">Domain</div>
          /
          <div className="domain-block__item _name">Name</div>/
          <div className="domain-block__item _account">Account</div>
        </div>
        <div className="text-block _domain">
          <div className="text-block__preview _mac">

          </div>
          <div className="text-block__info">
            <div className="text-block__title">
              DOMAIN
            </div>
            <div className="text-block__text">
              Domain to host a decentralized
              website in any language with no
              extension. Upload your website
              directly to blockchain to secure
              it from DDOS-attacks and censorship.
              Own your domain forever - no renewal
              and hosting fees.
            </div>
            <HashLink to={PATHS.find_domain_footer} className="text-block__button">
              Choose Domain
            </HashLink>
          </div>
        </div>
      </div>

      <div className="text-block-wrapper">
        <div className="text-block _name">
          <div className="text-block__preview _name-block">

          </div>
          <div className="text-block__info">
            <div className="text-block__title">
              NAME
            </div>
            <div className="text-block__text">
              Username and identity for Web 3.0 apps: GameFi,
              DEX, DAO, decentralized messengers,
              social networks.
            </div>
            <HashLink to={PATHS.find_domain_footer} className="text-block__button">
              Choose Name
            </HashLink>
          </div>
        </div>
      </div>


      <div className="text-block _account">
        <div className="text-block__preview _account">
        </div>
        <div className="text-block__info">
          <div className="text-block__title">
            ACCOUNT
          </div>
          <div className="text-block__text">
            Web 3.0 account to receive cryptocurrencies
            in any chain. Forget about long and confusing
            addresses - use your *DNA instead.
          </div>
          <HashLink to={PATHS.find_domain_footer} className="text-block__button">
            Choose Account
          </HashLink>
        </div>
      </div>

      <div className="be-the-one">
        <div className="be-the-one__title">Be the One in Web 3.0</div>
        <div className="be-the-one__subtitle">Domain without extension</div>
        <div className="be-the-one__text">
          W3DNA reinvented NFT domains and erased
          extensions. Now everyone has the opportunity to
          be the one in Web 3.0. The only *Vitalik, *John or *Mike.
        </div>
      </div>


      <div className="view-supported-wrapper" id="partner">
        <div className="inner">
          <div className="view-supported">
            <div className="view-supported__title">
              Surf the Decentralised <br/>
              Web with *W3DNA
            </div>
            <div className="view-supported__subtitle">
              Supported Browsers
            </div>

            <div className="supported-items">
              <div className="supported-item">
                <div className="supported-item__inner">
                  <div className="supported-item__label">Coming soon</div>
                  <div className="supported-item__icon">
                    <div className="supported-item__img b1"></div>
                  </div>
                  <div className="supported-item__name">*W3DNA</div>
                </div>
              </div>
              <div className="supported-item">
                <div className="supported-item__inner">
                  <div className="supported-item__label">Coming soon</div>
                  <div className="supported-item__icon">
                    <div className="supported-item__img b2"></div>
                  </div>
                  <div className="supported-item__name">Brave</div>
                </div>
              </div>
              <div className="supported-item">
                <div className="supported-item__inner">
                  <div className="supported-item__label">Coming soon</div>
                  <div className="supported-item__icon">
                    <div className="supported-item__img b3"></div>
                  </div>
                  <div className="supported-item__name">Opera</div>

                </div>
              </div>
              <div className="supported-item">
                <div className="supported-item__inner">
                  <div className="supported-item__label">Coming soon</div>
                  <div className="supported-item__icon">
                    <div className="supported-item__img b4"></div>
                  </div>
                  <div className="supported-item__name">Google Chrome</div>
                </div>
              </div>
              <div className="supported-item">
                <div className="supported-item__inner">
                  <div className="supported-item__label">Coming soon</div>
                  <div className="supported-item__icon">
                    <div className="supported-item__img b5"></div>
                  </div>
                  <div className="supported-item__name">Microsoft Edge</div>

                </div>
              </div>
              <div className="supported-item">
                <div className="supported-item__inner">
                  <div className="supported-item__label">Coming soon</div>
                  <div className="supported-item__icon">
                    <div className="supported-item__img b6"></div>
                  </div>
                  <div className="supported-item__name">Mozilla Firefox</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="capabilities">
        <div className="inner">
          <div className="capabilities__items">
            <div className="capabilities__item">
              <div className="capabilities__item-icon _com"></div>
              <div className="capabilities__item-name">No extension</div>
            </div>
            <div className="capabilities__item">
              <div className="capabilities__item-icon _dollar">$</div>
              <div className="capabilities__item-name">No renewal fees</div>
            </div>
            <div className="capabilities__item">
              <div className="capabilities__item-icon _any-symbol"></div>
              <div className="capabilities__item-name"> Any symbol</div>
            </div>
            <div className="capabilities__item">
              <div className="capabilities__item-icon _any-language"></div>
              <div className="capabilities__item-name">Any language</div>
            </div>
          </div>
        </div>

      </div>
      <HomeContentFAQ/>

      <div className="search-block" id="search-footer">
        <div className="inner">
          <div className="search-block__title">Choose your *DNA for Web 3.0</div>
          <div className="search-block__field-wrapper">
            <MainSearchForm placeholder={'Any symbol & language; no extension'}/>
          </div>

        </div>
      </div>
    </div>

  );
}
