import {NavLink} from "react-router-dom";
import Sidebar from "components/sidebar/Sidebar";
import {PATHS} from "router/paths";
// @ts-ignore
import styles from './AccountSidebar.module.css';
import {useAuth} from "hooks";
import {actions as authActions} from 'features/auth/store';
import {useDispatch} from "store/hooks";


export default function AccountSidebar() {
  const {subject} = useAuth();
  const dispatch = useDispatch();
  const handleClickLogoutButton = () => dispatch(authActions.invalidate());

  return (
      <Sidebar title="Account" footerEl={footerEl(subject?.address, handleClickLogoutButton)}>
        {/*<NavLink end to={PATHS.account.profile.index}>my profile</NavLink>*/}
        <NavLink end to={PATHS.account.coupons.index}>Coupons <span className="counter">$150</span></NavLink>
        <NavLink end to={PATHS.account.domains.index}>Domains <span className="counter">8</span></NavLink>
        <NavLink end to={PATHS.account.referralprogram.index}>Referrals <span className="counter">2</span></NavLink>
        {/*<NavLink end to={PATHS.root}>help</NavLink>*/}
      </Sidebar>
  );

}

function footerEl(address: string = '', onClickLogout: () => void) {
  return (
    <div>
      <div>
        {address.replace(/(.{12})(.+)(.{4})/, '$1…$3')}
      </div>
      <button type='button' className={styles.logoutButton} onClick={onClickLogout}>Logout&nbsp;<span className={styles.logoutIcon}></span></button>
    </div>
  );
}
