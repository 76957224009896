import * as t from "io-ts";
import { Utils } from "../../util/util";
import {Currency} from "shared/types";
import {BuyTaskStatus} from "./BuyTaskStatus";
import {TaskCancelCause} from "./TaskCancelCause";
import {PaymentSystem} from "./PaymentSystem";

export const UserDomainsBuyTask = t.type({
  amount: t.union([t.string, t.undefined]),
  amountWithDiscount: t.string,
  cancelCause: t.union([Utils.enum<TaskCancelCause>(TaskCancelCause), t.undefined]),
  currency: Utils.enum<Currency>(Currency),
  discount: t.number,
  paymentSystem: t.union([Utils.enum<PaymentSystem>(PaymentSystem), t.undefined]),
  status: Utils.enum<BuyTaskStatus>(BuyTaskStatus),
  taskId: t.number
});

export type UserDomainsBuyTask = t.TypeOf<typeof UserDomainsBuyTask>;
