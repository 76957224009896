import {ChangeEvent} from "react";
import {PaymentOption} from "./store/model/PaymentOption";


export default function PaymentOptions(props: {value: PaymentOption, onChange: (value: PaymentOption) => any}) {

  const isChecked = (value: PaymentOption) => props.value === value;

  return (
    <div>
      <div className="added-items-label">
        <div className="added-items-label__counter">4</div>
        Select payment method
      </div>

      <div className="select-pay-buttons">
        <RenderPaymentOption
            value={PaymentOption.Wert}
            isChecked={isChecked}
            label="Credit card"
            onChange={props.onChange}>
          <img src="/assets/img/pay-iocn7.svg" alt=""/>
          <img src="/assets/img/pay-icon5.svg" alt=""/>
        </RenderPaymentOption>

        <RenderPaymentOption
            value={PaymentOption.USDT}
            isChecked={isChecked}
            label="USDT (Bep-20)"
            onChange={props.onChange}>
          <img src="/assets/img/t-icon.svg" alt=""/>
        </RenderPaymentOption>

        <RenderPaymentOption
            value={PaymentOption.Heropayments}
            isChecked={isChecked}
            label="Crypto (BTC, ETH, USDT etc)"
            onChange={props.onChange}>
          <img src="/assets/img/pay-icon8.svg" alt=""/>
        </RenderPaymentOption>


      </div>
    </div>
  );
}

function RenderPaymentOption(props: {
  children?: React.ReactNode;
  isChecked: (value: PaymentOption) => boolean,
  label: string,
  onChange: (value: PaymentOption) => any,
  value: PaymentOption
}) {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => props.onChange(event.target.value as PaymentOption);
  return (
    <div className="form_radio">
      <input type="radio" id={props.value} value={props.value} onChange={handleChange}
             checked={props.isChecked(props.value)}/>
      <label htmlFor={props.value}>{props.label} {props.children}</label>
    </div>
  );
}
