import {useDispatch} from "store/hooks";
import {useAuth, useSafeNavigate} from "hooks";
import styles from './RegisterEmail.module.scss';
import Modal from "components/modal/Modal";
import {Form, FormRenderProps} from "react-final-form";
import TextInput from "../components/TextInput";
import Button from "../../../components/button/Button";


export default function RegisterEmail () {

  const {isAuthorized, subject} = useAuth();
  const dispatch = useDispatch();
  const navigate = useSafeNavigate();

  const handleFormSubmit = (values: {[k: string]: any}) => {
    const {email} = values;
    // const result = requestInvoice({orderId: order.id, email})
  }

  const validateForm = (values: {[k: string]: any}) => {
    const errors: {[k: string]: any} = {};
    if (!values.email) {
      errors.email = 'This field is equired';
    } else {
      const isEmailValid = values.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      if (!isEmailValid) errors.email = 'Must be a valid email format (e.g., username@coolexample.com)';
    }
    return errors;
  }

  return (
    <Modal>
      <div className={styles.wrapper}>
        <Form onSubmit={handleFormSubmit} validate={validateForm} render={renderForm}/>
      </div>
    </Modal>
  );
}

function renderForm(props: FormRenderProps) {
  const {submitError, handleSubmit, submitting} = props;
  return (
    <form onSubmit={handleSubmit}>
      <TextInput name='email' label='Email' placeholder='example@email.com'/>
      {submitError && <div className="">{submitError}</div>}
      <div style={{textAlign: 'center'}}>
        <Button type="submit" disabled={submitting} shape='squared'>Submit</Button>
      </div>
    </form>
  );
}
