import React from 'react';
import BaseLayout from "components/layout/BaseLayout/BaseLayout";
import './CongratulationsPage.scss';
import {PATHS} from "../../router/paths";
import {NavLink} from "react-router-dom";
import {HomeNavbar} from "../home/HomeNavbar/HomeNavbar";

export default function CongratulationsPage() {
  return (
    <BaseLayout>
      <BaseLayout.Navbar>
        <HomeNavbar/>
      </BaseLayout.Navbar>
      <BaseLayout.Body>
        <div className="congrat-wrapper">
          <div className="inner">
            <div className="congrat-title">
              <span>Congratulations on purchasing your domain name!</span> <br/>
              purchase completed successfully
            </div>
            <NavLink to={PATHS.account.domains.index} className="congrat-button">MY DOMAINS</NavLink>
          </div>
        </div>
      </BaseLayout.Body>
    </BaseLayout>
  );

}
