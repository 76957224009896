import {useEffect, useState} from "react";
import Textarea from "../../../../components/textarea/Textarea";
import ImageInput from "../../../../components/imageInput/ImageInput";
import {uploadImage} from "../DomainPageEditorApi";


export default function DomainPageEditorPostsItem(props: DomainPageEditorPostsItemInterface) {

  const [post, setPost] = useState(Object.assign({
    text: '',
    img: ''
  }, props.post));

  const setPhoto = async (file: File | null) => {
    if (!file)
      return setPost(prevState => Object.assign({...prevState}, {img: ''}));

    let links: any = await uploadImage(file);
    setPost(prevState => Object.assign({...prevState}, {img: links.full}));
  }

  useEffect(() => {
    props.onChange(post);
  }, [post]);

  return (
      <div className={"domain-page-editor_posts_item"}>
        <Textarea
            label="text"
            onChange={(e: any) => setPost(prevState => Object.assign({...prevState}, {text: e.target.value}))}
            maxLength="2000">{post.text}</Textarea>

        <ImageInput
            label="Image"
            defaultValue={props.post.img}
            onChange={setPhoto}
            type="squared"/>
      </div>
  );
}


export interface DomainPageEditorPostsItemInterface {
  post: { text: string, img: string }
  onChange: (posts: object | null) => void,
}