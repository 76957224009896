import { getContract, getContractWithSigner } from "../util";


const CONTRACT_ABI = [
  'function balanceOf(address owner) view returns (uint256)',
  'function isDomainNameExists(string memory domainName) view returns (bool)',
  'function setContentOrAddressByTokenId(uint256 tokenId, string content, uint8 contentType, address contentProvider)',
  'function tokenIdToDomainNames(uint256) view returns (string)',
  'function tokenOfOwnerByIndex(address owner, uint256 index) view returns (uint256)'
];

const CONTRACT_ADDRESS = process.env.REACT_APP_WEB3_DOMAIN_NFT_ADDRESS;

export default {
  address: CONTRACT_ADDRESS,
  async isAvailable(domainName) {
    const contract = getContract(CONTRACT_ADDRESS, CONTRACT_ABI);
    const isExists = await contract.isDomainNameExists(domainName);
    return !isExists;
  },
  async fetchDomains(owner) {
    const contract = getContract(CONTRACT_ADDRESS, CONTRACT_ABI);
    const amount = await contract.balanceOf(owner);
    return await Promise.all(
      [...Array(amount.toNumber()).keys()].map(async (index) => {
        const tokenId = await contract.tokenOfOwnerByIndex(owner, index);
        const tokenName = await contract.tokenIdToDomainNames(tokenId);
        return {id: tokenId.toString(), name: tokenName};
      })
    );
  },
  /**
   * @param {string} tokenId
   * @return {Promise<string>}
   */
  async fetchDomainName(tokenId) {
    const contract = getContract(CONTRACT_ADDRESS, CONTRACT_ABI);
    return await contract.tokenIdToDomainNames(tokenId);
  },
  /**
   * @param {string} tokenId
   * @param {string} path
   * @return {Promise<TransactionResponse>} transaction object
   */
  async setContentRoute(tokenId, path) {
    const contractWithSigner = getContractWithSigner(CONTRACT_ADDRESS, CONTRACT_ABI);
    return await contractWithSigner.setContentOrAddressByTokenId(tokenId, path, 1, '0x0000000000000000000000000000000000000000');
  },
};
