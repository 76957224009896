import {useEffect} from 'react';
import {NavLink} from "react-router-dom";
import {PATHS} from "router/paths";
import './Mercuryo.scss'
import Loader from "components/loader/Loader";
import {OrderStatus} from "../store/model/OrderStatus";
import {PaymentSystem} from "shared/model";
import {Order} from "../store/model/Order";
import {useRequestHeropaymentsInvoiceMutation} from "features/api/sales/slice";


export default function Heropayments (props: {order: Order}) {

  const {order} = props;
  const isValidState = order?.status === OrderStatus.AWAITING_PAYMENT && order.paymentSystem === PaymentSystem.Heropayments;
  const [requestInvoice, {data, error, isLoading}] = useRequestHeropaymentsInvoiceMutation();

  useEffect(() => {
    if (!isValidState) return;
    const successUrl = `${window.location.protocol}//${window.location.host}${PATHS.congratulations.index}`
    const failUrl = `${window.location.protocol}//${window.location.host}?fail=true`
    requestInvoice({orderId: order.id, successUrl, failUrl});
  }, [])

  useEffect(() => {
    if (data) window.location.href = data.data.invoiceUrl;
  }, [data])

  return (
    <div className="mercuryo">
      <div className="mercuryo__inner">
        <div className="mercuryo__header">
          <div className="logo">
            <NavLink to={PATHS.root} className="logo">
              <img src="/assets/img/logo-retina.png" alt=""/>
            </NavLink>
          </div>
          <NavLink to={PATHS.root} className="mercuryo__close">
            <img src="/assets/img/Desktop-retina-close-cart.svg" alt=""/>
          </NavLink>
        </div>
        <div className="mercuryo__content">
          { isLoading ? <div className="mercuryo__loader"><Loader size='2rem'/></div> : <div className="mercuryo__loader">Awaiting payment</div> }
        </div>
      </div>
    </div>
  );

}
