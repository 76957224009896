import {Component} from "react";
import Footer from "components/footer/Footer";
import './BaseLayout.css';

export default class BaseLayout extends Component {
  static Navbar = NavbarSlot;
  static Body = BodySlot;

  componentDidMount() {
    if (window.innerWidth <= 500)
      document.body.style.zoom = (window.innerWidth / 414)
  }

  render() {

    const {children} = this.props;
    const navbar = children.find(child => child.type === NavbarSlot)
    const body = children.find(child => child.type === BodySlot)

    return (
      <div className="wrapper">
        {navbar ? navbar.props.children : null}

        {body ? body.props.children : null}

        <Footer/>
      </div>
    );
  }
}

function NavbarSlot(props) {
  return null;
}

function BodySlot(props) {
  return null;
}
