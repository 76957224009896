import React, {useState} from "react";

export default function HomeContentFAQ() {
  const [activeTab, setActiveTab] = useState(0);
  const [activeItem, setActiveItemState] = useState(0);

  const setActiveItem = (newActiveItem: number) => {
    setActiveItemState(activeItem === newActiveItem ? -1 : newActiveItem);
  }

  return (
      <div className="faq" id="faq">
        <div className="inner">
          <div className="faq__title">Frequently asked questions</div>
          <div className={'faq-tabs-content ' + (activeTab === 0 ? '_is-active' : '')}>
            <div className="dropdown">
              <div className={'title ' + (activeItem === 0 ? 'expanded' : '')} onClick={() => setActiveItem(0)}>
                What are the advantages of buying *W3DNA domain names?
              </div>
              <div className={'text ' + (activeItem === 0 ? 'expanded' : '')}>
                *W3DNA domains will be registered in any language, even in hieroglyphs in any language and
                symbol with no extension and length limits.
              </div>
            </div>
            <div className="dropdown">
              <div className={'title ' + (activeItem === 1 ? 'expanded' : '')} onClick={() => setActiveItem(1)}>
                How can I use my *DNA?
              </div>
              <div className={'text ' + (activeItem === 1 ? 'expanded' : '')}>
                <p>*W3DNA domain usage:</p>
                <p>- Resell the best domain names</p>
                <p>- Launch your own indexed sites</p>
                <p>- Receive payments in cryptocurrency to your domain from friends and clients</p>
                <p>- Create your personal pages in a decentralized social network</p>
                <p>- Communicating in the decentralized messenger</p>
              </div>
            </div>
            <div className="dropdown">
              <div className={'title ' + (activeItem === 2 ? 'expanded' : '')} onClick={() => setActiveItem(2)}>
                What chains will I get a free mint on?
              </div>
              <div className={'text ' + (activeItem === 2 ? 'expanded' : '')}>
                Mint once on three chains: BSC, Ethereum, and Polygon.
              </div>
            </div>
            <div className="dropdown">
              <div className={'title ' + (activeItem === 3 ? 'expanded' : '')} onClick={() => setActiveItem(3)}>
                Why does *W3DNA have no extensions?
              </div>
              <div className={'text ' + (activeItem === 3 ? 'expanded' : '')}>
                The *W3DNA domain has no extension: Vitalik is simply better than Vitalik.eth
              </div>
            </div>
            <div className="dropdown">
              <div className={'title ' + (activeItem === 4 ? 'expanded' : '')} onClick={() => setActiveItem(4)}>
                Are *W3DNA NFT domains part of IPFS or ICANN?
              </div>
              <div className={'text ' + (activeItem === 4 ? 'expanded' : '')}>
                *W3DNA is an NFT domain in Web3.0, they are not part of the current DNS. *W3DNA protocol
                is a blockchain-built-in tool to index content that allows refuse IPFS and other bridging.
              </div>
            </div>
            <div className="dropdown">
              <div className={'title ' + (activeItem === 5 ? 'expanded' : '')} onClick={() => setActiveItem(5)}>
                What is the maximum length of a name I can register?
              </div>
              <div className={'text ' + (activeItem === 5 ? 'expanded' : '')}>
                *W3DNA domains will be registered with no length limit.
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
