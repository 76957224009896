import React, {useRef, useState} from "react";
import {blogSubscribeEmail} from "./BlogApi";


export default function BlogSubscribeForm() {

  const [isSubscribed, setSubscribed] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = (e: React.MouseEvent) => {
    if (!inputRef.current) return;

    let email = inputRef.current.value;

    if (!email.match(/.@.{3,}\..{2,}/))
      return;

    blogSubscribeEmail(email).then(() => setSubscribed(true));
  }

  const renderSuccessMessage = function () {
    return (<>
      <div className="subscribe-form__field-wrapper">
        Successfully subscribed!
        <div className="subscribe-form__field-description">&nbsp;</div>
      </div>
    </>);
  }

  const renderForm = function () {
    return (<>
      <div className="subscribe-form__field-wrapper">
        <input ref={inputRef} type="text" className="subscribe-form__field" placeholder="Your email"/>
        <div className="subscribe-form__field-description">
          Once a week. No spam, ever.
        </div>
      </div>

      <button className="subscribe-form__submit-button" onClick={(e) => handleClick(e)}>
        SUBSCRIBE
      </button>
    </>);
  }

  return (
      <div className="subscribe-form">
        <div className="subscribe-form__middle-block">
          <div className="subscribe-form__title">GET THE LATEST UPDATES</div>
          <div className="subscribe-form__inner">
            {isSubscribed ? renderSuccessMessage() : renderForm()}
          </div>
        </div>
      </div>
  );
}
