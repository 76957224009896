import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import {PATHS} from "./paths";
import HomePage from "../features/home/HomePage";
import CartPage from "../features/cart/CartPage";
import ConnectionPage from "../features/connection/ConnectionPage";
import MyDomains from "../features/account/MyDomains/MyDomains";
import MyProfile from "../features/account/MyProfile/MyProfile";
import DummyPage from "../features/dummy/DummyPage";
import CartDummyPage from "../features/cart/DummyPage";
import ProtectedRoute from "./ProtectedRoute";
import ExternalRedirect from "./ExternalRedirect";
import PartnersPage from "../features/partners/PartnersPage";
import ScrollToTop from "./ScrollToTop";
import OrderPage from "../features/order/OrderPage";
import {BlogPage} from "../features/blog/BlogPage";
import {BlogPageItem} from "../features/blog-page-item/BlogPageItem";
import {Error404Page} from "../features/error/Error404Page";
import UploadDomain from "../features/account/UploadDomain/UploadDomain";
import BrowserRouter from "./BrowserRouter";
import ReferralProgram from "../features/account/ReferralProgram/ReferralProgram";
import MyCoupons from "../features/account/MyCoupons/MyCoupons";
import WelcomePage from "../features/welcome/WelcomePage";
import ClaimPage from "../features/claim/ClaimPage";
import CongratulationsPage from "../features/congratulations/CongratulationsPage";
import AuthPage from "features/auth/AuthPage";
import {useAuth} from "../hooks";
import RegisterEmail from "../features/account/RegisterEmail/RegisterEmail";
import DomainPageEditor from "../features/account/DomainPageEditor/DomainPageEditor";
import DevAuthPage from "../features/auth/templates/AuthPage";

export default function Router() {
	const { isAuthorized } = useAuth();

	return (
			<BrowserRouter>
				<ScrollToTop/>
				<Routes>
					<Route path={PATHS.market} element={<HomePage/>}/>
					<Route path={PATHS.welcome} element={<WelcomePage/>}/>
					<Route path={PATHS.congratulations.index} element={<CongratulationsPage/>}/>
					<Route path={PATHS.partners} element={<PartnersPage/>}/>
					<Route path={PATHS.investors} element={<DummyPage/>}/>
					<Route path={PATHS.developers} element={<DummyPage/>}/>
					<Route path={PATHS.cart} element={<CartPage/>}/>
					<Route path={PATHS.connect} element={<ConnectionPage/>}/>
					<Route path={PATHS.sales_forecast} element={<ExternalRedirect link={PATHS.external_sales_forecast}/>}/>
					<Route path={PATHS.presentation} element={<ExternalRedirect link={PATHS.external_presentation}/>}/>
					<Route path={PATHS.blog} element={<BlogPage/>}/>
					<Route path={PATHS.blog_item} element={<BlogPageItem/>}/>
					<Route path={PATHS.auth.index} element={<AuthPage/>}/>
					<Route path={PATHS.account.register.email.index} element={<RegisterEmail/>}/>
					<Route element={<ProtectedRoute predicate={isAuthorized} redirect={PATHS.auth.index}/>}>
						<Route path={PATHS.account.profile.index} element={<MyProfile/>}/>
						<Route path={PATHS.account.domains.index} element={<MyDomains/>}/>
						<Route path={PATHS.account.coupons.index} element={<MyCoupons/>}/>
						<Route path={PATHS.account.referralprogram.index} element={<ReferralProgram/>}/>
						<Route path={PATHS.account.domains.upload(':id')} element={<UploadDomain/>}/>
					</Route>
					<Route element={<ProtectedRoute predicate={process.env.REACT_APP_IS_SALE_STARTED === 'true'} redirect={<CartDummyPage/>}/>}>
						<Route path={PATHS.order.index} element={<OrderPage/>}/>
					</Route>
					<Route path={PATHS.claim.index} element={<ClaimPage/>}/>
					<Route path={PATHS.error_404} element={<Error404Page/>}/>
					<Route path={PATHS.all} element={<Navigate to={PATHS.market} replace/>}/>
					<Route path={"/dev_generator"} element={<DomainPageEditor/>}/>
					<Route path={"/dev_cart_dummy"} element={<CartDummyPage /> }/>
					<Route path={"/dev_auth"} element={<DevAuthPage /> }/>
				</Routes>
		</BrowserRouter>
	);
}
