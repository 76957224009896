export interface AppError {
  name: string
  message?: string
  cause?: AppError
}

export function AppError(message?: string, cause?: AppError | Error | any): AppError;
export function AppError(cause: AppError | Error | any): AppError;

export function AppError(a?: string | AppError | Error, b?: AppError | Error): AppError {
  const result: AppError = {
    name: 'AppError'
  };
  if (typeof a === 'string') {
    result.message = a;
  } else if (a instanceof Error) {
    result.cause = toAppError(a)
  } else if (isAppError(a)) {
    result.cause = a;
  }
  if (b instanceof Error) {
    result.cause = toAppError(b)
  } else if (isAppError(b)) {
    result.cause = b;
  }
  return result;
}

function isAppError(object: any): object is AppError {
  return object?.name === 'AppError';
}

function toAppError(error: Error): AppError {
  let cause: AppError | undefined
  if (error.cause instanceof Error) {
    cause = toAppError(error.cause)
  } else if (typeof error.cause === 'string') {
    cause = {
      name: 'UnknownError',
      message: error.cause
    }
  }
  return {
    name: error.name,
    message: error.message,
    cause
  }
}
