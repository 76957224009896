import {useEffect, useState} from "react";
import {getBgImages, uploadImage} from "../DomainPageEditorApi";
import ImageInput from "../../../../components/imageInput/ImageInput";
import DomainPageEditorBackgroundItem from "./DomainPageEditorBackgroundItem";

export default function DomainPageEditorBackground(props: DomainPageEditorBackgroundInterface) {

  const [active, setActive] = useState<object | null>(null);
  const [activeInd, setActiveInd] = useState<number | null>(-1);
  const [bgList, setBgList] = useState<Array<object>>([]);

  useEffect(() => {
    props.onChange(active);
  }, [active]);

  useEffect(() => {
    if (!bgList.length)
      getBgImages().then((list: any) => {
        for (let i = 0; i < list.length; i++) {
          let rnd = Math.floor(Math.random() * list.length);
          [list[i], list[rnd]] = [list[rnd], list[i]];
        }
        setBgList(list);
      });
  });

  const setImage = async (ind: number) => {
    setActive(bgList[ind] || null);
    setActiveInd(ind);

    if (bgList[ind]) {
      // @ts-ignore
      (new Image()).src = bgList[ind].link;
    }
  };

  const setCustom = async (file: File | null) => {
    if (!file)
      return setImage(-1)

    let links: any = await uploadImage(file);
    setActive({link: links.full});
    setActiveInd(null);
  }

  return (
      <div className={"domain-page-editor_background_container"}>
        <div className={"domain-page-editor_background_list"}>
          <DomainPageEditorBackgroundItem
              item={null}
              isActive={activeInd === -1}
              onClick={() => setImage(-1)}/>

          {bgList.map((item: any, ind) =>
              <DomainPageEditorBackgroundItem
                  item={item}
                  isActive={ind === activeInd}
                  key={ind}
                  onClick={() => setImage(ind)}/>)}
        </div>

        <ImageInput label="custom" onChange={setCustom} type="squared"/>
      </div>
  );
}

export interface DomainPageEditorBackgroundInterface {
  onChange: (info: object | null) => void
}