import {ButtonHTMLAttributes} from 'react';
import styles from './Button.module.scss';

export interface ButtonInterface extends ButtonHTMLAttributes<HTMLButtonElement> {
  shape?: "squared" | "rounded",
  color?: "gray" | "yellow",
}

export default function Button(props: ButtonInterface = {shape: 'rounded'}) {
  const {shape, color, ...restProps} = props;
  const className = [styles.button];

  className.push(styles[`button_type_${props.shape}`]);
  if (props.className) className.push(props.className);
  if (props.color) className.push(styles[`button_color_${props.color}`]);
  if (props.disabled) className.push(styles.button_disabled);

  return <button {...restProps} className={className.join(' ')}>{props.children}</button>
}
