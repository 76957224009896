import '../../components/layout/BaseLayout/BaseLayout.css';
import './CartPage.scss'
import {FormEvent, Fragment} from 'react';
import {PATHS} from "../../router/paths";
import {default as RenderCartItem} from "./CartItem";
import {actions as cartActions, selectors as cartSelectors} from "./store";
import {actions as orderActions} from "features/order/store";
import {useDispatch, useSelector} from 'store/hooks';
import {useFetchPricesQuery} from "features/api/sales/slice";
import {formatToken, parseWei} from "features/api/web3/lib/helpers";
import {useReferrer, useSafeNavigate} from "../../hooks";
import {CartItem} from "./store/model/CartItem";
import {useGetAccountQuery} from "../api/web3/slice";
import PaymentOptions from "./PaymentOptions";
import {PaymentOption} from "./store/model/PaymentOption";
import BaseLayout from "../../components/layout/BaseLayout/BaseLayout";
import {HomeNavbar} from "../home/HomeNavbar/HomeNavbar";
import Button from "../../components/button/Button";
import {NavLink} from "react-router-dom";

export default function CartPage() {
  const dispatch = useDispatch();
  const navigate = useSafeNavigate();
  const domainNames = useSelector(cartSelectors.selectDomainNames);
  const coupon = useSelector(cartSelectors.selectCoupon);
  const paymentOption = useSelector(cartSelectors.selectPaymentOption);
  const referrer = useReferrer();
  const {data: account} = useGetAccountQuery();
  const {domains, totalAmount, discountedAmount, discount, isLoading} = useFetchPricesQuery({
    domainNames,
    account,
    coupon: coupon?.value,
    referrer
  }, {
    selectFromResult: ({data, error, isLoading}) => ({
      domains: data ? toCartItems(data.data.domainPrices) : [],
      totalAmount: data?.data.price || '0',
      discountedAmount: data?.data.priceWithDiscount || '0',
      discount: data?.data.price !== '0' && data?.data.priceWithDiscount ? parseWei(data.data.price).sub(data.data.priceWithDiscount).mul(100).div(data.data.price).toNumber() : 0,
      error,
      isLoading
    }),
  });
  const canCreateOrder = domains?.length > 0
  const isCouponDisabled = coupon?.status === 'loading' || coupon?.status === 'account required';

  const handleSubmitDiscount = async (e: FormEvent<HTMLFormElement & {elements: {coupon: HTMLInputElement}}>) => {
    e.preventDefault();
    const value = e.currentTarget.elements.coupon.value;
    if (!value.length) return;
    dispatch(cartActions.checkCoupon(value));
  }

  const handleClickConnect = () => {
    navigate(PATHS.connect);
  }

  const handleRemoveDomain = (domainName: string) => {
    dispatch(cartActions.removeDomain(domainName));
  }

  const handleChangePaymentOption = (paymentOption: PaymentOption) => {
    dispatch(cartActions.setPaymentOption(paymentOption));
  }

  const handleConfirmButton = () => {
    if (!canCreateOrder) return;

    dispatch(orderActions.createOrder({
      paymentOption,
      items: domains,
      totalAmount,
      discountedAmount,
      coupon: coupon?.status === 'valid' ? coupon.value : undefined,
      referrer
    }));
    navigate(PATHS.order.index);
  }

  return (
      <BaseLayout>
        <BaseLayout.Navbar>
          <HomeNavbar/>
        </BaseLayout.Navbar>
        <BaseLayout.Body>
          <div className="inner cart-modal">
            {!isLoading && domains && domains.length ?
                <div className="cart-modal__content">
                  <div className="cart-modal__content-block">
                    <div className="added-items-label">
                      <div className="added-items-label__counter">1</div>
                      Cart
                    </div>
                    <div className="added-items">
                      {domains.map(({name, price, discountedPrice}, i) =>
                          <RenderCartItem key={i} name={name} price={price || '0'} discountedPrice={discountedPrice}
                                          onClickRemove={handleRemoveDomain}/>)
                      }
                    </div>
                  </div>

                  <div className="cart-modal__content-block">
                    <div className="added-items-label">
                      <div className="added-items-label__counter">2</div>
                      Coupon/Promo code
                    </div>
                    <form className="coupon-code" onSubmit={handleSubmitDiscount}>
                      <div className="coupon-code__field-wrap">
                        <input type="text" name="coupon" className="coupon-code__field"
                               placeholder="Enter coupon/promo code"
                               defaultValue={coupon?.value || ''} disabled={isCouponDisabled}/>
                      </div>
                      <button className="coupon-code__apply" type="submit" disabled={isCouponDisabled}>Apply</button>
                      <div className="coupon-code__feedback">
                        {(() => {
                          switch (coupon?.status) {
                            case 'valid':
                              return <Fragment>Coupon applied successfully!</Fragment>
                            case 'invalid':
                              return <Fragment>invalid code</Fragment>;
                            case 'error':
                              return <Fragment>{coupon.error.message}</Fragment>
                            case 'account required':
                              return <Fragment>Please <button onClick={handleClickConnect}
                                                              type='button'>connect</button> to
                                apply your coupon</Fragment>
                            default:
                              return null;
                          }
                        })()}
                      </div>
                    </form>
                  </div>

                  <div className="cart-modal__content-block">
                    <div className="added-items-label">
                      <div className="added-items-label__counter">3</div>
                      Order summary
                    </div>
                    <div className="order-summary">
                      <div className="order-summary__item">
                        <div className="order-summary__item-name">
                          {domains.length}&nbsp;{domains.length > 1 ? 'domains' : 'domain'}
                        </div>
                        <div className="order-summary__item-price">
                          {totalAmount !== discountedAmount &&
                            <div className="order-summary__item-old-price">${formatToken(totalAmount)}</div>
                          }
                          <div className="order-summary__item-current-price">${formatToken(discountedAmount)}</div>
                        </div>
                      </div>
                      {coupon?.status === 'valid' &&
                        <div className="order-summary__item">
                          <div className="order-summary__item-name">Coupon</div>
                          <div className="order-summary__item-price">
                            <div className="order-summary__item-old-price">$240</div>
                          </div>
                        </div>
                      }
                    </div>

                    <div className="total-price">
                      <div className="total-price__label">Total:</div>
                      <div>
                       <span className="full-price"
                             style={{display: (discount === 0 ? 'none' : 'inline')}}>${formatToken(totalAmount)}</span>
                        <span className="total-price-main">${formatToken(discountedAmount)}</span>
                      </div>
                    </div>
                  </div>

                  <div className="cart-modal__content-block">
                    <div className="select-pay">
                      <PaymentOptions value={paymentOption} onChange={handleChangePaymentOption}/>
                    </div>
                  </div>

                  <div className="confirm-block">
                    <button type='button' onClick={handleConfirmButton} className="confirm-button"
                            disabled={!canCreateOrder}>BUY
                    </button>
                  </div>
                </div>
                :
                <EmptyCart/>
            }
          </div>
        </BaseLayout.Body>

      </BaseLayout>
  );
}

function EmptyCart() {
  return (
      <div className="cart-modal__empty">
        <h1>Your cart is empty!</h1>
        <NavLink to={PATHS.search}><Button shape="squared">Search domain</Button></NavLink>
      </div>
  )
}

function toCartItems(domains: { normalizedDomainName: string | undefined, price: string | undefined, priceWithDiscount: string | undefined }[]): CartItem[] {
  return domains.reduce((acc, {normalizedDomainName, price, priceWithDiscount}) => {
    if (typeof price === 'undefined' || typeof normalizedDomainName === 'undefined') return acc;
    return [...acc, {name: normalizedDomainName, price, discountedPrice: priceWithDiscount || price}];
  }, [] as CartItem[]);
}
