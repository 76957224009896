import * as client from "./client";

export function getBlogMainPage() {
  return client.get('get_list');
}

export function getBlogArticle(path: string) {
  return client.get(`get_article?path=${encodeURIComponent(path)}`);
}

export function blogSubscribeEmail(email: string) {
  return client.post('add_email', {email});
}