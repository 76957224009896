import * as t from "io-ts";
import {Utils} from "../../util/util";
import {CouponReason} from "./CouponReason";
import {CouponStatus} from "./CouponStatus";

export const Coupon = t.type({
  id: t.number,
  ownerAddress: t.string,
  reason: Utils.enum<CouponReason>(CouponReason),
  reasonCounter: t.number,
  status: Utils.enum<CouponStatus>(CouponStatus),
  discount: t.number,
  name: t.string
});

export type Coupon = t.TypeOf<typeof Coupon>;
