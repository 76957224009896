import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'store/hooks';
import './ClaimPage.scss'
import BaseLayout from "../../components/layout/BaseLayout/BaseLayout";
import {Navigate, useSearchParams} from "react-router-dom";
import {selectClaimSaga} from "./store/selectors";
import {ClaimSagaStatus} from "./store/types";
import RequestAccount from "./components/RequestAccount";
import SendRequest from "./components/SendRequest";
import AwaitFulfillment from "./components/AwaitFulfillment";
import {PATHS} from "../../router/paths";
import Loader from "../../components/loader/Loader";
import {actions as claimActions} from './store';
import {HomeNavbar} from "../home/HomeNavbar/HomeNavbar";

export default function ClaimPage () {

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const state = useSelector(selectClaimSaga);

  useEffect(() => {
    const uuid = searchParams.get('uuid');
    if (uuid && 'uuid' in state && state.uuid !== uuid) {
      dispatch(claimActions.cancelSaga({uuid}));
    }
    if (uuid && state.status === ClaimSagaStatus.UNINITIALIZED) {
      searchParams.delete('uuid');
      setSearchParams(searchParams);
      dispatch(claimActions.createSaga({uuid}));
    }
  },[state])

  return (
    <BaseLayout>
      <BaseLayout.Navbar>
        <HomeNavbar/>
      </BaseLayout.Navbar>
      <BaseLayout.Body>
        <div className="claim__wrapper">
          {(() => { switch (state.status) {
            case ClaimSagaStatus.AWAITING_ACCOUNT: return <RequestAccount/>
            case ClaimSagaStatus.SENDING_REQUEST: return <SendRequest account={state.account} uuid={state.uuid}/>
            case ClaimSagaStatus.AWAITING_FULFILLMENT: return <AwaitFulfillment id={state.id}/>
            case ClaimSagaStatus.FULFILLED: return <Navigate to={PATHS.congratulations.index}/>
            default: return <div className="claim__inner"><div style={{display: 'flex', justifyContent: 'center'}}><Loader size='2rem'/></div></div>
          }})()}
        </div>
      </BaseLayout.Body>
    </BaseLayout>
  );

}
