import type {AxiosError, AxiosRequestConfig} from 'axios'
import axios from 'axios'
import type {BaseQueryFn} from '@reduxjs/toolkit/query'
import {FetchBaseQueryArgs} from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import {SerializedError} from "@reduxjs/toolkit";

export type AxiosBaseQueryArgs = FetchBaseQueryArgs

export type AxiosFetchArgs = {
  url: string
  method: AxiosRequestConfig['method']
  data?: AxiosRequestConfig['data']
  params?: AxiosRequestConfig['params']
}

export const axiosBaseQuery = ({baseUrl, prepareHeaders = (x) => x }: AxiosBaseQueryArgs = { baseUrl: '' }): BaseQueryFn<
  AxiosFetchArgs,
  unknown,
  SerializedError
> => async ({ url, method, data, params }, api) => {
  try {
    const preparedHeaders: Headers = await prepareHeaders(new Headers(), api);
    const headers = Array.from(preparedHeaders.entries()).reduce((result, entry) => ({...result, [entry[0]]: entry[1]}), {});
    const result = await axios({ url: `${baseUrl}/${url}`, method, data, params, headers });
    if (typeof result.data === 'object' && 'status' in result.data && result.data.status !== 'OK') {
      return { error: { message: 'Something went wrong while sending the request', code: result.data.status }};
    }
    return { data: result.data };
  } catch (axiosError) {
    let { name, message, stack, code } = axiosError as AxiosError;
    return {
      error: { name, message, stack, code }
    };
  }
}
