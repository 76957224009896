import {useEffect, useRef, useState} from "react";

export default function DomainPageEditorBackgroundItem(props: DomainPageEditorBackgroundItemInterface) {

  const isActive = useRef(props.isActive);

  useEffect(() => {
    isActive.current = props.isActive
  });

  const preview = props.item ? props.item.preview : ''
  return (
      <div
          onClick={props.onClick}
          className={`domain-page-editor_background_item ${isActive.current && '_active'}`}>
        {preview && <img src={preview} loading="lazy"/>}
      </div>
  );

}


export interface DomainPageEditorBackgroundItemInterface {
  item: null | { name: string, link: string, preview: string },
  isActive: boolean,
  onClick: () => void
}