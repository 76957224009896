import {isMobile} from "./browserdetection";


if (typeof window !== "undefined") {
  const style = document.createElement('style');
  let styles = '.web3modal-modal-lightbox { z-index: 10 !important; }';
  if (isMobile) styles += '.walletconnect-modal__mobile__toggle { display: none !important; };'
  style.innerHTML = styles;
  document.head.append(style);
}

export * as helpers from './helpers';
export * as web3 from './core';
export * as contracts from './contracts';
