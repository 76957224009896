import type {SerializedError} from "@reduxjs/toolkit";
import type {BaseQueryFn} from '@reduxjs/toolkit/query'

export type Web3Error = SerializedError & {
  reason?: string
}

export type Web3BaseQuery = BaseQueryFn<(() => any), any, Web3Error>

export const web3BaseQuery = (): Web3BaseQuery => async (method) => {
  try {
    const data = await method();
    return { data };
  } catch (error: any) {
    return {
      error: {
        code: error.code,
        message: error.message,
        reason: error.reason,
      }
    }
  }
}
