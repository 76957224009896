import {Fragment} from 'react';
import {Navigate} from "react-router-dom";
import {PATHS} from "router/paths";
import {PaymentSystem} from "shared/model";
import {Order} from "../store/model/Order";

export default function Congratulations (props: {order: Order}) {
  if (props.order.paymentSystem === PaymentSystem.Mercuryo || props.order.paymentSystem === PaymentSystem.Wert) return (
    <Fragment>
      <div>Congratulations!</div>
      <div>Your domains has been successfully reserved</div>
      <div>Please check your email for further instructions</div>
    </Fragment>
  )
  else return (
    <Navigate to={PATHS.congratulations.index}/>
  )
}
