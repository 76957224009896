import DomainPageEditorPostsItem from "./DomainPageEditorPostsItem";
import {useEffect, useState} from "react";
import Button from "../../../../components/button/Button";


export default function DomainPageEditorPosts(props: DomainPageEditorPostsInterface) {

  const [posts, setPosts] = useState<Array<object>>(props.posts || []);

  const updatePost = (ind: number, post: object | null) => {
    setPosts(prevState => {
      if (post === null) {
        prevState.splice(ind, 1);
      } else {
        prevState[ind] = post;
      }
      return [...prevState]
    })
  }

  useEffect(() => {
    props.onChange(posts)
  }, [posts])

  return (
      <div className={"domain-page-editor_posts_container"}>
        <div className={"domain-page-editor_posts_list"}>
          {posts.map((post: any, ind) =>
              <DomainPageEditorPostsItem key={ind} post={post} onChange={post => updatePost(ind, post)}/>)}
        </div>

        <Button shape="squared" onClick={() => updatePost(posts.length, {})}>Add Post</Button>
      </div>
  );

}


export interface DomainPageEditorPostsInterface {
  posts: Array<object>
  onChange: (posts: object) => void
}
