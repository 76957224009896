import {createSlice} from "@reduxjs/toolkit";
import {upload} from "./actions";
import {UploadState} from "./types";


export interface AccountState {
  uploads: {[tokenId: string]: UploadState}
}

const initialState: AccountState = {
  uploads: {},
}

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
    .addCase(upload.update, (state, {payload}) => {
      state.uploads[payload.tokenId] = payload;
    })
    .addCase(upload.delete, (state, {payload}) => {
      delete(state.uploads[payload]);
    })
});

export const { actions, reducer } = slice;
