import React, {useEffect, useState} from "react";
import {getPageHtml} from "./DomainPageEditorApi";


export default function DomainPagePreview(props: DomainPagePreviewInterface) {

  const [pageHtml, setPageHtml] = useState('');

  const updatePage = async () => {
    let resp = await getPageHtml(props.state);
    setPageHtml(resp ? `${resp}` : '');
  }

  useEffect(() => {
    updatePage();
  }, [props.state]);

  return (
      <iframe title="page-preview" srcDoc={pageHtml}/>
  );
}


export interface DomainPagePreviewInterface {
  state: object
}