import {Fragment, PropsWithChildren, ReactElement, useEffect} from "react";
import {SerializedError} from "@reduxjs/toolkit";
import Button from "components/button/Button";
import Loader from "components/loader/Loader";
import {formatTxLink} from "features/api/web3/lib/helpers";

export interface TransactionSnippetInteface extends PropsWithChildren {
  error: SerializedError | undefined
  hash: string | undefined
  isLoading: boolean
  isWaiting?: boolean
  onClickRetry: () => void
  onInit: () => void
}

export default function TransactionSnippet(props: TransactionSnippetInteface): ReactElement {

  const {children, error, hash, isLoading, isWaiting, onClickRetry, onInit} = props;

  useEffect(() => {
    onInit();
  }, []);

  if (typeof error !== 'undefined') return (
    <Fragment>
      <div>Something went wrong</div>
      <RenderError error={error} />
      <Button onClick={onClickRetry}>Retry</Button>
    </Fragment>
  );
  if (isLoading) return (
    <Fragment>
      <div style={{marginBottom: '2rem'}}>Please use your wallet to sign the transaction</div>
    </Fragment>
  )
  if (hash && isWaiting) return (
    <Fragment>
      <div style={{marginBottom: '2rem'}}>Please wait until your <a href={formatTxLink(hash)} target='_blank' rel='noreferrer'>transaction</a> is mined</div>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <Loader size='2rem'/>
      </div>
    </Fragment>
  )
  return <Fragment>{children}</Fragment>;
}

function RenderError(props: {error: SerializedError}) {
  let message;
  switch (props.error.code) {
    case 'ACTION_REJECTED': message = 'User rejected transaction'; break;
    case 'UNPREDICTABLE_GAS_LIMIT': message = 'Cannot estimate gas. Transaction may fail or may require manual gas limit'; break;
    default: message = props.error.message;
  }
  return (<div style={{marginBottom: '2rem'}}>{message}</div>);
}
