import {AppError} from "./model/AppError";

export const Currency = {
  BUSD: 'BUSD',
  USD: 'USD',
  USDT: 'USDT'
} as const;

export type Currency = typeof Currency[keyof typeof Currency]

export enum FetchStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  FAILED = 'failed'
}

export interface Fetchable {
  status: FetchStatus
  error: AppError | undefined
}
