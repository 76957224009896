import React, {useEffect, useState} from "react";
import ColorsList from "./ColorsList";
import DomainPageEditorColorPickerColor from "./DomainPageEditorColorPickerColor";
import DomainPageEditorColorPickerAlpha from "./DomainPageEditorColorPickerAlpha";
import Button from "../../../../components/button/Button";

export default function DomainPageEditorColors(props: DomainPageEditorColorsInterface) {

  const [colors, setColors] = useState(ColorsList.default);
  const [isAdvanced, setAdvanced] = useState(false);

  useEffect(() => {
    if (props.onChange)
      props.onChange(colors)
  }, [colors])

  const setColorsFromPreset = (key: any) => {
    // @ts-ignore
    let newPalette = ColorsList[key] || ColorsList.default;
    setColors(prevState => Object.assign({...prevState}, newPalette))
  }

  const setColor = (key: string, color: string | number) => {
    setColors(prevState => Object.assign({...prevState}, {[key]: color}))
  }

  const colorPreview = (paletteKey: string, palette: { _theme_icon_1: string, _theme_icon_2: string }) => {
    return (
        <div key={paletteKey} className="color-picker-preview_item" onClick={() => setColorsFromPreset(paletteKey)}>
          <div className="color-picker-preview_item_color" style={{backgroundColor: palette['_theme_icon_1']}}></div>
          <div className="color-picker-preview_item_color" style={{backgroundColor: palette['_theme_icon_2']}}></div>
        </div>
    );
  }

  return (
      <div>
        <div className="color-picker-preview_container">
          {(Object.keys(ColorsList) as Array<keyof typeof ColorsList>).map(
              palette => colorPreview(palette, ColorsList[palette])
          )}
        </div>

        <div className={"color-picker_wrapper"}>
          <Button
              className={"color-picker_wrapper_button"}
              onClick={() => setAdvanced(!isAdvanced)}
              shape="squared">
            advanced
          </Button>

          {isAdvanced && <>{(Object.keys(colors) as Array<keyof typeof colors>).map(key =>
              <div
                  key={key}
                  title={key}
                  style={{display: key[0] === "_" ? 'none' : ''}}
                  className={"color-picker-row"}>
                <label className={"color-picker-row_title"}>
                  <span className={"color-picker-row_title_key"}>{key.replace(/_/g, ' ')}:</span>
                  <span className={"color-picker-row_title_value"}>{colors[key]}</span>
                </label>
                {typeof colors[key] === "number" ?
                    <DomainPageEditorColorPickerAlpha value={+colors[key]} onChange={color => setColor(key, color)}/> :
                    <DomainPageEditorColorPickerColor color={'' + colors[key]}
                                                      onChange={color => setColor(key, color)}/>}
              </div>
          )}</>}

        </div>
      </div>
  );
}

export interface DomainPageEditorColorsInterface {
  onChange?: (colors: object) => void;
}
