import {useEffect, useRef, useState} from 'react';
import {NavLink} from "react-router-dom";
import {PATHS} from "router/paths";
import {useDispatch} from 'store/hooks';
import {actions as orderActions} from "features/order/store";
import './Mercuryo.scss'
import Loader from "components/loader/Loader";
import {OrderStatus} from "../store/model/OrderStatus";
import {PaymentSystem} from "../../../shared/model/PaymentSystem";
import {Order} from "../store/model/Order";
import {formatToken} from "features/api/web3/lib/helpers";

export default function Mercuryo (props: {order: Order}) {

  const dispatch = useDispatch();
  const {order} = props;
  const isValidState = order?.status === OrderStatus.AWAITING_PAYMENT && order.paymentSystem === PaymentSystem.Mercuryo
  const [isLoading, setIsLoading] = useState(true);
  const paymentStatus = useRef('unknown');

  useEffect(() => {
    if (!isValidState) return;
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://sandbox-widget.mrcr.io/embed.2.0.js";
    script.onload = () => {
      setIsLoading(false);
      (window as any).mercuryoWidget.run({
        widgetId: process.env.REACT_APP_MERCURYO_WIDGET_ID,
        host: document.getElementById('mercuryo'),
        address: process.env.REACT_APP_MERCURYO_ADDRESS,
        signature: process.env.REACT_APP_MERCURYO_SIGNATURE,
        merchantTransactionId: order.id,
        amount: formatToken(order.discountedAmount),
        fixAmount: true,
        currency: 'BUSD',
        fixCurrency: true,
        fixFiatAmount: true,
        onStatusChange: ({status}: { status: BuyTransactionStatus }) => {
          if (status === 'paid' && status !== paymentStatus.current) {
            paymentStatus.current = status;
            dispatch(orderActions.markOrderAsPaid({id: order.id}));
          }
        }
      })
    }
    document.body.appendChild(script);
    return () => {
      script.remove();
    }
  }, [])

  return (
    <div className="mercuryo">
      <div className="mercuryo__inner">
        <div className="mercuryo__header">
          <div className="logo">
            <NavLink to={PATHS.root} className="logo">
              <img src="/assets/img/logo-retina.png" alt=""/>
            </NavLink>
          </div>
          <NavLink to={PATHS.root} className="mercuryo__close">
            <img src="/assets/img/Desktop-retina-close-cart.svg" alt=""/>
          </NavLink>
        </div>
        <div className="mercuryo__content">
          { isLoading ? <div className="mercuryo__loader"><Loader size='2rem'/></div> : <div className="mercuryo__widget" id='mercuryo'></div> }
        </div>
      </div>
    </div>
  );

}

type TransactionStatus = 'new' | 'pending' | 'order_scheduled'
type BuyTransactionStatus = TransactionStatus & 'cancelled' | 'paid' | 'descriptor_failed'
type SellTransactionStatus = TransactionStatus & 'failed' | 'completed'
