import isEqual from "lodash/isEqual";
import {AppStartListening} from "store";
import {slice as uploadAPI} from "features/api/upload/slice";
import {matchers as web3Matchers, slice as web3} from "features/api/web3";
import {actions as accountActions} from "./index";
import {isAnyOf} from "@reduxjs/toolkit";


export default function(startListening: AppStartListening) {
  startListening({
    matcher: isAnyOf(accountActions.upload.init.match, accountActions.upload.cancel.match),
    effect: async (action, api) => {
      const tokenId = action.payload;
      if (accountActions.upload.cancel.match(action)) {
        api.cancelActiveListeners();
        api.dispatch(accountActions.upload.delete(tokenId));
        return;
      }
      api.dispatch(accountActions.upload.update({status: 'awaiting file upload', tokenId}))
      const [{payload: {content, contentProvider, nft, route}}] = await api.take(action =>
        uploadAPI.endpoints.upload.matchFulfilled(action) && action.meta.arg.originalArgs.get('tokenId') === tokenId
      );
      api.dispatch(accountActions.upload.update({status: 'awaiting content tx', content, contentProvider, contentTxHash: undefined, nft, route, tokenId}));
      const [{payload: contentTxHash}] = await api.take(action =>
        web3.endpoints.setContent.matchFulfilled(action) && isEqual(action.meta.arg.originalArgs, {tokenId, content, contentProvider})
      );
      api.dispatch(web3.endpoints.waitForTransaction.initiate({transactionHash: contentTxHash}));
      await api.condition(web3Matchers.isSuccessfulTx(contentTxHash));
      api.dispatch(accountActions.upload.update({
        status: 'awaiting route tx',
        content,
        contentProvider,
        contentTxHash,
        nft,
        route,
        routeTxHash: undefined,
        tokenId
      }));
      const [{payload: routeTxHash}] = await api.take(action =>
        web3.endpoints.setContentRoute.matchFulfilled(action) && isEqual(action.meta.arg.originalArgs, {tokenId, route})
      );
      api.dispatch(web3.endpoints.waitForTransaction.initiate({transactionHash: routeTxHash}));
      await api.condition(web3Matchers.isSuccessfulTx(routeTxHash));
      api.dispatch(accountActions.upload.update({status: 'fulfilled', content, contentProvider, contentTxHash, nft, route, routeTxHash, tokenId}));
    },
  });
}
