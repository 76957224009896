const gateway = `${process.env.REACT_APP_STATIC_SERVER}/api/`;

export function get(method: string) {
  return fetch(
      `${gateway}/${method}`
  )
      .then(getResponse)
      .catch(() => null);
}

export function post(method: string, body?: object) {
  return fetch(
      `${gateway}/${method}`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json'
        }
      }
  )
      .then(getResponse)
      .catch(() => undefined);
}

async function getResponse(response: Response): Promise<object | undefined> {
  let body = await response.json();

  return body.status === "success" ? body.data : null;
}