import {Action, configureStore, ThunkAction, TypedStartListening} from "@reduxjs/toolkit";
import {createLogger} from "redux-logger";
import {reducer as accountReducer} from "features/account/store";
import {reducer as authReducer} from "features/auth/store";
import {reducer as claimReducer} from "features/claim/store";
import {reducer as cartReducer} from "features/cart/store";
import {reducer as orderReducer} from "features/order/store";
import {slice as salesApi} from "features/api/sales/slice";
import {slice as uploadApi} from "features/api/upload/slice";
import {slice as web3Api} from "features/api/web3/slice";
import {listenerMiddleware} from "./listeners";
import {loadState, saveState} from "./localstorage";
import {debounce} from "lodash";

const loggerMiddleware = createLogger({collapsed: true});

const store = configureStore({
	reducer: {
		[salesApi.reducerPath]: salesApi.reducer,
		[uploadApi.reducerPath]: uploadApi.reducer,
		[web3Api.reducerPath]: web3Api.reducer,
		account: accountReducer,
		auth: authReducer,
		cart: cartReducer,
		claim: claimReducer,
		order: orderReducer,
	},
	devTools: true,
	middleware: getDefaultMiddleware => getDefaultMiddleware().concat([
		loggerMiddleware,
		listenerMiddleware.middleware,
		salesApi.middleware,
		uploadApi.middleware,
		web3Api.middleware
	]),
	preloadedState: {cart: loadState()?.cart}
});

store.subscribe(debounce(() => saveState({cart: store.getState().cart}), 800))

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type AppStartListening = TypedStartListening<RootState, AppDispatch>
