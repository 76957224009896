import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal, { IProviderOptions, providers } from "web3modal";
import { BigNumber, ethers, getDefaultProvider } from "ethers";
import { hasEthereum, isMobile } from "./browserdetection";
import { normalizeChainId } from "./util";


const WALLETCONNECT_PARAMS = {
  rpc: { [process.env.REACT_APP_WEB3_CHAIN_ID!]: process.env.REACT_APP_WEB3_RPC_URL },
  chainId: process.env.REACT_APP_WEB3_CHAIN_ID,
  network: 'binance',
  qrcodeModalOptions: { desktopLinks: [] }
}

const ETHEREUM_CHAIN_PARAMS = {
  blockExplorerUrls: [process.env.REACT_APP_WEB3_EXPLORER_URL],
  chainId: `0x${Number(process.env.REACT_APP_WEB3_CHAIN_ID).toString(16)}`,
  chainName: process.env.REACT_APP_WEB3_CHAIN_NAME,
  nativeCurrency: {
    name: 'Binance',
    symbol: 'BNB',
    decimals: 18
  },
  rpcUrls: [process.env.REACT_APP_WEB3_RPC_URL!],
}

let provider: any;
let web3Modal: Web3Modal;
const callbacks: {
  accountsChanged?: (accounts: string[]) => any,
  chainChanged?: (chainId: string) => any,
  connect?: (info: string) => any,
  discounnect?: (error: any) => any,
} = {}

function getWeb3Modal(): Web3Modal {
  if (typeof web3Modal !== "undefined") return web3Modal;
  const providerOptions: IProviderOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: WALLETCONNECT_PARAMS
    }
  };
  if (!isMobile && !hasEthereum) {
    providerOptions['custom-metamask'] = {
      display: {
        logo: providers.METAMASK.logo,
        name: 'Install MetaMask',
        description: 'Connect using browser wallet'
      },
      package: {},
      connector: () => {
        window.open('https://metamask.io')
        throw new Error('MetaMask not installed');
      }
    }
  }
  return new Web3Modal({ cacheProvider: false, providerOptions });
}

function handleAccountsChanged(accounts: string[]) {
  if (!accounts.length && !hasDisconnectCallback()) handleDisconnect(Error('No accounts found when accountChanged event was caught'));
  else callbacks.accountsChanged?.(accounts);
}

function handleChainChanged(chainId: string) {
  callbacks.chainChanged?.(chainId);
}

function handleConnect(info: string) {
  callbacks.connect?.(info);
}

function handleDisconnect(error: any) {
  getWeb3Modal().clearCachedProvider();
  localStorage.removeItem('WEB3_CONNECT_CACHED_PROVIDER')
  provider = undefined;
  callbacks.discounnect?.(error);
}

function hasDisconnectCallback() {
  return !('isMetaMask' in provider);
}

function isExpectedChain(chainId: string | number) {
  return normalizeChainId(chainId) === ETHEREUM_CHAIN_PARAMS.chainId;
}

export async function connect() {
  provider = await getWeb3Modal().connect();
  provider.on("accountsChanged", handleAccountsChanged);
  provider.on("chainChanged", handleChainChanged);
  provider.on("connect", handleConnect);
  provider.on("disconnect", handleDisconnect);
  const chainId = await provider.request({ method: 'eth_chainId' })
  if (!isExpectedChain(chainId)) {
    try {
      await provider.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: ETHEREUM_CHAIN_PARAMS.chainId }] });
    } catch (error: any) {
      if (error.code && error.code === 4902) await provider.request({ method: 'wallet_addEthereumChain', params: [ ETHEREUM_CHAIN_PARAMS ] });
      else throw error;
    }
  }
}

export async function disconnect() {
  if ('close' in provider) await provider.close();
  handleDisconnect(Error('The "Disconnect" method was called '));
}

export function getAccounts(): Promise<string[]> {
  return getProvider(false).listAccounts();
}

export function getBalance(address: string): Promise<BigNumber> {
  return getProvider().getBalance(address);
}

export async function getPersonalSign(message: string): Promise<string> {
  const signer = getProvider(false).getSigner();
  return await signer.signMessage(message);
}

export function getProvider<B extends boolean>(allowDefaultProvider?: B): B extends false ? ethers.providers.Web3Provider : ethers.providers.BaseProvider

export function getProvider(allowDefaultProvider: boolean = true): ethers.providers.Web3Provider | ethers.providers.BaseProvider {
  if (provider || !allowDefaultProvider) return new ethers.providers.Web3Provider(provider);
  const [rpcUrl] = ETHEREUM_CHAIN_PARAMS.rpcUrls;
  return getDefaultProvider(rpcUrl);
}

export function hasCachedProvider() {
  return !!getWeb3Modal().cachedProvider;
}

export function isConnected() {
  return !!provider;
}

export function onAccountsChange(callback: (accounts: string[]) => any) {
  callbacks.accountsChanged = callback;
}

export function onChainChange(callback: (chainId: string) => any) {
  callbacks.chainChanged = callback;
}

export function onConnect(callback: (info: string) => any) {
  callbacks.connect = callback;
}

export function onDisconnect(callback: (error: any) => any) {
  callbacks.discounnect = callback;
}

export function waitForTransaction(txHash: string) {
  return getProvider().waitForTransaction(txHash);
}

