import React from "react";
import ReactGoogleLogin, {GoogleLoginResponse, GoogleLoginResponseOffline} from "react-google-login";
import {AuthType, OAuthData} from "../store/types";

export function GoogleAuthButton(props: {
  onClick: (authType: typeof AuthType.GOOGLE) => void,
  onSuccess: (data: OAuthData) => void
  onFailure?: (authType: typeof AuthType.GOOGLE, error: any) => void
}) {

  const handleSuccess = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if ('tokenId' in response) props.onSuccess({authType: AuthType.GOOGLE, tokenId: response.tokenId})
  }

  const handleFailure = (error: any) => {
    props.onFailure?.(AuthType.GOOGLE, error);
  }

  return (
    <ReactGoogleLogin
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}
      buttonText="Login with Google"
      onRequest={() => props.onClick(AuthType.GOOGLE)}
      onSuccess={handleSuccess}
      onFailure={handleFailure}
    />
  );
}
