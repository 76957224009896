import './PaymentPage.scss'
import {useSelector} from 'store/hooks';
import {selectDomainNames} from './store/selectors';
import BaseLayout from "../../components/layout/BaseLayout/BaseLayout";
import {HomeNavbar} from "../home/HomeNavbar/HomeNavbar";
import {PATHS} from "../../router/paths";
import {NavLink} from "react-router-dom";

export default function DummyPage() {

  const domains = useSelector(selectDomainNames);

  return (
      <BaseLayout>
        <BaseLayout.Navbar>
          <HomeNavbar/>
        </BaseLayout.Navbar>
        <BaseLayout.Body>
          <div className="payment__content">
            <div className="payment__submit-wrapper">
              <div className="payment__info">
                <h3>
                  Come Back for Domain Sales Later in December.<br/>
                  Join and Follow our Socials to Get the Earlybird Perks
                </h3>
              </div>
            </div>
          </div>
        </BaseLayout.Body>
      </BaseLayout>
  );

}
