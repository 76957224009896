import {useState} from "react";

export default function DomainItem({domain, num}) {

  const [isOpened, setOpened] = useState(false);


  return (
    <div className={`row ${isOpened && '_opened'}`}>

      <div className={"container"}>
        <div className="num">{num}</div>
        <div className="name"><span className="name-wrapper">{domain.name}</span></div>
        <div className="price">
          <div className="secondary">$300</div>
          <div className="primary">$50</div>
        </div>

        <div className="action">
          <div className="btn-toggle" onClick={() => setOpened(!isOpened)}></div>
        </div>
      </div>


      <div className="additional">
        <div className="price">
          <div className="title">Price</div>
          <div className="secondary">$300</div>
          <div className="primary">$50</div>
        </div>
        Att: Domains minting and management will be available after 12/15/2022
      </div>
    </div>
  );
}
