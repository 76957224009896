import React from 'react';
import BaseLayout from "components/layout/BaseLayout/BaseLayout";
import MainSearchForm from "./MainSearchForm/MainSearchForm";
import './HomePage.css';
import {HomeNavbar} from "./HomeNavbar/HomeNavbar";
import HomeContent from "./HomeContent/HomeContent";

const HomePage = () => {

  return (
    <BaseLayout>
      <BaseLayout.Navbar>
        <HomeNavbar/>
      </BaseLayout.Navbar>

      <BaseLayout.Body>
        <div className="dna-block" id="search">
          <div className="inner">
            <div className="dna-block__content">
              <div className="dna-block__title">
                <span>*DNA:</span> Domain, Name, Account
              </div>
              <div className="dna-block__intro">
                Start with choosing an NFT domain
              </div>
              <div className="dna-block__any-language">
                Use any language and symbol
              </div>
              <div className="dna-block__pay">
                <span>Pay for 1 get 3 in: </span> BSC, Polygon, Ethereum
              </div>
              <MainSearchForm placeholder={'Find your domain without Extension'}/>
            </div>

            <div className="dna-block__pictures">

            </div>
          </div>

        </div>

        <HomeContent/>

      </BaseLayout.Body>

    </BaseLayout>
  );

}

export default HomePage;
