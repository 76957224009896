import {ReactNode} from "react";

export interface ShareInterface {
  details?: {
    url?: string,
    title?: string,
    text?: string
  },

  social: Socials

  className?: string,

  children?: ReactNode
}

export enum Socials {
  tg="tg",
  tw="tw",
  in="in",
  fb="fb"
}

export default function Share(props: ShareInterface) {
  return (
      <a className={props.className || ""} onClick={(e) => handleClick(e, props)}>{props.children}</a>
  );
}

function handleClick(event: any, props: ShareInterface) {
  let details = props.details || {url: undefined};
  details.url = details.url || window.location.href;

  if (typeof navigator.share !== "undefined") {
    navigator.share(props.details)
  } else {
    window.open(getShareLink(props.social, details.url))
  }
}

function getShareLink(social: Socials, link: string) {
  link = encodeURIComponent(link);

  if (social === Socials.tg)
    return `https://t.me/share/url?url=${link}`;

  if (social === Socials.tw)
    return `https://twitter.com/share?url=${link}`;

  if (social === Socials.in)
    return `https://www.linkedin.com/sharing/share-offsite/?url=${link}`;

  if (social === Socials.fb)
    return `https://www.facebook.com/sharer.php?u=${link}`;
}