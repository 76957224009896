import { getContractWithSigner } from "../util";


const CONTRACT_ABI = [
  'function setContentAsBytes(uint id, bytes memory newContent)'
];

export default {
  /**
   * @param {string} tokenId
   * @param {string} content
   * @param {string} contractAddress
   * @return {Promise<TransactionResponse>} transaction object
   */
  async setContent(tokenId, content, contractAddress) {
    const contractWithSigner = getContractWithSigner(contractAddress, CONTRACT_ABI);
    return await contractWithSigner.setContentAsBytes(tokenId, content);
  },
};
