import {NavLink} from "react-router-dom";
import {PATHS} from "router/paths";
import './Wert.scss'
import Loader from "components/loader/Loader";
import {OrderStatus} from "../../store/model/OrderStatus";
import {PaymentSystem} from "shared/model";
import {Order} from "../../store/model/Order";
import WertModule from "@wert-io/module-react-component";
import {useRequestWertInvoiceMutation} from "features/api/sales/slice";
import {useEffect} from "react";
import {useDispatch} from "store/hooks";
import {actions as orderActions} from "../../store";
import {useAuth, useSafeNavigate} from "hooks";


export default function Wert (props: {order: Order}) {

  const {order} = props;
  const {isAuthorized, subject} = useAuth();
  const dispatch = useDispatch();
  const navigate = useSafeNavigate();
  const [requestInvoice, {data, error, isLoading}] = useRequestWertInvoiceMutation();
  const isValidState = order?.status === OrderStatus.AWAITING_PAYMENT && order.paymentSystem === PaymentSystem.Wert;

  useEffect(() => {
    if (!isAuthorized) navigate(PATHS.auth.index);
  }, [isAuthorized]);

  useEffect(() => {
    if (!isValidState || !subject) return;
    if (!subject.email) {
      // TODO: request email registration
      return;
    }
    requestInvoice({orderId: order.id, email: subject.email});
  }, [isValidState, subject?.email])

  const wertOptions = (() => {
    if (!data || !isValidState) return undefined;
    return {
      ...data.data,
      width: 400,
      height: 600,
      listeners: {
        'payment-status': (event: PaymentEvent) => {
          if (event.status === 'success') dispatch(orderActions.markOrderAsPaid({id: order.id}));
        }
      }
    }
  })();

  return (
    <div className="wert">
      <div className="wert__inner">
        <div className="wert__header">
          <div className="logo">
            <NavLink to={PATHS.root} className="logo">
              <img src="/assets/img/logo-retina.png" alt=""/>
            </NavLink>
          </div>
          <NavLink to={PATHS.root} className="wert__close">
            <img src="/assets/img/Desktop-retina-close-cart.svg" alt=""/>
          </NavLink>
        </div>
        <div className="wert__content">
          {(() => {
            if (isLoading) return <div className="wert__loader"><Loader size='2rem'/></div>
            if (wertOptions) return <div className="wert__widget"><WertModule options={wertOptions}/></div>
          })()}
        </div>
      </div>
    </div>
  );

}

type PaymentEvent = {
  status: 'pending' | 'canceled' | 'failed' | 'success' | 'failover'
  payment_id: string
  order_id: string
}
