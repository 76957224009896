import * as t from 'io-ts';
import {Utils} from '../../util/util';
import {Currency} from "shared/types";
import {BuyTaskStatus} from "./BuyTaskStatus";
import {DomainPrice} from "./DomainPrice";
import {TaskCancelCause} from "./TaskCancelCause";

export const UserDomainBuyTaskInfo = t.type({
  amount: t.string,
  amountWithDiscount: t.string,
  cancelCause: Utils.enum<TaskCancelCause>(TaskCancelCause),
  currency: Utils.enum<Currency>(Currency),
  discount: t.number,
  domainPrices: t.array(DomainPrice),
  price: t.string,
  priceWithDiscount: t.string,
  status: Utils.enum<BuyTaskStatus>(BuyTaskStatus),
  taskId: t.number
});

export type UserDomainBuyTaskInfo = t.TypeOf<typeof UserDomainBuyTaskInfo>;
